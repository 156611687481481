import React from "react";
import ActionButton from "~/components/Button";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { ExtraSmallDialogFrame } from "~/components/DialogLayout/Custom";
import ExternalLink from "~/components/Navigation/ExternalLink";
import type { StepPackage } from "~/components/StepPackageEditor/StepPackage/StepPackage";
import styles from "./StepPackageInfoDialog.module.less";

type StepPackageInfoDialogProps = {
    open: boolean;
    stepPackage: StepPackage<unknown> | undefined;
    onClose: () => void;
};

export function StepPackageInfoDialog({ open, stepPackage, onClose }: StepPackageInfoDialogProps) {
    if (!stepPackage) return null;
    const { name, description, version, releaseNotesUrl } = stepPackage;

    return (
        <CustomDialog
            open={open}
            close={onClose}
            render={() => (
                <ExtraSmallDialogFrame>
                    <div className={styles.content}>
                        <h2 className={styles.title}>{name}</h2>
                        <div className={styles.version}>Version {version}</div>
                        <div className={styles.description}>{description}</div>
                        {releaseNotesUrl && <ExternalLink href={releaseNotesUrl}>Release Notes</ExternalLink>}

                        <div className={styles.buttons}>
                            <ActionButton label="Close" type={2} onClick={onClose} />
                        </div>
                    </div>
                </ExtraSmallDialogFrame>
            )}
        />
    );
}
