import URI from "urijs";
import octopusDynamicExtensions from "~/areas/dynamicExtensions";
import type { ClientConfiguration } from "~/client/client";
import Session from "~/session";
import { Client, Repository } from "./client";

const browserURI = URI(window.location);
const configuration: ClientConfiguration = {
    serverEndpoint: resolveEndpoint(),
};
const cookieName = "OctopusDevExperience.Server";
const octopusServerURI = browserURI.search(true)["octopus.server"];
if (!octopusServerURI) {
    document.cookie = `${cookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
} else {
    document.cookie = `${cookieName}=${octopusServerURI}`;
}

octopusDynamicExtensions.setApiDocumentRootUrl(configuration.serverEndpoint + "/api");

const session = new Session();
const client = Client.Create(
    configuration,
    () => session.isAuthenticated(),
    () => session.end()
);

const repository = new Repository(client);

function resolveEndpoint() {
    let endpoint = "" + window.location.protocol;
    if (!endpoint.endsWith("//")) {
        endpoint = endpoint + "//";
    }

    endpoint = endpoint + window.location.host;

    let path = window.location.pathname;
    if (!path.startsWith("/")) {
        path = "/" + path;
    }

    if (path.length >= 1) {
        const lastSegmentIndex = path.lastIndexOf("/");
        if (lastSegmentIndex >= 0) {
            path = path.substring(0, lastSegmentIndex + 1);
        }
    }

    endpoint = endpoint + path;
    return endpoint;
}

export { client, repository, session };
