import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";

export const ConfigurationAuditRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.audit,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
};
