import type { Client } from "../client";
import type { UserRoleResource } from "../resources/userRoleResource";
import BasicRepository from "./basicRepository";

class UserRoleRepository extends BasicRepository<UserRoleResource, UserRoleResource> {
    constructor(client: Client) {
        super("UserRoles", client);
    }
}

export default UserRoleRepository;
