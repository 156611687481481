/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/consistent-type-assertions */

export interface Binding {
    protocol: string;
    port: string;
    ipAddress: string;
    host: string;
    thumbprint: string | null;
    certificateVariable?: any;
    requireSni: string;
    enabled: string;
}

const bindingsFromString = (bindingString: string) => {
    try {
        const result = parseV2Format();
        if (typeof result === "object") {
            return result;
        }
        return parseV1Format();
    } catch (ex) {
        return parseV1Format();
    }

    function parseV1Format() {
        const map = (b: string): Binding => {
            const bindingParts = b.split("/");
            const bindingInfo = bindingParts[1].split(":");
            const enabled = bindingParts.length >= 4 ? (bindingParts[3] === "True" ? "True" : "False") : "True";
            const requireSni = bindingParts.length >= 5 ? (bindingParts[4] === "True" ? "True" : "False") : "False";
            return {
                protocol: bindingParts[0],
                enabled,
                thumbprint: bindingParts[2],
                ipAddress: bindingInfo[0],
                port: bindingInfo[1],
                host: bindingInfo[2],
                requireSni,
            };
        };

        return bindingString.split("|").map(map);
    }

    function parseV2Format() {
        return JSON.parse(bindingString);
    }
};

const bindingsToString = (bindings: Array<Partial<Binding>>) => {
    const map = (partialBinding: Partial<Binding>): Binding => {
        const b: Binding = partialBinding as Binding;
        const isHttp = b.protocol === "http";
        //strip http etc except inside #{...} variable declarations
        b.host = b.host
            .split(/(#{.*?})/g)
            .map((h) => {
                return /^#{.*}$/.test(h) ? h : h.replace("//", "").replace("/", "").replace("https:", "").replace("http:", "");
            })
            .join("");

        return {
            protocol: b.protocol,
            ipAddress: b.ipAddress,
            port: b.port,
            host: b.host,
            thumbprint: isHttp ? null : b.thumbprint,
            certificateVariable: isHttp ? null : b.certificateVariable,
            requireSni: isHttp ? "False" : b.requireSni,
            enabled: b.enabled,
        };
    };

    const result = bindings.map(map);
    return JSON.stringify(result);
};

export { bindingsFromString, bindingsToString };
