import { useMemo } from "react";
import packagingRegistry from "./packagingRegistry";

export const usePackagingRegistration = (id: string) => {
    return useMemo(() => {
        const registration = packagingRegistry.getRegistration(id);
        return registration;
    }, [id]);
};

export default usePackagingRegistration;
