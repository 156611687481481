/**
 * https://github.com/kubernetes/ingress-nginx/blob/master/docs/user-guide/nginx-configuration/annotations.md
 *
 */
const NginxIngressAnnotation = [
    "nginx.ingress.kubernetes.io/add-base-url",
    "nginx.ingress.kubernetes.io/app-root",
    "nginx.ingress.kubernetes.io/affinity",
    "nginx.ingress.kubernetes.io/auth-realm",
    "nginx.ingress.kubernetes.io/auth-secret",
    "nginx.ingress.kubernetes.io/auth-type",
    "nginx.ingress.kubernetes.io/auth-tls-secret",
    "nginx.ingress.kubernetes.io/auth-tls-verify-depth",
    "nginx.ingress.kubernetes.io/auth-tls-verify-client",
    "nginx.ingress.kubernetes.io/auth-tls-error-page",
    "nginx.ingress.kubernetes.io/auth-tls-pass-certificate-to-upstream",
    "nginx.ingress.kubernetes.io/auth-url",
    "nginx.ingress.kubernetes.io/base-url-scheme",
    "nginx.ingress.kubernetes.io/client-body-buffer-size",
    "nginx.ingress.kubernetes.io/configuration-snippet",
    "nginx.ingress.kubernetes.io/default-backend",
    "nginx.ingress.kubernetes.io/enable-cors",
    "nginx.ingress.kubernetes.io/cors-allow-origin",
    "nginx.ingress.kubernetes.io/cors-allow-methods",
    "nginx.ingress.kubernetes.io/cors-allow-headers",
    "nginx.ingress.kubernetes.io/cors-allow-credentials",
    "nginx.ingress.kubernetes.io/cors-max-age",
    "nginx.ingress.kubernetes.io/force-ssl-redirect",
    "nginx.ingress.kubernetes.io/from-to-www-redirect",
    "nginx.ingress.kubernetes.io/grpc-backend",
    "nginx.ingress.kubernetes.io/limit-connections",
    "nginx.ingress.kubernetes.io/limit-rps",
    "nginx.ingress.kubernetes.io/permanent-redirect",
    "nginx.ingress.kubernetes.io/proxy-body-size",
    "nginx.ingress.kubernetes.io/proxy-cookie-domain",
    "nginx.ingress.kubernetes.io/proxy-connect-timeout",
    "nginx.ingress.kubernetes.io/proxy-send-timeout",
    "nginx.ingress.kubernetes.io/proxy-read-timeout",
    "nginx.ingress.kubernetes.io/proxy-next-upstream",
    "nginx.ingress.kubernetes.io/proxy-next-upstream-tries",
    "nginx.ingress.kubernetes.io/proxy-request-buffering",
    "nginx.ingress.kubernetes.io/proxy-redirect-from",
    "nginx.ingress.kubernetes.io/proxy-redirect-to",
    "nginx.ingress.kubernetes.io/rewrite-log",
    "nginx.ingress.kubernetes.io/rewrite-target",
    "nginx.ingress.kubernetes.io/secure-backends",
    "nginx.ingress.kubernetes.io/secure-verify-ca-secret",
    "nginx.ingress.kubernetes.io/server-alias",
    "nginx.ingress.kubernetes.io/server-snippet",
    "nginx.ingress.kubernetes.io/service-upstream",
    "nginx.ingress.kubernetes.io/session-cookie-name",
    "nginx.ingress.kubernetes.io/session-cookie-hash",
    "nginx.ingress.kubernetes.io/ssl-redirect",
    "nginx.ingress.kubernetes.io/ssl-passthrough",
    "nginx.ingress.kubernetes.io/upstream-max-fails",
    "nginx.ingress.kubernetes.io/upstream-fail-timeout",
    "nginx.ingress.kubernetes.io/upstream-hash-by",
    "nginx.ingress.kubernetes.io/load-balance",
    "nginx.ingress.kubernetes.io/upstream-vhost",
    "nginx.ingress.kubernetes.io/whitelist-source-range",
    "nginx.ingress.kubernetes.io/proxy-buffering",
    "nginx.ingress.kubernetes.io/proxy-buffer-size",
    "nginx.ingress.kubernetes.io/ssl-ciphers",
    "nginx.ingress.kubernetes.io/connection-proxy-header",
    "nginx.ingress.kubernetes.io/enable-access-log",
    "nginx.ingress.kubernetes.io/lua-resty-waf",
    "nginx.ingress.kubernetes.io/lua-resty-waf-debug",
    "nginx.ingress.kubernetes.io/lua-resty-waf-ignore-rulesets",
    "nginx.ingress.kubernetes.io/lua-resty-waf-extra-rules",
    "nginx.ingress.kubernetes.io/enable-influxdb",
    "nginx.ingress.kubernetes.io/influxdb-measurement",
    "nginx.ingress.kubernetes.io/influxdb-port",
    "nginx.ingress.kubernetes.io/influxdb-host",
    "nginx.ingress.kubernetes.io/influxdb-server-name",
];

/**
 * https://github.com/nginxinc/kubernetes-ingress/tree/master/examples/customization
 */
const NginxPlusAnnotations = [
    "nginx.com/sticky-cookie-services",
    "nginx.com/jwt-key",
    "nginx.com/jwt-realm",
    "nginx.com/jwt-token",
    "nginx.com/jwt-login-url",
    "nginx.com/health-checks",
    "nginx.com/health-checks-mandatory",
    "nginx.com/health-checks-mandatory-queue",
    "nginx.com/slow-start",
];

const CommonIngressAnnotations = ["kubernetes.io/ingress.class", "kubernetes.io/ingress.allow-http", "kubernetes.io/ingress.global-static-ip-name"];

/**
 * https://github.com/kubernetes/ingress-gce/blob/master/docs/annotations.md
 */
const GoogleIngressAnnotations = ["ingress.gcp.kubernetes.io/pre-shared-cert"];

export const IngressAnnotations = NginxIngressAnnotation.concat(CommonIngressAnnotations).concat(GoogleIngressAnnotations).concat(NginxPlusAnnotations);
