import * as React from "react";
import { useCallback } from "react";
import { PersistenceSettingsType } from "../../../../client/resources";
import { Callout, CalloutType } from "../../../../primitiveComponents/dataDisplay/Callout/Callout";
import { useProjectContext } from "../../context/ProjectContext";

const InvalidBranchCallout: React.FC = () => {
    const projectContext = useProjectContext();

    const onClose = useCallback(() => {
        projectContext.setState((current) => ({
            ...current,
            branchError: undefined,
        }));
    }, [projectContext]);

    const settings = projectContext.state.model.PersistenceSettings;
    if (settings.Type === PersistenceSettingsType.Database || !projectContext.state.branchError) {
        return <></>;
    }

    const defaultBranch = settings.DefaultBranch;
    return (
        <Callout title={`There was a problem loading the branch "${projectContext.state.branchError.branchName}"`} type={CalloutType.Warning} canClose={true} onClose={onClose}>
            <ul>
                {projectContext.state.branchError.errors.map((e, i) => (
                    <li key={i}>{e}</li>
                ))}
            </ul>
            Switched back to the default branch <b>"{defaultBranch}"</b>.
        </Callout>
    );
};

export default InvalidBranchCallout;
