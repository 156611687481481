import { isEqual } from "lodash";
import type { ReactNode } from "react";
import * as React from "react";
import { orderedHealthStatuses } from "~/areas/infrastructure/InfrastructureDetails";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import type { DeploymentTargetResource, EnvironmentResource, EnvironmentsSummaryResource, ResourceCollection, TenantResource } from "~/client/resources";
import { TaskRestrictedTo } from "~/client/resources";
import Permission from "~/client/resources/permission";
import { repository } from "~/clientInstance";
import FormPage from "~/components/FormPage/FormPage";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { withTheme } from "~/components/Theme";
import { CardTitle, UnstructuredFormSection } from "~/components/form/Sections";
import type { TagIndex } from "~/components/tenantTagsets";
import { OctopusIcon, OctopusIconType } from "~/primitiveComponents/dataDisplay/Icon";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import type { EnvironmentSummaryFilter } from "../EnvironmentsLayout/EnvironmentSummaryFilter";
import { defaultEnvironmentSummaryFilter } from "../EnvironmentsLayout/EnvironmentSummaryFilter";
import MachineRow from "../MachineRow/MachineRow";
import type { BaseAllMachinesSummaryProps, BaseAllMachinesSummaryState, HealthStatusRecord } from "./BaseAllMachinesSummary";
import BaseAllMachinesSummary from "./BaseAllMachinesSummary";
import { createMachineHealthMap, createMachinesListRequestArgs } from "./MachineFilter";
import styles from "./style.module.less";

interface DeploymentTargetsSummarySectionProps extends BaseAllMachinesSummaryProps<EnvironmentSummaryFilter> {
    environmentsSummary: EnvironmentsSummaryResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    filter: EnvironmentSummaryFilter;
}

interface DeploymentTargetsSummarySectionInnerProps extends DeploymentTargetsSummarySectionProps {
    initialData: InitialData;
}

interface InitialData {
    machinesResponse: ResourceCollection<DeploymentTargetResource>;
    machineHealthStatusFastLookup: HealthStatusRecord<DeploymentTargetResource>;
    endpointRegistrations: EndpointRegistration[];
}

const DeploymentTargetsSummarySectionFormPage = FormPage<InitialData>();
const DeploymentTargetsSummarySection: React.FC<DeploymentTargetsSummarySectionProps> = (props: DeploymentTargetsSummarySectionProps) => {
    return (
        <DeploymentTargetsSummarySectionFormPage
            title={"Deployment Targets"}
            load={async () => {
                const machineRequestArgs = createMachinesListRequestArgs(defaultEnvironmentSummaryFilter, null, true);
                const machinesResponse = await repository.Machines.list(machineRequestArgs);
                const machineHealthStatusFastLookup = createMachineHealthMap(machinesResponse, repository.takeDefaultPageSize);
                const endpointRegistrations = await endpointRegistry.getAllRegistrations();

                return { machinesResponse, machineHealthStatusFastLookup, endpointRegistrations };
            }}
            renderWhenLoaded={(data) => <DeploymentTargetsSummarySectionInner initialData={data} {...props} />}
        />
    );
};

interface DeploymentTargetsSummarySectionInnerState extends BaseAllMachinesSummaryState<DeploymentTargetResource> {
    endpointRegistrations: EndpointRegistration[];
}

class DeploymentTargetsSummarySectionInner extends BaseAllMachinesSummary<DeploymentTargetResource, DeploymentTargetsSummarySectionInnerProps, EnvironmentSummaryFilter, DeploymentTargetsSummarySectionInnerState> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: DeploymentTargetsSummarySectionInnerProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            machinesResponse: this.props.initialData.machinesResponse,
            machineHealthStatusFastLookup: this.props.initialData.machineHealthStatusFastLookup,
            endpointRegistrations: this.props.initialData.endpointRegistrations,
        };
    }

    componentDidMount() {
        this.reloadDataAndCurrentPageIndex();
    }

    UNSAFE_componentWillReceiveProps(nextProps: BaseAllMachinesSummaryProps<EnvironmentSummaryFilter>) {
        if (!isEqual(this.props.filter, nextProps.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }
        const environmentsSummary = this.props.environmentsSummary;

        const machineStatusesLinks = orderedHealthStatuses.map((status) => this.renderMachineSummaryLinks(environmentsSummary, status));
        const machinesDisabledLinks = this.renderMachineDisabledSummaryLinks(environmentsSummary);
        const summaryComponents = [...machineStatusesLinks, machinesDisabledLinks];

        const componentKey = "allMachines";
        const overflowMenuItems = [];

        // Only show machine-related actions if they actually have some machines in this environment.
        if (environmentsSummary.TotalMachines > 0) {
            const machineIds = this.state.machinesResponse && this.state.machinesResponse.Items.map((x) => x.Id);
            overflowMenuItems.push(
                OverflowMenuItems.item(`Check Health for ${machineIds && machineIds.length} Deployment Target${machineIds && machineIds.length === 1 ? "" : "s"}`, () => this.performHealthCheck(TaskRestrictedTo.DeploymentTargets, machineIds), {
                    permission: Permission.MachineEdit,
                    wildcard: true,
                })
            );
            const tentacleIds = environmentsSummary.MachineIdsForTentacleUpgrade;
            if (tentacleIds && tentacleIds.length > 0) {
                overflowMenuItems.push(
                    OverflowMenuItems.confirmUpgrade(`Upgrade ${tentacleIds.length} Tentacle${tentacleIds.length === 1 ? "" : "s"}`, () => this.performTentacleUpgrade(TaskRestrictedTo.DeploymentTargets, tentacleIds), {
                        permission: Permission.MachineEdit,
                        wildcard: true,
                    })
                );
            }
            const calamariIds = environmentsSummary.MachineIdsForCalamariUpgrade;
            if (calamariIds && calamariIds.length > 0) {
                overflowMenuItems.push(
                    OverflowMenuItems.confirmUpgrade(`Upgrade Calamari on ${calamariIds.length} Deployment Target${calamariIds.length === 1 ? "" : "s"}`, () => this.performCalamariUpgradeOnTargets(calamariIds), {
                        permission: Permission.MachineEdit,
                        wildcard: true,
                    })
                );
            }
        }
        const titleContainer = (
            <div className={styles.cardTitleContainer}>
                <div className={styles.environmentIcon}>
                    {withTheme((theme) => (
                        <OctopusIcon iconType={OctopusIconType.Machine} color={theme.iconDark} />
                    ))}
                </div>
                <div className={styles.environmentName}>Deployment targets</div>
                <div className={styles.environmentMachinesCount}>({environmentsSummary.TotalMachines && environmentsSummary.TotalMachines.toLocaleString()})</div>
                <div className={styles.environmentSummaryCounts}>{summaryComponents}</div>
                <div className={styles.environmentOverflowActions}>
                    <OverflowMenu menuItems={overflowMenuItems} />
                </div>
            </div>
        );

        return (
            <PaperLayout key={componentKey} busy={this.state.busy} errors={this.errors} className={styles.paperLayoutOverride}>
                <CardTitle title={titleContainer} />
                <UnstructuredFormSection stretchContent={true}>{this.renderMachinesList(environmentsSummary)}</UnstructuredFormSection>
            </PaperLayout>
        );
    }

    protected async loadData() {
        const machineArgs = createMachinesListRequestArgs(this.props.filter, this.state.healthStatusFilter, this.state.isDisabledFilter);
        const registrations = endpointRegistry.getAllRegistrations();
        const machines = repository.Machines.list(machineArgs);
        const promises = Promise.all([registrations, machines]);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(promises, ([endpointRegistrations, machinesResponse]) => {
            this.setMachineResponseState(machinesResponse);
            this.setState({ endpointRegistrations });
        });
    }

    protected renderMachine(machine: DeploymentTargetResource, needsUpgrading: boolean = false): ReactNode {
        return <MachineRow registrations={this.state.endpointRegistrations} machine={machine} environments={this.props.environments} tenants={this.props.tenants} tagIndex={this.props.tagIndex} needsUpgrading={needsUpgrading} />;
    }
}

export default DeploymentTargetsSummarySection;
