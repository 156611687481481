import type { ReactNode } from "react";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";

export interface UseDoBusyTaskProps {
    renderWithDoBusyTask: (doBusyTask: DoBusyTask, busy: Promise<void> | undefined, errors: Errors | undefined) => ReactNode;
}

export class UseDoBusyTask extends DataBaseComponent<UseDoBusyTaskProps> {
    constructor(props: UseDoBusyTaskProps) {
        super(props);

        this.state = {};
    }
    render() {
        return this.props.renderWithDoBusyTask(this.doBusyTask, this.state.busy, this.errors);
    }
}
