/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { cloneDeep } from "lodash";
import * as React from "react";
import { Permission } from "~/client/resources";
import type { LibraryVariableSetResource } from "~/client/resources/libraryVariableSetResource";
import { VariableSetContentType } from "~/client/resources/libraryVariableSetResource";
import type { LibraryVariableSetUsageResource, ProjectUsage } from "~/client/resources/libraryVariableSetUsageResource";
import type { VariableResource } from "~/client/resources/variableResource";
import { VariableType } from "~/client/resources/variableResource";
import type { VariableSetResource } from "~/client/resources/variableSetResource";
import { repository } from "~/clientInstance";
import CodeEditor from "~/components/CodeEditor/CodeEditor";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import Markdown from "~/components/Markdown/index";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import ScriptingLanguageSelector, { SupportedLanguage } from "~/components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, MarkdownEditor, required, Summary, Text } from "~/components/form";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import { ScriptingLanguage } from "~/components/scriptingLanguage";
import { TabItem, UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import StringHelper from "~/utils/StringHelper";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import { scriptModuleProjectUsageHelp, scriptModuleProjectUsageSummary, VariableSetProjectUsages } from "../VariableSets/VariableSetProjectUsage";
import { scriptModuleReleaseUsageHelp, scriptModuleReleaseUsageSummary, scriptModuleRunbookSnapshotUsageSummary, scriptModuleRunbookUsageHelp, VariableSetReleaseUsages, VariableSetRunbookUsages } from "../VariableSets/VariableSetReleaseUsage";

interface ScriptModuleProps {
    create?: boolean;
    scriptModuleId?: string;
    defaultTab?: string;
}

interface ScriptModuleModel extends LibraryVariableSetResource {
    scriptBody: string;
    syntax: ScriptingLanguage;
    variableSet: VariableSetResource;
}

interface ScriptModuleState extends OptionalFormBaseComponentState<ScriptModuleModel> {
    deleted: boolean;
    newId: string;
    scriptModule: LibraryVariableSetResource;
    usages: LibraryVariableSetUsageResource;
    usagesInProjects: ProjectUsage[];
    usagesInReleaseSnapshots: ProjectUsage[];
    usagesInRunbookSnapshots: ProjectUsage[];
}

class ScriptModule extends FormBaseComponent<ScriptModuleProps, ScriptModuleState, ScriptModuleModel> {
    constructor(props: ScriptModuleProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null!,
            usagesInProjects: [],
            usagesInReleaseSnapshots: [],
            usagesInRunbookSnapshots: [],
            scriptModule: null!,
            usages: null!,
        };
    }

    componentDidMount() {
        return this.doBusyTask(this.load);
    }

    private load = async () => {
        if (this.props.create) {
            const model: ScriptModuleModel = {
                Id: null!,
                Name: null!,
                Description: null!,
                Links: null!,
                VariableSetId: null!,
                ContentType: VariableSetContentType.ScriptModule,
                Templates: [],
                scriptBody: this.getSampleScriptBody(ScriptingLanguage.PowerShell),
                syntax: ScriptingLanguage.PowerShell,
                variableSet: null!,
                SpaceId: null!,
            };

            this.setState({
                model,
                cleanModel: cloneDeep(model),
            });
        } else {
            const scriptModule = await repository.LibraryVariableSets.get(this.props.scriptModuleId!);
            const variableSet = await repository.Variables.get(scriptModule.VariableSetId);

            const bodyVariable = this.getBodyVariable(variableSet);
            const syntax = this.getSyntax(variableSet);
            const scriptBody = bodyVariable ? bodyVariable.Value! : "";
            const model: ScriptModuleModel = this.buildModel(scriptModule, scriptBody, syntax, variableSet);

            this.setState({
                model,
                cleanModel: cloneDeep(model),
                scriptModule,
            });
        }
    };

    getSampleScriptBody(syntax: ScriptingLanguage): string {
        switch (syntax) {
            case ScriptingLanguage.PowerShell:
                return 'function Say-Hello()\r\n{\r\n    Write-Output "Hello, Octopus!"\r\n}\r\n';
            case ScriptingLanguage.Bash:
                return 'say_hello() {\r\n    echo "Hello, Octopus!"\r\n}\r\n';
            case ScriptingLanguage.CSharp:
                return 'void SayHello()\r\n{\r\n    Console.WriteLine("Hello, Octopus!");\r\n}\r\n';
            case ScriptingLanguage.FSharp:
                return 'module ScriptModule\r\n\r\nlet SayHello() = \r\n    printfn "Hello, Octopus!"\r\n';
            case ScriptingLanguage.Python:
                return 'def say_hello():\r\n    print("Hello, Octopus!")\r\n';
        }
    }

    getBodyVariable(variableSet: VariableSetResource): VariableResource {
        return variableSet.Variables.find((v) => v.Name.startsWith("Octopus.Script.Module["))!;
    }

    getSyntaxVariable(variableSet: VariableSetResource): VariableResource {
        return variableSet.Variables.find((v) => v.Name.startsWith("Octopus.Script.Module.Language["))!;
    }

    getSyntax(variableSet: VariableSetResource): ScriptingLanguage {
        const syntax = this.getSyntaxVariable(variableSet);
        return syntax ? (syntax.Value as ScriptingLanguage) : ScriptingLanguage.PowerShell;
    }

    render() {
        const title = this.props.create ? "New Script Module" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create && !!this.state.model) {
            overFlowActions.push(OverflowMenuItems.deleteItemDefault("script module", this.handleDeleteConfirm, { permission: Permission.LibraryVariableSetDelete }));
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        const saveText: string = this.state.newId ? "Script module created" : "Script module details updated";

        return (
            <LibraryLayout {...this.props}>
                <FormPaperLayout
                    title={title}
                    breadcrumbTitle={"Script Modules"}
                    breadcrumbPath={routeLinks.library.scripts.root}
                    busy={this.state.busy}
                    errors={this.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: this.props.create ? Permission.LibraryVariableSetCreate : Permission.LibraryVariableSetEdit, environment: "*", tenant: "*" }}
                    onSaveClick={() => this.handleSaveClick(false)}
                    saveText={saveText}
                    expandAllOnMount={this.props.create}
                    overFlowActions={overFlowActions}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.library.scripts.root} />}
                    {this.state.newId && <InternalRedirect to={routeLinks.library.script(this.state.newId)} />}
                    {this.state.model && (
                        <TransitionAnimation>
                            <UrlNavigationTabsContainer defaultValue={this.props.defaultTab ?? "summary"}>
                                <TabItem label="Summary" value="summary">
                                    <ExpandableFormSection
                                        errorKey="Name"
                                        title="Name"
                                        focusOnExpandAll
                                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your script module")}
                                        help="A short, memorable, unique name for this script module. Example: Service Configuration."
                                    >
                                        <Text value={this.state.model.Name} onChange={(Name) => this.setModelState({ Name })} label="Name" error={this.getFieldError("Name")} validate={required("Please enter a script module name")} autoFocus={true} />
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="Description" title="Description" summary={this.descriptionSummary()} help="This summary will be presented to users when selecting the script module for inclusion in a project.">
                                        <MarkdownEditor value={this.state.model.Description} label="Description" onChange={(Description) => this.setModelState({ Description })} />
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="Body" title="Body" fillCardWidth={CardFill.FillRight} summary={this.scriptBodySummary()} help={<Markdown markup={this.languageSpecificHelpText(this.state.model.syntax)} />}>
                                        <ScriptingLanguageSelector supportedLanguages={SupportedLanguage.All} value={this.state.model.syntax} onChange={(syntax) => this.handleSyntaxChange(syntax)} />
                                        <CodeEditor value={this.state.model.scriptBody} language={this.state.model.syntax} allowFullScreen={true} onChange={(scriptBody) => this.setModelState({ scriptBody })} />
                                    </ExpandableFormSection>
                                </TabItem>
                                {!this.props.create && (
                                    <TabItem label="Usage" value="usage" onActive={() => this.onUsageTabActive()}>
                                        {this.state.usages && (
                                            <ExpandableFormSection
                                                key="usageInProjects"
                                                errorKey="usageInProjects"
                                                title="Projects"
                                                expandable={this.state.usagesInProjects.length > 0}
                                                summary={scriptModuleProjectUsageSummary(this.state.usagesInProjects, this.state.usages.CountOfProjectsUserCannotSee)}
                                                help={scriptModuleProjectUsageHelp(this.state.usagesInProjects, this.state.usages.CountOfProjectsUserCannotSee)}
                                            >
                                                <VariableSetProjectUsages usage={this.state.usagesInProjects} />
                                            </ExpandableFormSection>
                                        )}
                                        {this.state.usages && (
                                            <ExpandableFormSection
                                                key="usageInReleaseSnapshots"
                                                errorKey="usageInReleaseSnapshots"
                                                title="Releases"
                                                expandable={this.state.usagesInReleaseSnapshots.length > 0}
                                                summary={scriptModuleReleaseUsageSummary(this.state.usagesInReleaseSnapshots, this.state.usages.CountOfReleasesUserCannotSee)}
                                                help={scriptModuleReleaseUsageHelp(this.state.usagesInReleaseSnapshots, this.state.usages.CountOfReleasesUserCannotSee)}
                                            >
                                                <VariableSetReleaseUsages usage={this.state.usagesInReleaseSnapshots} />
                                            </ExpandableFormSection>
                                        )}
                                        {this.state.usages && (
                                            <ExpandableFormSection
                                                key="usagesInRunbookSnapshots"
                                                errorKey="usagesInRunbookSnapshots"
                                                title="Runbook Snapshots"
                                                expandable={this.state.usagesInRunbookSnapshots.length > 0}
                                                summary={scriptModuleRunbookSnapshotUsageSummary(this.state.usagesInRunbookSnapshots, this.state.usages.CountOfRunbookSnapshotsUserCannotSee)}
                                                help={scriptModuleRunbookUsageHelp(this.state.usagesInRunbookSnapshots, this.state.usages.CountOfRunbookSnapshotsUserCannotSee)}
                                            >
                                                <VariableSetRunbookUsages usage={this.state.usagesInRunbookSnapshots} />
                                            </ExpandableFormSection>
                                        )}
                                    </TabItem>
                                )}
                            </UrlNavigationTabsContainer>
                        </TransitionAnimation>
                    )}
                </FormPaperLayout>
            </LibraryLayout>
        );
    }

    async onUsageTabActive() {
        if (this.state.usages || this.props.create) {
            return;
        }
        await this.doBusyTask(async () => {
            const usages = await repository.LibraryVariableSets.getUsages(this.state.scriptModule);
            const usagesInProjects = usages.Projects.filter((x) => x.IsCurrentlyBeingUsedInProject === true);
            const usagesInReleaseSnapshots = usages.Projects.filter((x) => x.Releases.length > 0);
            const usagesInRunbookSnapshots = usages.Projects.filter((x) => x.RunbookSnapshots.length > 0);
            this.setState({
                usages,
                usagesInProjects,
                usagesInReleaseSnapshots,
                usagesInRunbookSnapshots,
            });
        });
    }

    languageSpecificHelpText(syntax: ScriptingLanguage): string {
        switch (syntax) {
            case ScriptingLanguage.PowerShell:
                return "This script will be written as a PowerShell module (a `.psm1` file). " + "Functions and cmdlets from this module will be automatically available for use in PowerShell deployment scripts.";
            case ScriptingLanguage.Bash:
                return (
                    "This script will be be written as a Bash script next to the executing script. " +
                    (this.scriptModuleNameIsValid() ? "Import this script via `source " + this.convertScriptModuleNameToModuleName() + ".sh` to use in deployment scripts." : "")
                );
            case ScriptingLanguage.CSharp:
                return (
                    "This script will be be written as a C# module next to the executing script. " +
                    (this.scriptModuleNameIsValid() ? "Import this script via `#load " + this.convertScriptModuleNameToModuleName() + ".csx` to use in deployment scripts." : "")
                );
            case ScriptingLanguage.FSharp:
                return (
                    "This script will be be written as an F# module next to the executing script. " +
                    (this.scriptModuleNameIsValid() ? 'Import this script via `#load "' + this.convertScriptModuleNameToModuleName() + '.fsx"` to use in deployment scripts.' : "")
                );
            case ScriptingLanguage.Python:
                return (
                    "This script will be written as Python module next to the executing script. " +
                    (this.scriptModuleNameIsValid() ? "Import this script via `import " + this.convertScriptModuleNameToModuleName() + "` to use in deployment scripts." : "")
                );
        }
    }

    private handleSyntaxChange(syntax: ScriptingLanguage): void {
        const sampleScriptBody = this.getSampleScriptBody(this.state.model!.syntax);

        return this.setModelState({
            syntax,
            scriptBody: this.state.model!.scriptBody === sampleScriptBody ? this.getSampleScriptBody(syntax) : this.state.model!.scriptBody,
        });
    }

    private buildModel(scriptModule: LibraryVariableSetResource, script: string, syntax: ScriptingLanguage, vs: VariableSetResource): ScriptModuleModel {
        const thisModel: ScriptModuleModel = {
            Id: scriptModule.Id,
            Name: scriptModule.Name,
            Description: scriptModule.Description,
            VariableSetId: scriptModule.VariableSetId,
            ContentType: scriptModule.ContentType,
            Templates: scriptModule.Templates,
            Links: scriptModule.Links,
            scriptBody: script,
            syntax,
            variableSet: vs,
            SpaceId: scriptModule.SpaceId,
        };
        return thisModel;
    }

    private scriptModuleNameIsValid(): boolean {
        return !!this.convertScriptModuleNameToModuleName();
    }

    private convertScriptModuleNameToModuleName(): string | undefined {
        return this.state.model && this.state.model.Name && this.state.model.Name.replace(/[^0-9a-z_]/gi, "");
    }

    private descriptionSummary() {
        return this.state.model!.Description ? Summary.summary(<Markdown markup={this.state.model!.Description} />) : Summary.placeholder("No description provided");
    }

    private scriptBodySummary() {
        const syntax = this.state.model!.syntax;
        if (!this.state.model!.scriptBody) {
            return Summary.placeholder("The script body has not been defined");
        } else if (syntax === ScriptingLanguage.FSharp) {
            return Summary.summary(
                <span>
                    An <strong>F#</strong> script has been defined
                </span>
            );
        } else if (syntax === ScriptingLanguage.CSharp) {
            return Summary.summary(
                <span>
                    A <strong>C#</strong> script has been defined
                </span>
            );
        }
        return Summary.summary(
            <span>
                A <strong>{syntax}</strong> script has been defined
            </span>
        );
    }

    private handleSaveClick = async (redirectToTest: boolean) => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model!.Id == null;
            const result = await repository.LibraryVariableSets.save(this.state.model!);
            let variableSet = null;
            if (this.state.model!.scriptBody) {
                variableSet = await repository.Variables.get(result.VariableSetId);
                const bodyVar = this.getBodyVariable(variableSet) || this.addEmptyVariable(variableSet);
                bodyVar.Name = "Octopus.Script.Module[" + this.state.model!.Name + "]";
                bodyVar.Value = this.state.model!.scriptBody;
                const syntaxVar = this.getSyntaxVariable(variableSet) || this.addEmptyVariable(variableSet);
                syntaxVar.Name = "Octopus.Script.Module.Language[" + this.state.model!.Name + "]";
                syntaxVar.Value = this.state.model!.syntax;
                await repository.Variables.save(variableSet);
            }
            const thisModel = this.buildModel(result, this.state.model!.scriptBody, this.state.model!.syntax, variableSet!);
            this.setState({
                model: thisModel,
                cleanModel: cloneDeep(thisModel),
                newId: isNew ? result.Id! : null!,
            });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await repository.LibraryVariableSets.del(this.state.model!);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };

    private addEmptyVariable(variableSet: VariableSetResource) {
        const emptyVariable: VariableResource = {
            Id: "",
            Name: "",
            Value: "",
            Description: null!,
            Scope: {},
            IsEditable: false,
            Prompt: null,
            Type: VariableType.String,
            IsSensitive: false,
        };
        variableSet.Variables.push(emptyVariable);
        return emptyVariable;
    }
}

export default ScriptModule;
