import { MenuItem, useForkRef } from "@material-ui/core";
import cn from "classnames";
import type { LocationDescriptor } from "history";
import { createPath } from "history";
import type { PropsWithChildren, Ref } from "react";
import React, { forwardRef, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { client } from "~/clientInstance";
import { isUrlActive } from "~/components/Navigation/isUrlActive";
import { resolvePathWithSpaceId } from "~/components/Navigation/resolvePathWithSpaceId";
import styles from "./MenuItemInternalLink.module.less";

interface MenuItemInternalLinkProps {
    path: LocationDescriptor;
    label: string;
    shortDescription?: string;
    icon?: JSX.Element;
    showLinkAsActive?: ShowLinkAsActive;
    onClick?: () => void;
    autoFocus?: boolean;
    size?: LinkSize;
}

export const MenuItemInternalLink = forwardRef(({ path, label, onClick, autoFocus, size = "default", icon, shortDescription, showLinkAsActive = "never" }: MenuItemInternalLinkProps, ref: Ref<HTMLAnchorElement>) => {
    // We can't use the default li component that MenuItem renders here, because the parent element (CustomMenu or MenuList) is not a ul
    return <MenuItem component={LinkWrapper} path={path} label={label} onClick={onClick} showLinkAsActive={showLinkAsActive} autoFocus={autoFocus} size={size} ref={ref} icon={icon} shortDescription={shortDescription} />;
});

interface LinkWrapperProps {
    path: LocationDescriptor;
    icon: JSX.Element | undefined;
    onClick: undefined | (() => void);
    label: string;
    shortDescription: string | undefined;
    showLinkAsActive: ShowLinkAsActive;
    className: string;
    autoFocus: boolean | undefined;
    size: LinkSize;
}

const LinkWrapper = forwardRef(({ path, showLinkAsActive, label, onClick, className, children, autoFocus, size, icon, shortDescription, ...other }: PropsWithChildren<LinkWrapperProps>, ref: Ref<HTMLAnchorElement>) => {
    const routeParams = useParams<{ spaceId: string | undefined }>();

    // The client's spaceId and the route's spaceId should be in sync,
    // but because we haven't modelled this relationship in a reliable way,
    // this is not guaranteed to be true, particularly in tests
    const spaceId = routeParams.spaceId ?? client.spaceId ?? undefined;
    const location = useLocation();
    const newPath = resolvePathWithSpaceId(path, spaceId);
    const urlIsActive = showLinkAsActive === "never" ? false : isUrlActive(location.pathname, spaceId, typeof path === "object" ? createPath(path) : path, showLinkAsActive === "if path matches exactly");
    const sizeClass = size === "compact" ? styles.compact : size === "default" ? styles.defaultSize : styles.spacious;
    const classes = cn(className, styles.menuItemInternalLink, { [styles.active]: urlIsActive }, sizeClass);
    const labelClasses = cn(styles.label, { [styles.active]: urlIsActive });

    const focusRef = useRef<HTMLAnchorElement | null>(null);
    const combinedRef = useForkRef(focusRef, ref);

    useEffect(() => {
        if (autoFocus) {
            focusRef.current?.focus();
        }
    }, [autoFocus]);

    // Ideally we would wrap an anchor tag in an li like in this example: https://www.w3.org/TR/wai-aria-practices/examples/menu-button/menu-button-links.html
    // <li role="none"><a role="menuitem">My Link</a></li>
    // But if we do that, we lose out on keyboard navigation support from material ui's MenuList component
    // Instead, we want to render an anchor tag so that we get the right native browser link behaviours,
    // but make it appear like a menuitem for the accessibility tree
    return (
        // eslint-disable-next-line react/forbid-elements
        <Link aria-disabled={undefined} target={"_self"} role={"menuItem"} aria-label={label} to={newPath} onClick={onClick} tabIndex={-1} className={classes} {...other} innerRef={combinedRef}>
            {icon && <div className={styles.icon}>{icon}</div>}
            <div className={styles.textContainer}>
                <div className={labelClasses}>{label}</div>
                {styles.description && <div className={styles.description}>{shortDescription}</div>}
            </div>
            {/*The children is the ripple effect*/}
            {children}
        </Link>
    );
});

export type LinkSize = "default" | "compact" | "spacious";
export type ShowLinkAsActive = "never" | "if path matches exactly" | "if path partially matches";
