import * as React from "react";
import { ActionButton } from "~/components/Button/ActionButton";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { DialogLayoutConnect } from "~/components/Dialog/DialogLayoutConnect";
import { CustomDialogActions } from "~/components/DialogLayout/Custom/CustomDialogActions";
import { CustomFlexDialogContent } from "~/components/DialogLayout/Custom/CustomDialogContent";
import { LargeDialogFrame } from "~/components/DialogLayout/Custom/CustomDialogFrames";
import { CustomDialogTitleBar } from "~/components/DialogLayout/Custom/CustomDialogTitleBar";
import type { DialogLayoutDispatchProps, DialogLayoutCommonProps } from "~/components/DialogLayout/DialogLayout";
import styles from "./style.module.less";

interface InfoDialogLayoutProps extends DialogLayoutCommonProps {
    closeButtonLabel?: string;
}

class LargeInfoDialogLayoutInternal extends React.Component<InfoDialogLayoutProps & DialogLayoutDispatchProps> {
    render() {
        const { children } = this.props;
        const close = <ActionButton label={this.props.closeButtonLabel || "Close"} disabled={this.props.busy} onClick={() => this.props.close()} />;
        return (
            <CustomDialog
                open={true}
                close={this.props.close}
                render={() => (
                    <LargeDialogFrame>
                        <CustomDialogTitleBar title={this.props.title} />
                        <CustomFlexDialogContent className={styles.content}>{children}</CustomFlexDialogContent>
                        <CustomDialogActions actions={close} additionalActions={this.props.additionalActions} />
                    </LargeDialogFrame>
                )}
            />
        );
    }
}

const LargeInfoDialogLayout = DialogLayoutConnect.to(LargeInfoDialogLayoutInternal);
LargeInfoDialogLayout.displayName = "InfoDialogLayout";
export default LargeInfoDialogLayout;
