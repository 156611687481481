import { useHistory } from "react-router";
import { client } from "~/clientInstance";
import { SpaceAwareNavigation } from "./SpaceAwareNavigation";

export function useSpaceAwareNavigation() {
    const history = useHistory();
    const spaceId = client.spaceId ?? undefined;

    return new SpaceAwareNavigation(history, spaceId);
}
