import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import type { CategoryDefinition } from "./endpointRegistry";

const category: CategoryDefinition = {
    category: "Azure",
    title: (
        <React.Fragment>
            What type of <strong>Azure target</strong> do you want to connect?
        </React.Fragment>
    ),
    help: (
        <Note>
            Learn more about <ExternalLink href="AzureTargets">Azure Targets</ExternalLink>
        </Note>
    ),
    displayOrder: 30,
};

export default category;
