/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { AzureWebSite } from "~/client/resources";
import { repository } from "~/clientInstance";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import BusyRefreshContainer from "~/components/BusyRefreshContainer";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { default as IconButton, Icon } from "~/components/IconButton/IconButton";
import InputWithActions from "~/components/InputWithActions";
import isBound from "~/components/form/BoundField/isBound";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Note from "~/primitiveComponents/form/Note/Note";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";

interface AzureWebSiteSelectorProps {
    resourceGroupName: string;
    webAppName: string;
    projectId: string;
    localNames: string[];
    isAccountBound: boolean;
    accountId: string;
    resourceGroupError: string;
    webAppNameError: string;
    doBusyTask: DoBusyTask;
    onResourceGroupChanged(value: string): void;
    onWebAppNameChanged(value: string): void;
    onIsBoundChanged?(value: boolean): void;
}

interface AzureWebSiteSelectorState {
    reset: {
        resourceGroupName: string;
        name: string;
    };
    siteIsBound: boolean;
    sites: AzureWebSite[];
    siteItems: Array<{ value: string; text: string }>;
    selectedSiteIndex: string;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}

const toggleBusy = (value?: boolean) => (prev: AzureWebSiteSelectorState, props: AzureWebSiteSelectorProps) => ({ ...prev, busy: value ? value : !prev.busy });

class AzureWebSiteSelector extends BaseComponent<AzureWebSiteSelectorProps, AzureWebSiteSelectorState> {
    constructor(props: AzureWebSiteSelectorProps) {
        super(props);
        this.state = {
            reset: {
                resourceGroupName: null!,
                name: null!,
            },
            siteIsBound: isBound(props.webAppName, false),
            sites: [],
            siteItems: [],
            selectedSiteIndex: null!,
            busy: false,
        };
    }

    async componentDidMount() {
        if (!this.props.isAccountBound) {
            await this.getWebSites(this.props.accountId);
        }
    }

    async UNSAFE_componentWillReceiveProps(newprops: AzureWebSiteSelectorProps) {
        if (newprops.isAccountBound && !this.state.siteIsBound) {
            this.setState({ siteIsBound: true });
        }
        if (newprops.accountId !== this.props.accountId) {
            await this.getWebsitesIfNotBound(newprops);
        }
    }

    async getWebsitesIfNotBound(props: AzureWebSiteSelectorProps) {
        if (!props.isAccountBound) {
            await this.getWebSites(props.accountId);
        }
    }

    render() {
        return (
            <div>
                {this.state.siteIsBound && (
                    <div>
                        <VariableLookupText localNames={this.props.localNames} value={this.props.resourceGroupName} onChange={this.props.onResourceGroupChanged} error={this.props.resourceGroupError} label="Resource Group" />
                        <Note>
                            The Resource Group of your Azure Web App.
                            <br />
                            If not specified then all Resource Groups are searched.
                        </Note>
                    </div>
                )}
                <BusyRefreshContainer busy={this.state.busy}>
                    <InputWithActions
                        input={
                            <BoundSelect
                                variableLookup={{
                                    localNames: this.props.localNames,
                                }}
                                resetValue={this.state.selectedSiteIndex}
                                isBound={this.state.siteIsBound}
                                onIsBoundChanged={(value: boolean) => {
                                    this.setState(
                                        (prev, props) => ({ siteIsBound: this.props.isAccountBound || value }),
                                        () => {
                                            if (this.props.onIsBoundChanged) {
                                                this.props.onIsBoundChanged(this.state.siteIsBound);
                                            }
                                        }
                                    );
                                }}
                                hideBindButton={this.props.isAccountBound}
                                value={this.state.siteIsBound ? this.props.webAppName : this.state.selectedSiteIndex}
                                onChange={(value) => (this.state.siteIsBound ? this.props.onWebAppNameChanged(value!) : this.handleSelectedSiteChanged(value!))}
                                items={this.state.siteItems}
                                error={this.props.webAppNameError}
                                label="Web app"
                            />
                        }
                        actions={<>{!this.props.isAccountBound && <IconButton disabled={this.state.busy} onClick={() => this.getWebsitesIfNotBound(this.props)} toolTipContent="Refresh" icon={Icon.Refresh} />}</>}
                    />
                </BusyRefreshContainer>
                <Note>The name of your Azure Web App.</Note>
            </div>
        );
    }

    private handleSelectedSiteChanged = (value: string) => {
        const index = ParseHelper.safeParseInt(value, undefined);
        const selectedSite = !index ? this.state.sites[index] : null;

        this.props.onResourceGroupChanged(selectedSite ? selectedSite.ResourceGroup : null!);
        this.props.onWebAppNameChanged(selectedSite ? selectedSite.Name : null!);
        this.setState({ selectedSiteIndex: !index ? index.toString() : null! });
    };

    private resetWebSites() {
        this.setState({ sites: [] });
    }

    private async getWebSites(accountId: string) {
        this.setState(toggleBusy(true));
        try {
            await this.props.doBusyTask(async () => {
                if (!accountId) {
                    this.setState({
                        sites: [],
                        siteItems: [],
                        selectedSiteIndex: null!,
                    });
                    return;
                }
                const account = await repository.Accounts.get(accountId);
                const sites = await repository.Accounts.getWebSites(account);

                let selectedSiteIndex: string = null!;
                if (this.props.webAppName) {
                    const resourceGroupName = this.props.resourceGroupName === undefined ? null : this.props.resourceGroupName;
                    const selectedSite = sites.find((s) => s.Name === this.props.webAppName && s.ResourceGroup === resourceGroupName);
                    if (selectedSite) {
                        const index = sites.indexOf(selectedSite);
                        selectedSiteIndex = index < 0 ? null! : index.toString();
                    }
                }

                const siteItems = sites.map((site, index) => {
                    const details = site.ResourceGroup ? site.ResourceGroup + " " + site.Region : site.Region;
                    return {
                        value: index.toString(),
                        text: site.Name + " " + details,
                    };
                });

                this.setState({
                    sites,
                    siteItems,
                    selectedSiteIndex,
                });
            });
        } finally {
            this.setState(toggleBusy(false));
        }
    }
}

export default AzureWebSiteSelector;
