import * as React from "react";
import type { ProcessType } from "~/client/resources";
import { ProcessTypeAliasMap } from "~/client/resources";
import type { ChannelResource } from "~/client/resources/channelResource";
import type { DeploymentPromotionTarget } from "~/client/resources/deploymentTemplateResource";
import type { TenantResource } from "~/client/resources/tenantResource";
import { TenantedDeploymentMode } from "~/client/resources/tenantedDeploymentMode";
import { EnvironmentChip, LookupTenantChip } from "~/components/Chips/index";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import Note from "~/primitiveComponents/form/Note/Note";

interface HelpTextProps {
    doAnyTenantsMatchProject: boolean;
    doAnyTenantsMatchChannel: boolean;
    tenantedDeploymentMode: TenantedDeploymentMode;
    channel: ChannelResource | null;
    availableTenants: TenantResource[];
    availableEnvironmentsForTenantPromotion: DeploymentPromotionTarget[];
    tenantsWithMissingVariables: string[];
    allTenants: TenantResource[];
    processType: ProcessType;
}

const HelpText: React.StatelessComponent<HelpTextProps> = (props) => {
    const { alias, manifest } = ProcessTypeAliasMap[props.processType];

    if (!props.doAnyTenantsMatchProject) {
        if (props.tenantedDeploymentMode === TenantedDeploymentMode.Tenanted) {
            return (
                <Callout type={CalloutType.Danger} title="There are no tenants connected to this project.">
                    <p>
                        Connect at least one tenant to this project to start performing tenanted {alias.plural} {alias.preposition} this project. To do this, find the tenant, and connect it to this project targeting the desired environments.
                    </p>
                </Callout>
            );
        } else if (props.tenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted) {
            return (
                <Note>
                    There are no tenants connected to this project. Connect at least one tenant to this project to start performing tenanted {alias.plural} of this project. To do this, find the tenant, and connect it to this project targeting the
                    desired environments.
                </Note>
            );
        }
    }

    if (!props.doAnyTenantsMatchChannel && props.channel && props.channel.TenantTags.length !== 0) {
        const content = (
            <p>
                Releases in the {props.channel.Name} channel can only be {alias.pastTense} {alias.preposition} matching tenants. Either change the tenant filter on the channel, tag an existing tenant so they match the channel filter, or create a new
                tenant tagged to match the channel filter.
            </p>
        );
        if (props.tenantedDeploymentMode === TenantedDeploymentMode.Tenanted) {
            return (
                <Callout type={CalloutType.Danger} title={`There are no matching tenants for the ${props.channel.Name} channel.`}>
                    {content}
                </Callout>
            );
        } else if (props.tenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted) {
            return (
                <Note>
                    <p>There are no matching tenants for the {props.channel.Name} channel.</p>
                    {content}
                </Note>
            );
        }
    }

    if (props.availableTenants.length === 0) {
        if (props.tenantedDeploymentMode === TenantedDeploymentMode.Tenanted) {
            return (
                <Callout type={CalloutType.Danger} title={`Cannot ${alias.verb} ${alias.preposition} any of the connected tenants in this phase of the lifecycle`}>
                    <p>
                        Releases of this project can only be {alias.pastTense} {alias.preposition} tenants, but there are no tenants connected to any environments in the current lifecycle phase.
                    </p>
                    {props.availableEnvironmentsForTenantPromotion && props.availableEnvironmentsForTenantPromotion.length > 0 && (
                        <div>
                            Available environments are
                            {props.availableEnvironmentsForTenantPromotion.map((env: DeploymentPromotionTarget) => (
                                <EnvironmentChip environmentName={env.Name} key={env.Id} />
                            ))}
                            Either connect an existing tenant to one of the environments in this lifecycle phase, or create a new tenant and connect them to one of the environments in this lifecycle phase, or modify the lifecycle, or configure the
                            project to allow untenanted {alias.plural}.
                        </div>
                    )}
                </Callout>
            );
        } else if (props.tenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted) {
            return (
                <Note>
                    Cannot {alias.verb} {alias.preposition} any of the connected tenants in this phase of the lifecycle. Either perform an untenanted {alias.verb} of this {manifest} to an environment in this lifecycle phase, or connect an existing
                    tenant to one of the environments in this lifecycle phase, or create a new tenant and connect them to one of the environments in this lifecycle phase, or modify the lifecycle.
                </Note>
            );
        }
    }

    if (props.tenantsWithMissingVariables.length > 0) {
        return (
            <Callout type={CalloutType.Danger} title="Tenants with missing variables">
                {props.tenantsWithMissingVariables.map((tId) => {
                    return <LookupTenantChip lookupTenants={props.allTenants} id={tId} />;
                })}
                <p>
                    Some of the selected tenants are missing variable values required for this {alias.noun} to succeed. Either provide a value for the tenant or configure a default value for the variable template. See{" "}
                    <ExternalLink href="MultiTenantVariables" label="Multi tenant variables">
                        Multi tenant variables
                    </ExternalLink>{" "}
                    for more information.
                </p>
            </Callout>
        );
    }
    return null;
};

export default HelpText;
