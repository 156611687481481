import * as React from "react";
import type { PhaseResource } from "~/client/resources";
import PhaseStatusIcon from "~/components/PhaseStatusIcon/PhaseStatusIcon";
import { DataTableBody, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import type { LifecycleStatus } from "~/utils/MapProgressionToStatus/MapProgressionToStatus";
import styles from "./style.module.less";

interface PhaseHeaderProps {
    className?: string;
    phase: PhaseResource;
    lifecycleStatus: LifecycleStatus;
    isOptional: boolean;
    title: React.ReactNode;
    actionButton: React.ReactNode;
    environmentId?: string;
}

const PhaseHeader: React.StatelessComponent<PhaseHeaderProps> = (props) => {
    return (
        <DataTableBody>
            <DataTableRow className={props.className}>
                <DataTableRowColumn>
                    <PhaseStatusIcon phase={props.phase} status={props.lifecycleStatus} environmentId={props.environmentId} />
                    {props.title}
                </DataTableRowColumn>
                <DataTableRowColumn />
                <DataTableRowColumn />
                <DataTableRowColumn className={styles.deploymentRowButton}>
                    {props.actionButton}
                    <div className={styles.deploymentExpander} />
                </DataTableRowColumn>
            </DataTableRow>
        </DataTableBody>
    );
};

PhaseHeader.displayName = "PhaseHeader";
export default PhaseHeader;
