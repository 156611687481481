import * as React from "react";
import type { AccountProps } from "~/areas/infrastructure/components/AccountDisplay";
import AccountDisplay from "~/areas/infrastructure/components/AccountDisplay";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import routeLinks from "~/routeLinks";
import styles from "./style.module.less";

interface ReadonlyAccountProps {
    account: AccountProps;
    accountId: string;
    className?: string;
}

// This component is supposed to be styled the same as a <Text /> component.
// This idea being that if they were side by side and the text component was not focused and did not have an underline,
// then they would look identical
const ReadonlyAccount: React.SFC<ReadonlyAccountProps> = ({ className, accountId, account }) => {
    return (
        <div className={styles.container}>
            <div className={`${className} ${styles.content}`} title={account ? account.name : accountId}>
                <InternalLink to={routeLinks.infrastructure.account(accountId)}>{account ? account.name : accountId}</InternalLink>
            </div>
        </div>
    );
};

interface ReadonlyAccountDisplayProps {
    accountId: string;
    renderIcon?: () => React.ReactNode;
}

const ReadonlyAccountDisplay: React.SFC<ReadonlyAccountDisplayProps> = ({ accountId }) => <AccountDisplay accountId={accountId} render={({ account }) => <ReadonlyAccount account={account} accountId={accountId} />} />;

export default ReadonlyAccountDisplay;
