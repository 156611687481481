import type { PropertyMetadata } from "~/client/resources/dynamicFormResources";

export default class MetadataTypeHelpers {
    static isCompositeType(property: PropertyMetadata) {
        const validSimpleTypes: string[] = ["raw_list", "raw_map", "bool", "boolean", "string", "string[]", "long", "int", "bool?", "long?", "int?", "SensitiveValue"];
        return !validSimpleTypes.includes(property.Type) && !this.isArrayType(property);
    }

    static isValidatableType(property: PropertyMetadata) {
        const validValidationTypes: string[] = ["string", "string[]", "long", "int", "SensitiveValue"];
        return validValidationTypes.includes(property.Type);
    }

    static getRequiredMessage(property: PropertyMetadata) {
        return property.DisplayInfo.Label + " is required";
    }

    static isArrayType(property: PropertyMetadata) {
        return property.Type.endsWith("[]") && property.Type !== "string[]";
    }
}
