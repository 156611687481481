import { flatten, sortBy } from "lodash";
import * as React from "react";
import type { WorkerPoolResource, NewWorkerPoolResource } from "~/client/resources";
import { Permission } from "~/client/resources";
import type { DynamicWorkerType } from "~/client/resources/workerPoolsSupportedTypesResouce";
import { WorkerPoolType } from "~/client/resources/workerPoolsSupportedTypesResouce";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { required, Text, Note, RadioButtonGroup, RadioButton, Select } from "~/components/form";
import { DeprecatedDynamicWorkerTypeCallout, IsWorkerTypeAvailable } from "../DeprecatedWorkerType";

interface AddWorkerPoolsDialogContentProps {
    saveDone(workerPool: WorkerPoolResource): Promise<void>;
}

interface AddWorkerPoolsDialogContentState extends DataBaseComponentState {
    name: string;
    description: string;
    useGuidedFailure: boolean;
    newWorkerPoolId: string;
    workerPoolType: WorkerPoolType;
    supportedPoolTypes: WorkerPoolType[];
    workerTypes: DynamicWorkerType[];
    workerType: string;
}

export default class AddWorkerPoolsDialog extends DataBaseComponent<AddWorkerPoolsDialogContentProps, AddWorkerPoolsDialogContentState> {
    constructor(props: AddWorkerPoolsDialogContentProps) {
        super(props);
        this.state = {
            name: "",
            description: "",
            useGuidedFailure: false,
            newWorkerPoolId: "",
            workerPoolType: WorkerPoolType.Static,
            supportedPoolTypes: [],
            workerTypes: [],
            workerType: "",
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const result = await repository.WorkerPools.getSupportedPoolTypes();
            const dynamicWorkerTypes = result.SupportedPoolTypes.find((x) => x === WorkerPoolType.Dynamic) ? await repository.WorkerPools.getDynamicWorkerTypes() : [];
            this.setState({
                supportedPoolTypes: result.SupportedPoolTypes,
                workerTypes: dynamicWorkerTypes,
            });
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            const workerPool = this.buildNewWorkerPool();
            const result = await repository.WorkerPools.create(workerPool, {});
            this.setState({
                newWorkerPoolId: result.Id,
            });
            await this.props.saveDone(result);
        });
    }

    private buildNewWorkerPool(): NewWorkerPoolResource {
        const workerPool = {
            Name: this.state.name,
            Description: this.state.description,
            SortOrder: -1,
            IsDefault: false,
            CanAddWorkers: false,
        };
        if (this.state.workerPoolType === WorkerPoolType.Dynamic) {
            return { ...workerPool, WorkerPoolType: WorkerPoolType.Dynamic, WorkerType: this.state.workerType };
        }
        return { ...workerPool, WorkerPoolType: WorkerPoolType.Static };
    }

    render() {
        const items = this.state.workerTypes
            ? sortBy(
                  this.state.workerTypes.filter((wt) => IsWorkerTypeAvailable(wt)).map((t) => ({ value: t.Type, text: t.Description + (t.Type.endsWith("Default") ? " (default)" : "") })),
                  (t) => t.text
              )
            : [];
        const workerType = this.state.workerTypes.find((x) => x.Type === this.state.workerType);
        return (
            <SaveDialogLayout title={"Add Worker Pool"} busy={this.state.busy} errors={this.errors} savePermission={{ permission: Permission.WorkerEdit }} onSaveClick={() => this.save()} saveButtonDisabled={!this.state.name}>
                <Text label="New worker pool name" value={this.state.name} onChange={this.handleNameChange} autoFocus={true} validate={required("Please enter an worker pool name")} />
                {this.state.supportedPoolTypes && this.state.supportedPoolTypes.length > 1 ? (
                    <>
                        <div>
                            <RadioButtonGroup accessibleName="Worker Pool Type" label="Worker Pool Type" value={this.state.workerPoolType} onChange={this.handleWorkerPoolTypeChange}>
                                {flatten(this.state.supportedPoolTypes.map((t) => [<RadioButton value={t} label={this.getPoolTypeLabel(t)} key={t} />, <Note key={`${t}_note`}>{this.getWorkerPoolTypeDescription(t)}</Note>]))}
                            </RadioButtonGroup>
                        </div>
                        <div>
                            {this.state.workerPoolType === WorkerPoolType.Dynamic && (
                                <>
                                    <Select label="Worker Image" value={this.state.workerType} onChange={this.handleWorkerTypeChange} items={items} />
                                    <DeprecatedDynamicWorkerTypeCallout workerType={workerType} isNew={true} />
                                </>
                            )}
                        </div>
                    </>
                ) : null}
            </SaveDialogLayout>
        );
    }

    private handleNameChange = (value: string) => {
        this.setState({ name: value });
    };

    private handleWorkerPoolTypeChange = (value: WorkerPoolType) => {
        this.setState({
            workerPoolType: value,
        });
    };

    private handleWorkerTypeChange = (value: string | undefined) => {
        const workerType = this.state.workerTypes.find((l) => l.Type === value);
        if (!workerType) {
            throw Error("Could not locate selected worker image");
        }

        this.setState({ workerType: workerType.Type });
    };

    private getPoolTypeLabel(t: WorkerPoolType): string {
        switch (t) {
            case WorkerPoolType.Static:
                return "Static";
            case WorkerPoolType.Dynamic:
                return "Dynamic";
        }
    }

    private getWorkerPoolTypeDescription(t: WorkerPoolType): string {
        switch (t) {
            case WorkerPoolType.Static:
                return "A static worker pool allows you to add your own workers for executing deployments";
            case WorkerPoolType.Dynamic:
                return "The dynamic worker pool will lease a worker from the Worker service to use for a deployment";
        }
    }
}
