import React from "react";
import styles from "./style.module.less";

export interface ColorPaletteProps {
    colors: string[];
    selectedColor: string | undefined;
    onColorChange: (color: string) => void;
}

const ColorPaletteSelector = ({ colors, selectedColor, onColorChange }: ColorPaletteProps) => {
    return (
        <div className={styles.colourPaletteContainer}>
            {colors.map((color) => (
                <button
                    aria-label={color}
                    aria-pressed={selectedColor === color}
                    className={selectedColor === color ? [styles.colourPaletteItem, styles.selected].join(" ") : styles.colourPaletteItem}
                    key={color}
                    onClick={() => onColorChange(color)}
                    style={{ backgroundColor: color }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            onColorChange(color);
                        }
                    }}
                />
            ))}
        </div>
    );
};

export default ColorPaletteSelector;
