import cn from "classnames";
import type { History } from "history";
import { forwardRef } from "react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { client } from "~/clientInstance";
import { exposeComponentAsClass } from "../exposeComponentAsClass";
import { resolvePathWithSpaceId } from "../resolvePathWithSpaceId";
import styles from "./style.module.less";

type LocationDescriptor = History.LocationDescriptor;

export type FontWeight = "normal" | "bold" | "bolder" | "lighter" | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

interface InternalLinkComponentProps {
    size?: number;
    weight?: FontWeight;
    width?: string;
    color?: string;
    className?: string;
    to: LocationDescriptor;
    openInSelf?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
    spaceId?: string;
    accessibleName?: string;
}

type InternalLinkProps = InternalLinkComponentProps & RouteComponentProps<{ spaceId?: string }>;

const InternalLink = forwardRef<HTMLAnchorElement, InternalLinkProps>((props, ref) => {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement, {}>) => {
        if (props.onClick) {
            props.onClick();
        }
        e.stopPropagation();
    };

    const style: React.CSSProperties = {};
    if (props.size) {
        style.fontSize = props.size + "rem";
    }
    if (props.weight) {
        style.fontWeight = props.weight;
    }
    if (props.width) {
        style.width = props.width;
    }
    if (props.color) {
        style.color = props.color;
    }

    // The client's spaceId and the route's spaceId should be in sync,
    // but because we haven't modelled this relationship in a reliable way,
    // this is not guaranteed to be true, particularly in tests
    const spaceId = (props.spaceId || props.match.params.spaceId || client.spaceId) ?? undefined;

    /* eslint-disable react/forbid-elements */
    return (
        <Link aria-label={props.accessibleName} className={cn(styles.internalLink, props.className)} style={style} to={resolvePathWithSpaceId(props.to, spaceId)} target={props.openInSelf ? "_self" : "_blank"} onClick={onClick} innerRef={ref}>
            {props.children}
        </Link>
    );
});

InternalLink.defaultProps = {
    openInSelf: true,
};

export default exposeComponentAsClass(withRouter(InternalLink));
