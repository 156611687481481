import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/Page";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { renderWithLayout } from "~/components/RenderWithLayout/RenderWithLayout";
import pageIds from "~/pageIds";
import routeLinks from "../../../routeLinks";
import DashboardConfiguration from "../DashboardConfiguration/DashboardConfiguration";
import DashboardLayout from "../DashboardLayout";
import DashboardOverview from "../DashboardOverview/DashboardOverview";

const dashboardConfiguration = renderWithLayout(DashboardLayout)(DashboardConfiguration);

const DashboardConfigurationPage = withPage({ page: pageIds.dashboard.configure })(dashboardConfiguration);
const DashboardOverviewPage = withPage({ page: pageIds.dashboard.root })(DashboardOverview);

const DashboardRoutes: React.StatelessComponent<RouteComponentProps<{}>> = (props) => {
    return (
        <Switch>
            <ReloadableRoute path={routeLinks.dashboard.configure} component={DashboardConfigurationPage} />
            <ReloadableRoute path={routeLinks.dashboard.root} component={DashboardOverviewPage} />
        </Switch>
    );
};

export default DashboardRoutes;
