/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { IdentityMetadataResource } from "~/client/authentication/authenticationProviderElement";
import type { ClaimsBasedIdentity } from "~/client/resources/identityResource";
import type IdentityType from "~/client/resources/identityType";
import { AddIdentityDialog } from "~/components/AddIdentityDialog/AddIdentityDialog";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { Summary, ExpandableFormSection } from "~/components/form";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip/index";

class IdentityList extends RemoveItemsList<ClaimsBasedIdentity> {}

export interface ProviderWithUserSettings {
    providerName: string;
    providerDetails: IdentityMetadataResource;
    identities: ClaimsBasedIdentity[];
    identityType: IdentityType;
}

interface ProviderIdentitiesProps {
    provider: ProviderWithUserSettings;
    enabledToSupportAdding: boolean;
    canCurrentUserEditIdentitiesForUser: boolean;
    onChange(providerGroup: ProviderWithUserSettings): void;
}

export class ProviderIdentities extends DataBaseComponent<ProviderIdentitiesProps, {}> {
    constructor(props: ProviderIdentitiesProps) {
        super(props);
    }

    render() {
        const addAction = (
            <OpenDialogButton key={`addIdentity-${this.props.provider.providerName}`} label="Add Login">
                <AddIdentityDialog selectedProvider={this.props.provider.providerDetails} key={`addEmail-${this.props.provider.providerName}`} onAddIdentity={this.addIdentity} />
            </OpenDialogButton>
        );

        return (
            <ExpandableFormSection errorKey={this.props.provider.providerName} title={this.props.provider.providerName} summary={this.providerIdentitiesSummary()} help={`A set of accounts for ${this.props.provider.providerName}.`}>
                <div>
                    <IdentityList
                        data={this.props.provider.identities}
                        listActions={this.props.enabledToSupportAdding && this.props.canCurrentUserEditIdentitiesForUser && this.props.provider.identities.length === 0 ? [addAction] : []}
                        onRow={(identity, i) => <div key={`${this.props.provider}-${i}`}>{this.renderIdentityDetails(this.props.provider.providerDetails, identity)}</div>}
                        onRemoveRow={this.props.canCurrentUserEditIdentitiesForUser ? (identity) => this.removeIdentity(identity) : null!}
                    />
                </div>
            </ExpandableFormSection>
        );
    }

    providerIdentitiesSummary = () => {
        const qty = this.props.provider.identities.length;
        const msg = qty > 0 ? this.identityClaims() : "No logins are associated";

        return Summary.placeholder(`${msg}`);
    };

    identityClaims = () => {
        const claims = this.props.provider.identities[0].Claims;
        const claimKey = Object.keys(claims).filter((key) => claims[key].IsIdentifyingClaim && claims[key].Value)[0] || Object.keys(claims).filter((key) => claims[key].IsIdentifyingClaim)[0];

        return `${this.claimLabel(claimKey, this.props.provider.providerDetails)}: ${claims[claimKey].Value}`;
    };

    renderIdentityDetails = (provider: IdentityMetadataResource, identity: ClaimsBasedIdentity) => {
        const claims = identity.Claims;
        const keys = Object.keys(claims);
        return (
            <div>
                {" "}
                {Object.keys(claims).map((key, i) => (
                    <div key={i}>
                        {" "}
                        {claims[key].Value && (
                            <ToolTip content={this.claimDescription(key, provider)}>
                                {claims[key].IsIdentifyingClaim ? (
                                    <ListTitle>
                                        {this.claimLabel(key, provider)}: {claims[key].Value}
                                    </ListTitle>
                                ) : (
                                    <div>
                                        {this.claimLabel(key, provider)}: {claims[key].Value}
                                    </div>
                                )}
                            </ToolTip>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    addIdentity = (identity: ClaimsBasedIdentity) => {
        this.props.onChange({
            ...this.props.provider,
            identities: [identity, ...this.props.provider.identities],
        });

        return true;
    };

    removeIdentity = async (identity: ClaimsBasedIdentity) => {
        this.props.onChange({
            ...this.props.provider,
            identities: [...this.props.provider.identities.filter((i) => i !== identity)],
        });

        return true;
    };

    claimLabel(key: string, provider: IdentityMetadataResource) {
        return this.findProviderByKey(key, provider)?.Label || key;
    }

    claimDescription(key: string, provider: IdentityMetadataResource) {
        return this.findProviderByKey(key, provider)?.Description || "No description provided";
    }

    findProviderByKey(key: string, provider: IdentityMetadataResource) {
        return provider.ClaimDescriptors.find((d: Record<string, {}>) => d.Type === key);
    }
}
