import Permission from "~/client/resources/permission";
import { client } from "~/clientInstance";
import { pageId } from "~/utils/pageId";
import tenantRouteLinks from "./tenantRouteLinks";

const tenantLinks = tenantRouteLinks(client.spaceId ?? "");

export default () => ({
    tenants: pageId({ area: "Tenants", id: "Tenants", name: "Tenants", routeLink: tenantLinks.tenants, routePermission: { permission: Permission.TenantView, wildcard: true } }),
    tenant: {
        root: pageId({ area: "Tenants", id: "Tenants", name: "Tenants", routeLink: null }),
        overview: pageId({ area: "Tenants", id: "Tenant.Overview", name: "Tenant Overview", routeLink: null }),
        settings: pageId({ area: "Tenants", id: "Tenant.Settings", name: "Tenant Settings", routeLink: null }),
        tasks: pageId({ area: "Tenants", id: "Tenant.Tasks", name: "Tenant Tasks", routeLink: null }),
        variables: pageId({ area: "Tenants", id: "Tenant.Variables", name: "Tenant Variables", routeLink: null }),
    },
});
