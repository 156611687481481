import type { PackagingRegistration } from "../../Registry/packagingRegistry";
import BuildServerPackagingInstruction from "./BuildServerPackagingInstruction";
import CommandLinePackagingInstruction from "./CommandLinePackagingInstruction";
import ManualPackagingInstruction from "./ManualPackagingInstruction";

export const JavaPackagingRegistration: PackagingRegistration = {
    displayOrder: 400,
    instructions: [BuildServerPackagingInstruction, ManualPackagingInstruction, CommandLinePackagingInstruction],
    name: "Java",
    id: "Java",
};

export default JavaPackagingRegistration;
