import type { Client } from "../client";
import type { NewScopedUserRoleResource, ScopedUserRoleResource } from "../resources/scopedUserRoleResource";
import MixedScopeBaseRepository from "./mixedScopeBaseRepository";

class ScopedUserRoleRepository extends MixedScopeBaseRepository<ScopedUserRoleResource, NewScopedUserRoleResource> {
    constructor(client: Client) {
        super("ScopedUserRoles", client);
    }
}

export default ScopedUserRoleRepository;
