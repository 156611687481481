import { createHookHOC } from "~/utils/createHookHOC";
import type { IScriptActionContext } from "./ScriptActionContext";
import { useScriptActionContext } from "./ScriptActionContext";

export type WithScriptActionContextInjectedProps = {
    scriptActionContext: IScriptActionContext | undefined;
};

export const mapScriptActionContextToInjectedProps = (context: IScriptActionContext | undefined) => ({ scriptActionContext: context });

export const withScriptActionContext = createHookHOC(useScriptActionContext, "WithScriptActionContext");
