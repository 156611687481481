import * as React from "react";
import BaseComponent from "../BaseComponent";

interface State {
    value: string;
}

export default class LateStateChangesChild extends BaseComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = { value: "Test" };
    }

    async componentDidMount() {
        setTimeout(() => this.setState({ value: "This should not be visible and there should be no warning in the console." }), 5000);
    }

    render() {
        return <span>{this.state.value}</span>;
    }
}
