/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import Note from "~/primitiveComponents/form/Note/Note";
import OkDialogLayout from "../../../../components/DialogLayout/OkDialogLayout";
import Select from "../../../../primitiveComponents/form/Select/Select";
import Text from "../../../../primitiveComponents/form/Text/Text";

interface ModifyTaskStateDialogProps {
    availableStates: string[];
    currentTaskState: string;
    onStateChanged(newTaskState: string, reason: string): void;
}

interface ModifyTaskStateDialogState extends DataBaseComponentState {
    newTaskState: string;
    reason: string;
}

export default class ModifyTaskStateDialog extends DataBaseComponent<ModifyTaskStateDialogProps, ModifyTaskStateDialogState> {
    constructor(props: ModifyTaskStateDialogProps) {
        super(props);
        this.state = {
            newTaskState: null!,
            reason: null!,
        };
    }

    save = async () => {
        this.props.onStateChanged(this.state.newTaskState, this.state.reason);

        //this.props.onStateChanged();
        return true;
    };

    render() {
        const isDisabled = !this.state.newTaskState || this.state.newTaskState === this.props.currentTaskState || !this.state.reason || this.state.reason === "";
        return (
            <OkDialogLayout title="Modify Task State" busy={this.state.busy} errors={this.errors} okButtonLabel="Apply" okButtonDisabled={isDisabled} onOkClick={() => this.save()}>
                <Select
                    label="State"
                    placeholder="The desired state of this task"
                    autoFocus
                    value={this.state.newTaskState || this.props.currentTaskState}
                    items={this.props.availableStates.map((t) => ({
                        value: t,
                        text: t === this.props.currentTaskState ? `${t} (current)` : t,
                    }))}
                    onChange={(newState) => this.setState({ newTaskState: newState! })}
                />
                <Text label="Reason" value={this.state.reason} multiline={true} onChange={(reason) => this.setState({ reason })} />
                <Note>Provide a reason for changing the state of this task</Note>
                <Callout type={CalloutType.Information} title={"Auto-deploys will not be affected"}>
                    Changing the state will not have any knock-on effects for auto-deploys. For example, changing it to successful will not cause it be treated as successful on a re-deploy trigger.
                </Callout>
            </OkDialogLayout>
        );
    }
}
