import type { PropsWithChildren } from "react";
import React from "react";
import { useProjectContext } from "~/areas/projects/context";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";

interface FeatureNotSupportedForConfigAsCodeProps {
    callout?: (defaultElement: React.ReactElement) => React.ReactElement;
}

const FeatureNotSupportedCallout = () => (
    <Callout type={CalloutType.Information} title="Feature not supported">
        <p>Some features for version controlled projects are still in development and this feature is not yet supported.</p>
    </Callout>
);

const FeatureNotSupportedForConfigAsCode: React.FC<PropsWithChildren<FeatureNotSupportedForConfigAsCodeProps>> = ({ children, callout = (el) => el }) => {
    const projectContext = useProjectContext();
    if (projectContext.state.model.IsVersionControlled) {
        return callout(<FeatureNotSupportedCallout />);
    }
    return <>{children}</>;
};

export default FeatureNotSupportedForConfigAsCode;
