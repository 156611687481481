import * as React from "react";
import { LinksMenu } from "~/components/LinksMenu/LinksMenu";
import type { MenuNode } from "~/components/LinksMenu/MenuNode";

interface LinksMenuButtonProps {
    activeItemClassName: string;
    icon?: string;
    label: string;
    moreItems: MenuNode[];
}

export const LinksMenuButton: React.StatelessComponent<LinksMenuButtonProps> = (props) => (
    <li key="navItem-more">
        <LinksMenu items={props.moreItems} label={props.label} icon={props.icon} />
    </li>
);
