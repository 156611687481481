import type { ContainerImageReference, Discriminator, PackageReference, SensitiveValue } from "@octopusdeploy/step-inputs";
import type { InputJsonSchema, ObjectDefinition, UnionDefinition } from "@octopusdeploy/step-runtime-inputs";

export enum Options {
    "First Option",
    "Second Option",
    "Third Option",
}

export interface SampleStepInputs {
    string: string;
    sensitiveValue: SensitiveValue;
    package: PackageReference;
    union: Union;
    secondUnion: Union;
    containerImage: ContainerImageReference;
    list: Array<{
        string: string;
        needsTransformation: number;
        nestedList: Array<{
            nestedString: string;
        }>;
    }>;
    inlineList: Array<{
        name: string;
        value: string;
        disable: boolean;
        casing: "upper-case" | "lower-case" | "upper-and-lower-case";
    }>;
    boolean: boolean;
    region: "us-east1" | "us-east2" | "au-east1";
    select: "select-1" | "select-2" | "select-3";
    number: number;
    nonDiscriminatorSelect: { selectedOption: Options };
}

export type Union = FirstType | SecondType;

export interface FirstType {
    type: Discriminator<"first">;
    valueFromFirst: string;
}

export interface SecondType {
    type: Discriminator<"second">;
    valueFromSecond: SensitiveValue;
}

const unionSchema: UnionDefinition = {
    anyOf: [
        {
            type: "object",
            properties: {
                type: { type: "string", const: "first", isDiscriminator: true },
                valueFromFirst: { type: "string" },
            },
            required: ["type", "valueFromFirst"],
        },
        {
            type: "object",
            properties: {
                type: { type: "string", const: "second", isDiscriminator: true },
                valueFromSecond: { type: "object", isSensitiveValue: true },
            },
            required: ["type", "valueFromSecond"],
        },
    ],
};

const listSchema: ObjectDefinition = {
    type: "object",
    properties: {
        string: { type: "string" },
        needsTransformation: { type: "number" },
        nestedList: { type: "array", items: { type: "object", properties: { nestedString: { type: "string" } }, required: ["nestedString"] } },
    },
    required: ["string", "needsTransformation", "nestedList"],
};

const inlineListSchema: ObjectDefinition = {
    type: "object",
    properties: {
        name: { type: "string" },
        value: { type: "string" },
        disable: { type: "boolean" },
        casing: { type: "string", enum: ["upper-case", "lower-case", "upper-and-lower-case"] },
    },
    required: ["name", "value", "disable", "casing"],
};

export const sampleStepInputSchema: InputJsonSchema = {
    definitions: {
        MyInputs: {
            type: "object",
            isRoot: true,
            properties: {
                string: { type: "string" },
                sensitiveValue: { type: "object", isSensitiveValue: true },
                package: { type: "object", isPackageReference: true },
                containerImage: { type: "object", isContainerImageReference: true },
                list: { type: "array", items: listSchema },
                inlineList: { type: "array", items: inlineListSchema },
                union: unionSchema,
                secondUnion: unionSchema,
                boolean: { type: "boolean" },
                region: { type: "string", enum: ["us-east1", "us-east2"] },
                select: { type: "string", enum: ["select-1", "select-2", "select-3"] },
                number: { type: "number" },
                nonDiscriminatorSelect: { type: "object", properties: { selectedOption: { type: "string" } } },
            },
            required: ["string", "sensitiveValue", "package", "containerImage", "list", "inlineList", "union", "secondUnion", "boolean", "region", "select", "number"],
        },
    },
};
