import * as React from "react";
import type { ChannelResource, ProjectResource } from "~/client/resources";
import { TenantedDeploymentMode } from "~/client/resources";
import { DefaultOptionChip } from "~/components/Chips";
import MarkdownDescription from "~/components/MarkdownDescription";
import TenantTagsList from "~/components/TenantTagsList/TenantTagsList";
import type { TagIndex } from "~/components/tenantTagsets";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import type { Lifecycles } from "./Channels";
import RulesDescriptionList from "./RulesDescriptionList";
import styles from "./style.module.less";

interface ChannelProps {
    channel: ChannelResource;
    project: ProjectResource;
    lifecycles: Lifecycles;
    tagIndex: TagIndex;
}

const Channel: React.StatelessComponent<ChannelProps> = (props) => {
    const getLifecycleName = () => {
        if (props.channel.LifecycleId) {
            return props.lifecycles[props.channel.LifecycleId].Name;
        }

        const projectLifecycle = props.lifecycles[props.project.LifecycleId].Name;
        return `${projectLifecycle} (inherited from project)`;
    };
    const channel = props.channel;

    return (
        <div>
            <ListTitle>
                {channel.Name}
                {channel.IsDefault && <DefaultOptionChip />}
            </ListTitle>
            <div className={styles.description}>
                <MarkdownDescription markup={channel.Description} />
            </div>
            <div className={styles.grid}>
                <div className={styles.row}>
                    <div className={styles.header}>Lifecycle</div>
                    <div className={styles.value}>{getLifecycleName()}</div>
                </div>
                {channel.TenantTags.length > 0 && props.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted && (
                    <div className={styles.row}>
                        <div className={styles.header}>Limited to these tenants</div>
                        <div className={styles.value}>
                            <TenantTagsList tags={channel.TenantTags} />
                        </div>
                    </div>
                )}
                {channel.Rules.length > 0 && (
                    <div className={styles.row}>
                        <div className={styles.header}>Version rules</div>
                        <div className={styles.value}>
                            <RulesDescriptionList rules={channel.Rules} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Channel;
