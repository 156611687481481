import React from "react";
import { Snackbar } from "~/primitiveComponents/feedback/Snackbar";
import { useWebpackHotModuleProgress } from "./useWebpackHotModuleProgress";

export const BuildProgressSnackbar: React.FC = () => {
    const progress = useWebpackHotModuleProgress();
    const text = "[WDS] ".concat(progress.percent.toString(), "% - ").concat(progress.message, ".");
    return <Snackbar open={progress.percent > 0 && progress.percent < 100} content={text} size="large" />;
};

export default BuildProgressSnackbar;
