import React from "react";
import type { MenuState } from "~/primitiveComponents/navigation/Menu/CustomMenu";
import { CustomMenu } from "~/primitiveComponents/navigation/Menu/CustomMenu";
import type { SimpleMenuItem } from "~/primitiveComponents/navigation/MenuItems/SimpleMenuItems";
import { SimpleMenuItems } from "~/primitiveComponents/navigation/MenuItems/SimpleMenuItems";

export interface SimpleMenuProps {
    menuState: MenuState;
    items: SimpleMenuItem[];
    accessibleName: string;
    compact?: boolean;
}

export function SimpleMenu({ menuState, items, accessibleName, compact }: SimpleMenuProps) {
    return (
        <CustomMenu menuState={menuState} accessibleName={accessibleName}>
            <SimpleMenuItems items={items} onClose={menuState.onClose} autoFocus={true} compact={compact} />
        </CustomMenu>
    );
}
