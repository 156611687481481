import { flatten, memoize } from "lodash";
import * as React from "react";
import type { AllVariableSaveWarnings } from "~/areas/variables/VariableSaveWarnings/VariableSaveWarnings";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import StringHelper from "~/utils/StringHelper";
import type { DuplicatedVariableName } from "../VariableMessages/VariableMessages";
import styles from "./style.module.less";

interface VariableSaveConfirmationDialogProps {
    content?: VariableSaveConfirmationContent; // Dialog is shown when content exists
    onClosed(): void;
    onSaveClick(): Promise<void>;
}

export default class VariableSaveConfirmationDialog extends React.Component<VariableSaveConfirmationDialogProps> {
    constructor(props: VariableSaveConfirmationDialogProps) {
        super(props);
        this.state = {};
    }

    render() {
        const showModal = !!this.props.content;
        const variableNameNonPrintableCharacterWarnings: ReadonlyArray<string> = this.props.content ? this.props.content.variableNameNonPrintableCharacterWarningMessages : [];
        const variableValueNonPrintableCharacterWarnings: ReadonlyArray<string> = this.props.content ? this.props.content.variableValueNonPrintableCharacterWarnings : [];
        const duplicateVariableNames: ReadonlyArray<DuplicatedVariableName> = this.props.content ? this.props.content.duplicateVariableNames : [];
        const variableValueSubstitutionSyntaxWarning: ReadonlyArray<string> = this.props.content ? this.props.content.variableValueSubstitutionSyntaxWarning : [];
        const nonPrintableCharacterWarnings = [...variableNameNonPrintableCharacterWarnings, ...variableValueNonPrintableCharacterWarnings];
        const title = getTitle();

        return (
            <ConfirmationDialog
                title={title}
                continueButtonLabel={"Save Anyway"}
                continueButtonBusyLabel={"Saving..."}
                open={showModal}
                onClose={() => this.props.onClosed()}
                onContinueClick={async () => {
                    await this.props.onSaveClick();
                    return true;
                }}
            >
                {nonPrintableCharacterWarnings.length > 0 && (
                    <div>
                        Non printable characters found:
                        <ul className={styles.warningList}>
                            {nonPrintableCharacterWarnings.map((sw) => (
                                <li key={sw}>{StringHelper.capitalizeFirstLetter(sw)}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {duplicateVariableNames.length > 0 && (
                    <div>
                        <ul className={styles.warningList}>
                            {duplicateVariableNames.map((dn) => (
                                <li key={dn.normalizedName}>The variables {this.humanize(dn.originalNames)} will be merged into a single variable</li>
                            ))}
                        </ul>
                    </div>
                )}
                {variableValueSubstitutionSyntaxWarning.length > 0 && (
                    <div>
                        <strong>##&#123;</strong> characters will be escaped and won't be evaluated for variable substitution:
                        <ul className={styles.warningList}>
                            {variableValueSubstitutionSyntaxWarning.map((dn) => (
                                <li key={dn}>
                                    Value of variable '{dn}' contains <strong>##&#123;</strong> characters
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </ConfirmationDialog>
        );
    }

    private humanize(names: ReadonlyArray<string>) {
        return names.map((name, index) => (index !== names.length - 1 ? `"${name}", ` : `and "${name}"`)).join("");
    }
}

function getTitle(): string {
    return "Warnings found in variables";
}

export class VariableSaveConfirmationContent {
    private readonly getVariableNameNonPrintableCharacterWarningMessages: () => ReadonlyArray<string>;
    private readonly getVariableValueNonPrintableCharacterWarnings: () => ReadonlyArray<string>;
    private readonly getVariableNamesWithSyntaxSubstitution: () => ReadonlyArray<string>;
    private readonly getDuplicateVariableNames: () => ReadonlyArray<DuplicatedVariableName>;

    constructor(warnings: AllVariableSaveWarnings) {
        this.getVariableNameNonPrintableCharacterWarningMessages = memoize(() => flatten<string>(warnings.variableWarnings.map((w) => [...w.variableNameNonPrintableCharacterWarningMessages])));
        this.getVariableValueNonPrintableCharacterWarnings = memoize(() => flatten<string>(warnings.variableValueMessages.map((vw) => [...vw.variableValueNonPrintableCharacterWarningMessages])));
        this.getVariableNamesWithSyntaxSubstitution = memoize(() => flatten<string>(warnings.variableValueMessages.map((vw) => vw.variableSubtitutionSyntaxWarningMessage).filter((vw) => !!vw)));
        this.getDuplicateVariableNames = memoize(() => warnings.duplicateVariableNames);
    }

    get variableNameNonPrintableCharacterWarningMessages() {
        return this.getVariableNameNonPrintableCharacterWarningMessages();
    }

    get variableValueNonPrintableCharacterWarnings() {
        return this.getVariableValueNonPrintableCharacterWarnings();
    }

    get duplicateVariableNames() {
        return this.getDuplicateVariableNames();
    }

    get variableValueSubstitutionSyntaxWarning() {
        return this.getVariableNamesWithSyntaxSubstitution();
    }

    get hasContent(): boolean {
        return !!this.variableNameNonPrintableCharacterWarningMessages.length || !!this.variableValueNonPrintableCharacterWarnings.length || !!this.duplicateVariableNames.length || !!this.variableValueSubstitutionSyntaxWarning.length;
    }
}
