import type { PropsWithChildren } from "react";
import * as React from "react";
import { useState } from "react";
import isWithinLicenceLimit from "~/areas/configuration/components/License/isWithinLicenceLimit";
import type { CategorizedEndpointRegistration, CategoryDefinition, EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry, { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import type { LicenseStatusResource } from "~/client/resources/licenseStatusResource";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import DataLoader from "~/components/DataLoader";
import Dialog from "~/components/Dialog/Dialog";
import { WorkerLimitReachedDialogLayout } from "~/components/LicenseLimitReachedDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import PaperLayout from "~/components/PaperLayout";
import routeLinks from "~/routeLinks";
import { useSpaceAwareNavigation } from "../../../../components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { EndpointSelector } from "../MachineSettings/Endpoints/EndpointSelector";

const WorkerMachineNewDataLoader = DataLoader<LoadedData>();
interface LoadedData {
    licenseStatus: LicenseStatusResource;
    registrations: EndpointRegistration[];
}

export interface WorkerMachineNewProps {
    workerPoolId: string | undefined;
}

export function WorkerMachineNew(props: WorkerMachineNewProps) {
    return (
        <WorkerMachineNewDataLoader
            load={async () => {
                const licenseStatus = repository.Licenses.getCurrentStatus();
                const registrations = endpointRegistry.getAllMachines();
                return {
                    registrations: registrations,
                    licenseStatus: await licenseStatus,
                };
            }}
            renderAlternate={({ busy, errors }) => <WorkerMachineNewLayout busy={busy} errors={errors} />}
            renderWhenLoaded={(loadedData) => <WorkerMachineNewWhenLoaded loadedData={loadedData} workerPoolId={props.workerPoolId} />}
        />
    );
}

interface WorkerMachineNewWhenLoadedProps {
    loadedData: LoadedData;
    workerPoolId: string | undefined;
}

function WorkerMachineNewWhenLoaded({ loadedData, workerPoolId }: WorkerMachineNewWhenLoadedProps) {
    const { licenseStatus, registrations } = loadedData;
    const navigate = useNavigateToWorker(workerPoolId);
    const isWithinWorkerLimit = isWithinLicenceLimit(licenseStatus, "Workers");
    const [isUpgradeDialogOpen, setUpgradeDialogOpen] = useState(!isWithinWorkerLimit);
    const categories = endpointRegistry.categorizeEndpoints(registrations);

    return (
        <WorkerMachineNewLayout>
            <EndpointSelector heading={<Heading />} registrations={registrations} categories={categories} onSelect={navigate} scope={EndpointSelectionScope.Worker} />

            <Dialog open={isUpgradeDialogOpen}>
                <WorkerLimitReachedDialogLayout
                    onActionClick={() => {
                        setUpgradeDialogOpen(false);
                        window.history.back();
                    }}
                />
            </Dialog>
        </WorkerMachineNewLayout>
    );
}

function WorkerMachineNewLayout({ busy, errors, children }: PropsWithChildren<{ busy?: boolean; errors?: Errors }>) {
    return (
        <PaperLayout busy={busy} errors={errors} title="Add Worker" fullWidth={true} flatStyle={true} breadcrumbTitle={"Workers"} breadcrumbPath={routeLinks.infrastructure.workerMachines.root}>
            {children}
        </PaperLayout>
    );
}

function Heading() {
    return (
        <React.Fragment>
            What type of <strong>Worker</strong> do you want to set up? Learn more about <ExternalLink href="Worker">Workers</ExternalLink>
        </React.Fragment>
    );
}

function useNavigateToWorker(workerPoolId: string | undefined) {
    const navigation = useSpaceAwareNavigation();

    return React.useCallback(
        (registration: CategorizedEndpointRegistration, category: CategoryDefinition) => {
            if (endpointRegistry.isMachineRegistration(registration) && registration.discoverable) {
                return navigation.navigate(routeLinks.infrastructure.workerMachines.discover(registration.key, workerPoolId, category?.category));
            } else {
                return navigation.navigate(routeLinks.infrastructure.workerMachines.create({ type: registration.communicationStyle, environment: workerPoolId }));
            }
        },
        [workerPoolId, navigation]
    );
}
