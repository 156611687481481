import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import routeLinks from "../../routeLinks";
import RunbookRunToRunbookTaskRedirect from "./RunbookRunToRunbookTaskRedirect";

export default class RunbookRunRoutes extends React.Component<RouteComponentProps> {
    render() {
        return (
            <Switch>
                <ReloadableRoute path={routeLinks.runbookRun(":runbookRunId").root} component={RunbookRunToRunbookTaskRedirect} />
            </Switch>
        );
    }
}
