import React from "react";

const minDrawerHeight = 50;
const maxDrawerHeight = 1000;

export function useDrawerResizableHeight(initialHeight: number = 500) {
    const [height, setHeight] = React.useState(initialHeight);

    const handleResize = React.useCallback(
        (e) => {
            const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

            const newHeight = windowHeight - e.clientY;
            if (newHeight > minDrawerHeight && newHeight < maxDrawerHeight) {
                setHeight(newHeight);
            }
        },
        [setHeight]
    );

    const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleResize, true);
        e.stopPropagation();
        e.preventDefault();
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleResize, true);
    };

    return { height, handleMouseDown };
}
