import * as React from "react";
import { NoResults as NoResultsImage } from "../Images/NoResults/NoResults";
import styles from "./NoResults.module.less";
export class NoResults extends React.PureComponent<{}> {
    render() {
        return (
            <div className={styles.container} data-testid="no-results">
                <NoResultsImage />
            </div>
        );
    }
}
