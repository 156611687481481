import * as React from "react";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";

const RunbookOnboarding = () => {
    return (
        <OnboardingPage
            title="Add steps to your Runbook"
            intro={
                <span>
                    The steps for a Runbook are like a recipe. You define the recipe by adding steps and variables to a Runbook.
                    <br />
                    Each step contains a specific action (or set of actions) that is executed each time your Runbook is run.
                </span>
            }
            learnMore={<ExternalLink href="OnboardingRunbooksLearnMore">Learn more</ExternalLink>}
        />
    );
};

export default RunbookOnboarding;
