import type { Client } from "../client";
import type PerformanceConfigurationResource from "../resources/performanceConfigurationResource";
import ConfigurationRepository from "./configurationRepository";

class PerformanceConfigurationRepository extends ConfigurationRepository<PerformanceConfigurationResource> {
    constructor(client: Client) {
        super("PerformanceConfiguration", client);
    }
}

export default PerformanceConfigurationRepository;
