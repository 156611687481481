import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationDiagnosticsOverview, CommonConfigurationDiagnosticsTopics } from "../../CommonComponents/CommonConfigurationDiagnosticsViews";

export const ConfigurationDiagnosticLogsAutoDeployRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.diagnostics.logs.autoDeploy,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationDiagnosticsOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationDiagnosticsTopics />;
    },
};
