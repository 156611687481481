import React from "react";
import type { DeploymentProcessResource, ProjectResource } from "~/client/resources";
import type { DeploymentActionPackageResource } from "~/client/resources/deploymentActionPackageResource";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note, RadioButton } from "~/components/form";
import { BooleanRadioButtonGroup } from "~/primitiveComponents/form/RadioButton";
import type { DeploymentSettingsModel } from "./DeploymentProcessSettings";
import { ReleaseVersionFromPackageSelector } from "./ReleaseVersionFromPackageSelector";
import { ReleaseVersionTemplateInput } from "./ReleaseVersionTemplateInput";

interface ReleaseVersioningProps {
    project: ProjectResource;
    setModelProp: <K extends keyof DeploymentSettingsModel>(model: Pick<DeploymentSettingsModel, K>) => void;
    versioningStrategyTemplate: string;
    versionFromPackage: boolean;
    versioningStrategyPackage: DeploymentActionPackageResource | undefined;
    versionPackageActions: DeploymentActionPackageResource[];
    deploymentProcess: DeploymentProcessResource | undefined;
}

export const ReleaseVersioning: React.FC<ReleaseVersioningProps> = (props: ReleaseVersioningProps) => (
    <>
        <BooleanRadioButtonGroup accessibleName="release versioning strategy" value={props.versionFromPackage} onChange={(versionFromPackage) => props.setModelProp({ versionFromPackage })}>
            <RadioButton value={false} label="Generate version numbers using a template" isDefault={true} />
            <RadioButton value={true} label="Use the version number from an included package" />
        </BooleanRadioButtonGroup>

        <Note>
            This setting controls the default version given to new releases.
            <br />
            The version can be generated from a template containing variable-expressions, or using the version from a package used by the deployment process.
            <br />
            See the <ExternalLink href="ReleaseVersioning">documentation</ExternalLink> for more information.
        </Note>

        {props.versionFromPackage ? (
            <ReleaseVersionFromPackageSelector deploymentProcess={props.deploymentProcess} setModelProp={props.setModelProp} versioningStrategyPackage={props.versioningStrategyPackage} versionPackageActions={props.versionPackageActions} />
        ) : (
            <ReleaseVersionTemplateInput setModelProp={props.setModelProp} versioningStrategyTemplate={props.versioningStrategyTemplate} />
        )}
    </>
);
