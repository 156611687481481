import { useMemo } from "react";
import packagingRegistry from "./packagingRegistry";

export const usePackagingInstructions = (id: string) => {
    return useMemo(() => {
        const registration = packagingRegistry.getRegistration(id);
        return registration ? registration.instructions : [];
    }, [id]);
};

export default usePackagingInstructions;
