import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import { ActionButton } from "~/components/Button/ActionButton";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import ExternalLink from "~/components/Navigation/ExternalLink";
import Select from "~/primitiveComponents/form/Select/Select";
import styles from "./ChangeVersionDialog.module.less";
import { getMajorVersionNumber } from "./StepPackageVersionBanner";

type ChangeVersionDialogProps = {
    open: boolean;
    stepPackageId: string;
    availableVersions?: string[];
    currentVersion?: string;
    onChangeClick: (selectedVersion: string) => void;
    onCancelClick: () => void;
    doBusyTask: DoBusyTask;
};

export function ChangeVersionDialog({ open, stepPackageId, availableVersions, currentVersion, onChangeClick, onCancelClick, doBusyTask }: ChangeVersionDialogProps) {
    const [selectedVersion, setSelectedVersion] = useState<string | undefined>(currentVersion);
    const [releaseNotesUrl, setReleaseNotesUrl] = useState<string | null>(null);

    React.useEffect(() => {
        setSelectedVersion(currentVersion);
    }, [currentVersion]);

    useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            if (!selectedVersion || !open) return;
            await getReleaseNotesUrl(stepPackageId, selectedVersion);
        },
        [selectedVersion, stepPackageId, open]
    );

    if (!availableVersions) return null;

    async function getReleaseNotesUrl(id: string, version: string) {
        const stepPackage = await repository.StepPackageRepository.getStepPackageByIdAndVersion(id, version);
        setReleaseNotesUrl(stepPackage.releaseNotesUrl || null);
    }

    return (
        <Dialog open={open} title="Choose Step Version">
            <div className={styles.content}>
                <p>Select the desired version, additional information may be required.</p>
                <Select
                    label="Version"
                    items={availableVersions.map((v) => ({ text: `Version ${getMajorVersionNumber(v)} ${v === currentVersion ? "(Current Version)" : ""}`, value: v }))}
                    value={selectedVersion}
                    onChange={(v) => {
                        if (!v || !selectedVersion) return;
                        setSelectedVersion(v);
                        doBusyTask(() => getReleaseNotesUrl(stepPackageId, selectedVersion));
                    }}
                ></Select>

                <div>{releaseNotesUrl && <ExternalLink href={releaseNotesUrl}>Release Notes</ExternalLink>}</div>

                <div className={styles.buttons}>
                    <ActionButton label="Cancel" onClick={onCancelClick} />
                    <ActionButton
                        label="Change"
                        type={0}
                        disabled={selectedVersion === currentVersion}
                        onClick={() => {
                            if (!selectedVersion) return;
                            onChangeClick(selectedVersion);
                        }}
                    />
                </div>
            </div>
        </Dialog>
    );
}
