import React from "react";
import styles from "./HighlightMatches.module.less";

interface HighlightMultipleMatchesProps {
    text: string;
    matches: number[];
}

const HighlightMultipleMatches: React.FC<HighlightMultipleMatchesProps> = ({ text = "", matches = [] }) => {
    if (matches.length === 0) {
        return <span aria-label={text}>{text}</span>;
    }

    const hOpen = `<mark class="${styles.highlight}">`;
    const hClose = "</mark>";
    let highlighted = "";
    let matchesIndex = 0;
    let opened = false;
    const target = text;
    const targetLen = target.length;
    const matchesBest = matches;
    for (let i = 0; i < targetLen; ++i) {
        const char = target[i];
        if (matchesBest[matchesIndex] === i) {
            ++matchesIndex;
            if (!opened) {
                opened = true;
                highlighted += hOpen;
            }
            if (matchesIndex === matchesBest.length) {
                highlighted += char + hClose + target.substr(i + 1);
                break;
            }
        } else {
            if (opened) {
                opened = false;
                highlighted += hClose;
            }
        }
        highlighted += char;
    }
    return <span dangerouslySetInnerHTML={{ __html: highlighted }} aria-label={text} />;
};

export default HighlightMultipleMatches;
