import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import type { CategoryDefinition } from "./endpointRegistry";

const category: CategoryDefinition = {
    category: "Linux",
    title: (
        <React.Fragment>
            What type of <strong>Linux target</strong> do you want to set up?
        </React.Fragment>
    ),
    help: (
        <Note>
            Learn more about <ExternalLink href="LinuxTentacle">Tentacle on Linux</ExternalLink> and <ExternalLink href="SSHTargets">SSH Targets</ExternalLink>
        </Note>
    ),
    displayOrder: 20,
};

export default category;
