import type { PackagingRegistration } from "../../Registry/packagingRegistry";
import BuildServerPackagingInstruction from "./BuildServerPackagingInstruction";
import CommandLinePackagingInstruction from "./CommandLinePackagingInstruction";
import ManualPackagingInstruction from "./ManualPackagingInstruction";

export const NodePackagingRegistration: PackagingRegistration = {
    displayOrder: 500,
    instructions: [BuildServerPackagingInstruction, ManualPackagingInstruction, CommandLinePackagingInstruction],
    name: "Node.js",
    id: "Node.js",
};

export default NodePackagingRegistration;
