import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";

export const ConfigurationPerformanceRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.performance,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
};
