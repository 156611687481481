import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import type { SortableItemModel } from "~/primitiveComponents/dataDisplay/SortableList/SortableList";
import SortableList from "~/primitiveComponents/dataDisplay/SortableList/SortableList";

interface StepSorterProps {
    title: string;
    initialItems: SortableItemModel[];
    stepId?: string;
    onComplete(ordered: SortableItemModel[]): void;
}

interface StepSorterState extends DataBaseComponentState {
    items: SortableItemModel[];
}

class StepSorter extends DataBaseComponent<StepSorterProps, StepSorterState> {
    constructor(props: StepSorterProps) {
        super(props);
        this.state = {
            items: [...this.props.initialItems],
        };
    }

    async save() {
        if (this.state.items) {
            this.props.onComplete(this.state.items);
        }

        return true;
    }

    render() {
        const steps = this.state.items;
        return (
            <SaveDialogLayout title={this.props.title} busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()} saveButtonLabel="Done">
                {steps && <SortableList onOrderChanged={this.stepsOrderChanged} items={this.state.items} />}
            </SaveDialogLayout>
        );
    }

    private stepsOrderChanged = (items: SortableItemModel[]) => {
        this.setState({ items });
    };
}

export default StepSorter;
