import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonWorkerPoolOverview, CommonWorkerPoolTopics } from "../../CommonComponents/CommonWorkerPoolViews";

export const InfrastructureWorkerPoolsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.workerPools.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonWorkerPoolOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonWorkerPoolTopics />;
    },
};
