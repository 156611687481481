import type { GitCredentialResource, GitCredentialUsage } from "~/client/resources";
import type { Client } from "../client";
import BasicRepository from "./basicRepository";

class GitCredentialRepository extends BasicRepository<GitCredentialResource, GitCredentialResource> {
    constructor(client: Client) {
        super("GitCredentials", client);
    }
    usage(gitCredential: GitCredentialResource) {
        return this.client.get<GitCredentialUsage>(gitCredential.Links.Usage);
    }
}

export default GitCredentialRepository;
