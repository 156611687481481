import * as React from "react";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import PaperLayout from "~/components/PaperLayout";
import routeLinks from "~/routeLinks";
import styles from "./UxGuidelinesOverview.module.less";
const useThinkFeel = require("./useThinkFeel.png");

const UxGuidelinesOverview: React.FC = () => {
    return (
        <PaperLayout title={"Overview"}>
            <div className={styles.centerContainer}>
                <img src={useThinkFeel} width={480} height={480} alt="UX" className={styles.roundedImage} />
                <div className={styles.stackedContainer}>
                    <p>Bringing the worlds of engineering, design and user experience together.</p>
                    <div className={styles.verticalAction}>
                        <NavigationButton type={NavigationButtonType.Primary} href={routeLinks.uxGuide.guidelines.cheatsheet} label="Use this UX Cheat Sheet to your advantage" />
                    </div>
                    <div className={styles.verticalAction}>
                        <NavigationButton type={NavigationButtonType.Ternary} href={routeLinks.uxGuide.guidelines.guidelines} label="Read some helpful guidelines" />
                    </div>
                </div>
            </div>
        </PaperLayout>
    );
};

export default UxGuidelinesOverview;
