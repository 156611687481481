import * as React from "react";
import ActionButton, { ActionButtonType, IconPosition } from "~/components/Button/ActionButton";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { SimpleMenu } from "~/primitiveComponents/navigation/Menu/SimpleMenu";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";

interface AddTriggerButtonProps {
    internalPath: string;
}

export function AddTriggerButton({ internalPath }: AddTriggerButtonProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    return (
        <>
            <ActionButton type={ActionButtonType.Primary} icon={<DropDownIcon />} iconPosition={IconPosition.Right} label="Add Trigger" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes} />
            <SimpleMenu
                compact={true}
                menuState={menuState}
                items={[
                    { type: "internal-link", label: "Deployment target trigger", path: `${internalPath}/create` },
                    { type: "internal-link", label: "Scheduled trigger", path: `${internalPath}/scheduled/create` },
                ]}
                accessibleName={"Add Trigger"}
            />
        </>
    );
}
