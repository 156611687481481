/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { DialogLayoutConnect } from "~/components/Dialog/DialogLayoutConnect";
import type { DialogLayoutDispatchProps } from "~/components/DialogLayout/DialogLayout";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import { publishingExplainedElement } from "../PublishButton";

interface PublishSnapshotDialogProps {
    onPublishSnapshotDialogClicked(): any;
}

interface PublishSnapshotDialogState extends DataBaseComponentState {
    isBusy: boolean;
}

class PublishSnapshotDialogInternal extends DataBaseComponent<PublishSnapshotDialogProps & DialogLayoutDispatchProps, PublishSnapshotDialogState> {
    constructor(props: PublishSnapshotDialogProps & DialogLayoutDispatchProps) {
        super(props);
        this.state = {
            isBusy: false,
        };
    }

    render() {
        const actions = [];
        actions.push(<ActionButton label="Cancel" key="CancelBlock" onClick={() => this.props.close()} />);
        actions.push(
            <ActionButton
                label="Publish"
                key="PublishSnapshotDialog"
                type={ActionButtonType.Primary}
                disabled={this.state.isBusy}
                onClick={() =>
                    this.doBusyTask(async () => {
                        this.setState({ isBusy: true });
                        await this.props.onPublishSnapshotDialogClicked();
                        this.setState({ isBusy: false });
                        this.props.close();
                    })
                }
            />
        );
        return (
            <DialogLayout title="Confirm Publish" busy={this.state.busy} errors={this.errors} key="PublishSnapshotDialog" closeDialog={this.props.close} actions={actions}>
                <p>{publishingExplainedElement}</p>
                <p>This snapshot will be set as the published version for this runbook. Are you sure?</p>
            </DialogLayout>
        );
    }
}

const PublishSnapshotDialog = DialogLayoutConnect.to<PublishSnapshotDialogProps>(PublishSnapshotDialogInternal);
export default PublishSnapshotDialog;
