import * as React from "react";
import { Permission } from "~/client/resources";
import { PermissionCheck } from "~/components/PermissionCheck";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import type { ScriptModule } from "../ScriptModules";
import { ScriptModulesWithActions } from "../ScriptModules";

interface RunbookProcessOverviewSidebarProps {
    projectId: string;
    includedScriptModules: ScriptModule[];
    onDataChanged: () => void;
}

export const RunbookProcessOverviewSidebar: React.FC<RunbookProcessOverviewSidebarProps> = (props) => {
    return (
        <PermissionCheck
            permission={Permission.LibraryVariableSetView}
            wildcard={true}
            alternate={
                <Callout type={CalloutType.Information} title={"Permission required"}>
                    The {Permission.LibraryVariableSetView} permission is required to view any included script modules
                </Callout>
            }
        >
            {props.includedScriptModules && (
                <div>
                    <ScriptModulesWithActions projectId={props.projectId} includedScriptModules={props.includedScriptModules} onDataChanged={props.onDataChanged} />
                </div>
            )}
        </PermissionCheck>
    );
};
