import cn from "classnames";
import * as React from "react";
import ActionButton, { ActionButtonType } from "~/components/Button/ActionButton";
import PageDivider from "~/components/PageDivider";
import { Section } from "~/components/Section/Section";
import { useOctopusTheme } from "~/components/Theme";
import { QueryStateMode, useQueryStringParam } from "~/hooks/useQueryStringParam";
import { orderBy } from "~/utils/orderBy";
import styles from "./EndpointSelector.module.less";
import type { EndpointSelectionScope, CategorizedEndpointRegistration, CategorizedEndpointResult, CategoryDefinition, EndpointRegistration } from "./endpointRegistry";

type EndpointSelectorProps = {
    onSelect: (registration: EndpointRegistration, category: CategoryDefinition) => void;
    scope: EndpointSelectionScope;
    registrations: EndpointRegistration[];
    categories: CategoriesLookup;
    heading: React.ReactNode;
};

export const EndpointSelector: React.FC<EndpointSelectorProps> = ({ registrations, categories, heading, scope, ...rest }) => {
    const [selectedCategory, setSelectedCategory] = useSelectedCategoryFromQueryString(categories);
    const categoryButtons: React.ReactNode[] = orderBy(
        Object.values(categories).map((x) => ({ label: x.category.category, displayOrder: x.category.displayOrder, action: () => setSelectedCategory(x.category) })),
        ["displayOrder", "label"]
    ).map((x) => <ActiveItemButton label={x.label} onClick={x.action} key={x.label} active={selectedCategory && selectedCategory.category === x.label} />);

    return (
        <React.Fragment>
            <Section className={styles.headingContainer}>{heading}</Section>
            <CategoryButtons className={styles.paperActions}>{categoryButtons}</CategoryButtons>
            <Section bodyClassName={styles.container}>
                {selectedCategory && <EndpointCardGroupTitle className={styles.activeItemGroupHeading}>{selectedCategory.title}</EndpointCardGroupTitle>}
                {selectedCategory && !!selectedCategory.help && <EndpointCardGroupHelp>{selectedCategory.help}</EndpointCardGroupHelp>}
                {selectedCategory && (
                    <EndpointCardGroup
                        scope={scope}
                        endpoints={categories[selectedCategory.category] && categories[selectedCategory.category].endpoints}
                        onSelect={rest.onSelect}
                        category={categories[selectedCategory.category] && categories[selectedCategory.category].category}
                    />
                )}
            </Section>
        </React.Fragment>
    );
};

interface EndpointCardGroupProps {
    endpoints: CategorizedEndpointRegistration[];
    className?: string;
    category: CategoryDefinition;
    scope: EndpointSelectionScope;
    onSelect: (registration: CategorizedEndpointRegistration, category: CategoryDefinition) => void;
}

const EndpointCardGroup: React.FC<EndpointCardGroupProps> = ({ endpoints = [], className, onSelect, category, scope }) => {
    const ordered = orderBy(endpoints, ["displayOrder", "name"]);

    return (
        <div className={cn(styles.cardGroup, className)}>
            {ordered.map((x) => {
                const element = x.renderCard({
                    scope,
                    registration: x,
                    category,
                    onNavigate: () => onSelect(x, category),
                });
                return React.cloneElement(element, { key: x.name });
            })}
        </div>
    );
};

export const EndpointCardGroupTitle: React.FC<{ className?: string }> = ({ className, children }) => (
    <div className={cn(styles.groupHeading, className)}>
        <PageDivider>{children}</PageDivider>
    </div>
);

export const EndpointCardGroupHelp: React.FC<{ className?: string }> = ({ className, children }) => <div className={cn(styles.groupHelp, className)}>{children}</div>;

type ActiveItemButtonProps = { active?: boolean; label: string; onClick?: () => void; style?: object; icon?: {} };

const ActiveItemButton: React.FC<ActiveItemButtonProps> = ({ onClick, label, active, style }) => {
    const theme = useOctopusTheme();

    const labelProps = active ? { color: theme.whiteConstant } : undefined;
    return <ActionButton type={ActionButtonType.Category} labelProps={labelProps} label={label} onClick={onClick} className={cn({ [styles.activeCategoryButton]: active })} style={style} />;
};

const CategoryButtons: React.FC<{ className?: string }> = ({ children, className }) => <div className={cn(styles.actions, className)}>{children}</div>;

export type CategoriesLookup = Record<string, CategorizedEndpointResult>;

function useSelectedCategoryFromQueryString(categories: CategoriesLookup): [CategoryDefinition | undefined, (category: CategoryDefinition) => void] {
    const [value, setValue] = useQueryStringParam("category", QueryStateMode.ReplaceHistory);
    const setCategoryDefinition = React.useCallback((category: CategoryDefinition) => setValue(category.category), [setValue]);
    const categoryFromUrl = categories[value ?? ""];
    return [categoryFromUrl?.category, setCategoryDefinition];
}
