import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/Page";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { renderWithLayout } from "~/components/RenderWithLayout/RenderWithLayout";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import TenantLayout from "../TenantLayout/TenantLayout";
import TenantOverview from "../TenantOverview/TenantOverview";
import TenantSettings from "../TenantSettings/TenantSettings";
import TenantTasks from "../TenantTasks/TenantTasks";
import TenantVariables from "../TenantVariables/TenantVariables";
import { Tenants } from "../Tenants";

const overview = renderWithLayout(TenantLayout)(TenantOverview);
const variables = renderWithLayout(TenantLayout)(TenantVariables);
const tasks = renderWithLayout(TenantLayout)(TenantTasks);
const settings = renderWithLayout(TenantLayout)(TenantSettings);

const TenantOverviewPage = withPage({ page: pageIds.tenant.overview })(overview);
const TenantVariablesPage = withPage({ page: pageIds.tenant.variables })(variables);
const TenantSettingsPage = withPage({ page: pageIds.tenant.settings })(settings);
const TenantTasksPage = withPage({ page: pageIds.tenant.tasks })(tasks);
const TenantsListPage = withPage({ page: pageIds.tenants })(Tenants);

export class TenantRoutes extends React.Component {
    render() {
        const tenantLink = routeLinks.tenant(`:tenantId`);
        return (
            <Switch>
                <ReloadableRoute path={routeLinks.v3tenantRoutes.newTenant}>
                    <InternalRedirect to={routeLinks.tenants} />
                </ReloadableRoute>
                <ReloadableRoute path={tenantLink.root}>
                    <Switch>
                        <ReloadableRoute exact path={tenantLink.overview} component={TenantOverviewPage} />
                        <ReloadableRoute exact path={tenantLink.variables().pathname} component={TenantVariablesPage} />
                        <ReloadableRoute exact path={tenantLink.tasks} component={TenantTasksPage} />
                        <ReloadableRoute exact path={tenantLink.settings} component={TenantSettingsPage} />
                        <ReloadableRoute exact path={tenantLink.root} render={(props: RouteComponentProps<{ tenantId: string }>) => <InternalRedirect to={routeLinks.tenant(props.match.params.tenantId).overview} />} />
                        <RedirectAs404 />
                    </Switch>
                </ReloadableRoute>
                <ReloadableRoute path={routeLinks.tenants} component={TenantsListPage} />
                <RedirectAs404 />
            </Switch>
        );
    }
}

export default TenantRoutes;
