import { compact } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import type { Action, Dispatch } from "redux";
import { MachineHealthStatusIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/MachineHealthStatusIcon";
import type { CategorizedEndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import type { MachineModelHealthStatus, MachineResource } from "~/client/resources";
import { isStepPackageEndpointResource } from "~/client/resources";
import { Permission } from "~/client/resources/permission";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import BaseComponent from "~/components/BaseComponent";
import { DisabledChip } from "~/components/Chips";
import type { NavItem } from "~/components/NavigationSidebarLayout";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import type { UnknownStepPackageDeploymentTarget } from "~/components/StepPackageDeploymentTargetEditor/StepPackageDeploymentTarget";
import routeLinks from "~/routeLinks";
import EndpointsHelper from "~/utils/EndpointsHelper/EndpointsHelper";
import { getMachineIconUrl } from "~/utils/MachineIconUrlFetchers/machineIconUrlFetchers";
import { machineActions } from "../../reducers/machines";
import { getCurrentMachine } from "../../reducers/selectors";
import { MachineIcon } from "../MachineIcon/MachineIcon";

const { machineFetched, machineCleared } = machineActions;

type MachineLayoutRouteProps = RouteComponentProps<{ machineId: string }>;

interface MachineLayoutDispatchProps {
    onFetchMachine(machine: MachineResource): void;
    onClearMachine(): void;
}

interface MachineCommonProps {
    machineId?: string;
    machineName?: string;
    machineType?: string;
    machineIconUrl?: string;
    machineIsDisabled?: boolean;
    isStepPackage?: boolean;
    machineHealthStatus?: MachineModelHealthStatus;
}

interface MachineLayoutStateProps extends MachineCommonProps {
    title: string;
    rootLink: string;
    extraNavLinks: NavItem[];
    getMachine(id: string): Promise<MachineResource>;
    getMachineLinks(id: string): MachineLinks;
}

type MachineLayoutConnectedProps = MachineLayoutDispatchProps & MachineLayoutStateProps;
type MachineLayoutProps = MachineLayoutConnectedProps & MachineLayoutRouteProps;

interface MachineLinks {
    settings: string;
    connection: string;
    events: string;
}

interface MachineLayoutInternalState {
    registration: CategorizedEndpointRegistration | undefined;
    stepPackageDeploymentTarget: UnknownStepPackageDeploymentTarget | undefined;
}

class MachineLayoutInternal extends BaseComponent<MachineLayoutProps, MachineLayoutInternalState> {
    private machineId: string | null;

    constructor(props: MachineLayoutProps) {
        super(props);
        this.machineId = this.props.match && this.props.match.params ? this.props.match.params.machineId : null;
        this.state = {
            registration: undefined,
            stepPackageDeploymentTarget: undefined,
        };
    }

    async componentDidMount() {
        if (this.machineId) {
            const machine = await this.props.getMachine(this.machineId);
            if (isStepPackageEndpointResource(machine.Endpoint)) {
                const deploymentTarget = await endpointRegistry.getStepPackageDeploymentTarget(machine.Endpoint.DeploymentTargetTypeId, machine.Endpoint.StepPackageVersion);
                this.setState((prevState) => ({ ...prevState, stepPackageDeploymentTarget: deploymentTarget }));
            }
            this.props.onFetchMachine(machine);
        }
    }

    render() {
        const machineId = this.machineId;
        const navLinks: Array<NavItem | null> = [];
        if (machineId) {
            const machineLinks = this.props.getMachineLinks(machineId);

            navLinks.push(Navigation.navItem("Settings", machineLinks.settings));
            navLinks.push(Navigation.navItem(<React.Fragment>Connectivity {this.props.machineHealthStatus && <MachineHealthStatusIcon healthStatus={this.props.machineHealthStatus} />}</React.Fragment>, machineLinks.connection));
            if (this.props.extraNavLinks) {
                navLinks.push.apply(navLinks, this.props.extraNavLinks);
            }
            navLinks.push(Navigation.navItem("Events", machineLinks.events, undefined, { permission: Permission.EventView, wildcard: true }));
        }

        const machineImage = this.props.machineIconUrl ? <MachineIcon imageUrl={this.props.machineIconUrl} /> : null;

        let machineType = this.props.machineType;
        if (this.props.isStepPackage) {
            machineType = this.state.stepPackageDeploymentTarget?.name ?? "";
        }

        return (
            <main id="maincontent">
                <AreaTitle link={this.props.rootLink} title={this.props.title} breadcrumbTitle={"Infrastructure"} breadcrumbPath={routeLinks.infrastructure.root} />
                <NavigationSidebarLayout
                    name={this.props.machineName}
                    resourceType={machineType}
                    image={machineImage}
                    navLinks={compact(navLinks)}
                    preNavbarComponent={
                        this.props.machineIsDisabled && (
                            <div>
                                <DisabledChip />
                            </div>
                        )
                    }
                    content={this.props.children}
                />
            </main>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState) => {
    const machine = getCurrentMachine(state);
    return !machine
        ? {}
        : {
              machineId: machine.id,
              machineName: machine.name,
              machineType: machine.machineType,
              machineIconUrl: machine.machineIconUrl,
              machineIsDisabled: machine.isDisabled,
          };
};

const mapGlobalStateToPropsForDeploymentTargets = (state: GlobalState): MachineLayoutStateProps => {
    const machine = getCurrentMachine(state);
    const common = mapGlobalStateToProps(state);
    const navLinks = machine
        ? compact([
              Navigation.navItem("Deployments", routeLinks.infrastructure.deploymentTarget(machine.id).deployments, undefined, { permission: Permission.TaskView, wildcard: true }),
              Navigation.navItem("Runbook Runs", routeLinks.infrastructure.deploymentTarget(machine.id).runbookRuns, undefined, { permission: Permission.TaskView, wildcard: true }),
          ])
        : [];

    return {
        ...common,
        title: "Deployment Targets",
        rootLink: routeLinks.infrastructure.machines.root,
        extraNavLinks: navLinks,
        getMachine: (id: string) => repository.Machines.get(id),
        getMachineLinks: (id: string) => routeLinks.infrastructure.machine(id),
        isStepPackage: machine?.isStepPackage,
        machineHealthStatus: machine?.healthStatus,
    };
};

const mapGlobalStateToPropsForWorkers = (state: GlobalState): MachineLayoutStateProps => {
    const machine = getCurrentMachine(state);

    const common = mapGlobalStateToProps(state);
    return {
        ...common,
        title: "Workers",
        extraNavLinks: [],
        rootLink: routeLinks.infrastructure.workerMachines.root,
        getMachine: (id: string) => repository.Workers.get(id),
        getMachineLinks: (id: string) => routeLinks.infrastructure.workerMachine(id),
        machineHealthStatus: machine?.healthStatus,
    };
};

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch<Action>) => {
    return {
        onFetchMachine: (machine: MachineResource) => {
            const machineIconUrl = getMachineIconUrl(machine);

            dispatch(
                machineFetched({
                    id: machine.Id,
                    name: machine.Name,
                    machineIconUrl,
                    machineType: EndpointsHelper.getFriendlyName(machine.Endpoint.CommunicationStyle),
                    isDisabled: machine.IsDisabled,
                    isStepPackage: isStepPackageEndpointResource(machine.Endpoint),
                    healthStatus: machine.HealthStatus,
                })
            );
        },
        onClearMachine: () => {
            dispatch(machineCleared());
        },
    };
};

export const WorkerMachineLayout = withRouter(
    connect<MachineLayoutStateProps, MachineLayoutDispatchProps, MachineLayoutRouteProps & MachineCommonProps, GlobalState>(mapGlobalStateToPropsForWorkers, mapGlobalActionDispatchersToProps)(MachineLayoutInternal)
);

export const MachineLayout = withRouter(
    connect<MachineLayoutStateProps, MachineLayoutDispatchProps, MachineLayoutRouteProps & MachineCommonProps, GlobalState>(mapGlobalStateToPropsForDeploymentTargets, mapGlobalActionDispatchersToProps)(MachineLayoutInternal)
);
