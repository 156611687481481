/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { RoleChip } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";

interface RoleMultiSelectProps extends FormFieldProps<string[]> {
    items: string[];
    canAdd?: boolean;
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    accessibleName?: string;
    validate?(value: string[]): string;
}

const RoleTypedMultiSelect = MultiSelect<SelectItem>();

export const RoleMultiSelect: React.FC<RoleMultiSelectProps> = (props) => {
    const onAdd = (value: string) => {
        props.onChange!(props.value!.concat(value));
    };

    const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => {
        return <RoleChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${r.Name}`} role={r.Name} />;
    };

    return (
        <RoleTypedMultiSelect
            items={props.items.map((i) => ({ Id: i, Name: i }))}
            fieldName="target roles"
            label={props.label}
            renderChip={chipRenderer}
            onNew={props.canAdd ? onAdd : null!}
            value={props.value}
            onChange={props.onChange}
            error={props.error}
            openOnFocus={props.openOnFocus}
            autoFocus={props.autoFocus}
            hideFloatingLabel={props.hideFloatingLabel}
            addNewTemplate={(text) => `"${text}" (add new role)`}
            accessibleName={props.accessibleName}
        />
    );
};
