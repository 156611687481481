/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import type { ReactNode } from "react";
import { orderedHealthStatuses } from "~/areas/infrastructure/InfrastructureDetails";
import { DisabledMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/DisabledMachineIcon";
import { MachineHealthStatusIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/MachineHealthStatusIcon";
import { DisabledMachineHealthSummaryLink, MachineHealthSummaryLink } from "~/areas/infrastructure/components/MachineHealthSummaryLink/MachineHealthSummaryLink";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { MachineModelHealthStatus } from "~/client/resources";
import type { MachineResource, TaskRestrictedTo, SummaryResource, WorkerPoolsSummaryResource, EnvironmentsSummaryResource, ResourceCollection } from "~/client/resources";
import type { PagingCollection } from "~/client/resources/pagingCollection";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import IconButton from "~/components/IconButton";
import { Icon } from "~/components/IconButton/IconButton";
import List from "~/components/List";
import { NoResults } from "~/components/NoResults/NoResults";
import { Section } from "~/components/Section/Section";
import { FormSectionHeading } from "~/components/form";
import MachineHealthStatusHelper from "~/utils/MachineHealthStatusHelper";
import routeLinks from "../../../../routeLinks";
import type { BaseInfrastructureFilter } from "../MachinesLayout/MachineFilter";
import { toPagingCollection, createMachineHealthMap } from "../MachinesLayout/MachineFilter";
import styles from "./style.module.less";

interface SmallCloseButtonProps {
    onClose?: (event: React.MouseEvent) => void;
}
export const SmallCloseButton: React.SFC<SmallCloseButtonProps> = (props) => {
    return <IconButton onClick={props.onClose} icon={Icon.CancelSmall} style={{ width: "12px", height: "12px" }} />;
};

class MachinesList extends List<MachineResource> {}

export interface BaseAllMachinesSummaryProps<Filter> {
    filter: Filter;
}

export type HealthStatusRecord<TResourceType> = Record<keyof typeof MachineModelHealthStatus | "Disabled", PagingCollection<TResourceType>>;

export interface BaseAllMachinesSummaryState<TResourceType extends MachineResource = MachineResource> extends DataBaseComponentState {
    machinesResponse: PagingCollection<TResourceType>;
    machineHealthStatusFastLookup: HealthStatusRecord<TResourceType>;
    currentPageIndex: number; // This has a custom endpoint, so we manage our own paging implementation in List/onLoadMore.
    expanded: boolean; // Need to know if we're currently expanded so we can choose to reload when the filter changes or not.
    healthStatusFilter: string;
    isDisabledFilter: boolean;
    redirectToTaskId?: string;
    endpointRegistrations: EndpointRegistration[];
}

abstract class BaseAllMachinesSummary<
    TResourceType extends MachineResource,
    Props extends BaseAllMachinesSummaryProps<Filter>,
    Filter extends BaseInfrastructureFilter,
    State extends BaseAllMachinesSummaryState<TResourceType>
> extends DataBaseComponent<Props, State> {
    protected machineListTakeSize = repository.takeDefaultPageSize;
    protected commonInitialState = {
        currentPageIndex: 0,
        expanded: true,
        healthStatusFilter: "",
        isDisabledFilter: false,
    };

    constructor(props: Props) {
        super(props);
    }

    protected abstract loadData(): Promise<void>;
    protected abstract renderMachine(machine: TResourceType, needsUpgrading?: boolean): ReactNode;

    protected reloadDataAndCurrentPageIndex() {
        this.setState({ currentPageIndex: 0 }, async () => {
            await this.doBusyTask(async () => {
                await this.loadData();
            });
        });
    }

    protected async performHealthCheck(taskRestrictedTo: TaskRestrictedTo, machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createHealthCheckTaskRestrictedTo(taskRestrictedTo, machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected async performTentacleUpgrade(taskRestrictedTo: TaskRestrictedTo, machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpgradeTentaclesTaskRestrictedTo(taskRestrictedTo, machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected async performCalamariUpgradeOnTargets(machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnTargetsTask(machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected async performCalamariUpgradeOnWorkers(machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnWorkersTask(machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected renderMachinesList(summary: EnvironmentsSummaryResource | WorkerPoolsSummaryResource) {
        const componentKey = "allMachines";
        return <div key={componentKey}>{this.state.expanded && this.state.machinesResponse && <div>{this.renderMachinesListGroupedByHealthStatus(summary)}</div>}</div>;
    }

    protected renderMachinesListGroupedByHealthStatus(summary: EnvironmentsSummaryResource | WorkerPoolsSummaryResource) {
        const allMachines = this.state.machinesResponse.Items;
        if (allMachines.length === 0) {
            return (
                <Section>
                    <NoResults />
                </Section>
            );
        }

        let machinesNeedUpgrading: string[] = [];
        if (summary.MachineIdsForCalamariUpgrade) {
            machinesNeedUpgrading = machinesNeedUpgrading.concat(summary.MachineIdsForCalamariUpgrade);
        }

        if (summary.MachineIdsForTentacleUpgrade) {
            machinesNeedUpgrading = machinesNeedUpgrading.concat(summary.MachineIdsForTentacleUpgrade);
        }

        return (
            <div>
                {orderedHealthStatuses.map((status) => this.renderHealthStatusSectionHeading(status, allMachines, machinesNeedUpgrading))}
                {this.renderDisabledSectionHeading(allMachines)}
            </div>
        );
    }

    protected renderHealthStatusSectionHeading(status: MachineModelHealthStatus, allMachines: TResourceType[], machineIdsTobeUpgraded: string[]): React.ReactElement | null {
        let machines = allMachines.filter((x) => x.HealthStatus === status);
        if (status === MachineModelHealthStatus.Unknown) {
            machines = machines.filter((x) => !x.IsDisabled);
        }

        let machinesTitle = <React.Fragment />;

        machinesTitle = (
            <div className={styles.healthStatusCardTitleContainer} key={status}>
                <div className={styles.healthStatusIconContainer}>
                    <MachineHealthStatusIcon healthStatus={status} title={"Health status"} />
                </div>
                <div className={styles.healthStatusName}>{MachineHealthStatusHelper.getFriendlyName(status)}</div>
                <div className={styles.healthStatusMachinesCount}>({machines.length})</div>
            </div>
        );

        const componentKey = status;
        const machinesList =
            machines.length > 0 ? (
                <div key={status}>
                    <FormSectionHeading title={machinesTitle} />
                    <MachinesList
                        key={componentKey}
                        initialData={this.state.machineHealthStatusFastLookup[componentKey]}
                        onRow={(item: TResourceType) => this.renderMachine(item, machineIdsTobeUpgraded.includes(item.Id))}
                        onRowRedirectUrl={(machine: TResourceType) => routeLinks.infrastructure.machine(machine).root}
                        onLoadMore={async () => {
                            const newTakeSize = this.getBaseState((state) => state.machineHealthStatusFastLookup[componentKey].ItemsPerPage + this.machineListTakeSize);
                            const machineHealthStatusFastLookup = this.getBaseState((state) => state.machineHealthStatusFastLookup);
                            const response = this.getBaseState((state) => state.machinesResponse);
                            const filteredMachines = response.Items.filter((x) => x.HealthStatus === status);
                            const machinesForHealthStatus = toPagingCollection(filteredMachines, newTakeSize);
                            machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                            this.setState({
                                machineHealthStatusFastLookup,
                            });
                        }}
                    />
                </div>
            ) : null;

        return machinesList;
    }

    protected renderDisabledSectionHeading(allMachines: TResourceType[]): React.ReactElement | null {
        const machines = allMachines.filter((x) => x.IsDisabled);
        let machinesTitle = <React.Fragment />;

        machinesTitle = (
            <div className={styles.healthStatusCardTitleContainer} key={status}>
                <div className={styles.healthStatusIconContainer}>
                    <DisabledMachineIcon title={"Health status"} />
                </div>
                <div className={styles.healthStatusName}>Disabled</div>
                <div className={styles.healthStatusMachinesCount}>({machines.length})</div>
            </div>
        );

        const componentKey = "Disabled";
        const machinesList =
            machines.length > 0 ? (
                <div>
                    <FormSectionHeading title={machinesTitle} />
                    <List<TResourceType>
                        key={componentKey}
                        initialData={this.state.machineHealthStatusFastLookup[componentKey]}
                        onRow={(item) => this.renderMachine(item)}
                        onRowRedirectUrl={(machine: MachineResource) => routeLinks.infrastructure.machine(machine).root}
                        onLoadMore={async () => {
                            const newTakeSize = this.getBaseState((state) => state.machineHealthStatusFastLookup[componentKey].ItemsPerPage + this.machineListTakeSize);
                            const machineHealthStatusFastLookup = this.getBaseState((state) => state.machineHealthStatusFastLookup);
                            const response = this.getBaseState((state) => state.machinesResponse);
                            const filteredMachines = response.Items.filter((x) => x.IsDisabled);
                            const machinesForHealthStatus = toPagingCollection(filteredMachines, newTakeSize);
                            machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                            this.setState({
                                machineHealthStatusFastLookup,
                            });
                        }}
                    />
                </div>
            ) : null;

        return machinesList;
    }

    handleHealthSummaryLinkSelect = (e: React.MouseEvent, healthStatus: MachineModelHealthStatus) => {
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        // Clear any disabled filters when a healthStatus filter is clicked. You can't chain inline disabled and healthStatus
        // filters together because they use different and/or logic at the API and it causes UI confusion.
        this.setState(
            {
                healthStatusFilter: healthStatus,
                isDisabledFilter: false,
            },
            () => {
                if (this.state.expanded) {
                    this.reloadDataAndCurrentPageIndex();
                }
            }
        );
    };

    handleClearHealthSummaryFilter = () => {
        this.setState({ healthStatusFilter: "" }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };

    protected renderMachineSummaryLinks(summary: SummaryResource, healthStatus: MachineModelHealthStatus) {
        return (
            <MachineHealthSummaryLink
                key={healthStatus}
                healthStatus={healthStatus}
                onSelect={this.handleHealthSummaryLinkSelect}
                onClearSelect={this.handleClearHealthSummaryFilter}
                getLinkText={(status, count, friendlyName) => `${count.toLocaleString()}  ${friendlyName}`}
                count={summary.MachineHealthStatusSummaries[healthStatus]}
                allowSelection={!(this.props.filter.healthStatuses && this.props.filter.healthStatuses.length > 0)}
                isSelected={this.state.healthStatusFilter === healthStatus}
            />
        );
    }

    protected handleDisabledSummaryLinkSelect = (e: React.MouseEvent) => {
        // The user may click a disabled link to open an expander (but it shouldn't ever close it).
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        // Clear any healthStatus filters when disabled is clicked. You can't chain inline disabled and healthStatus
        // filters together because they use different and/or logic at the API and it causes UI confusion.
        this.setState(
            {
                isDisabledFilter: true,
                healthStatusFilter: "",
            },
            () => {
                if (this.state.expanded) {
                    this.reloadDataAndCurrentPageIndex();
                }
            }
        );
    };

    protected handleClearDisabledSummaryFilter = () => {
        this.setState({ isDisabledFilter: false }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };

    protected renderMachineDisabledSummaryLinks(summary: SummaryResource) {
        return (
            <DisabledMachineHealthSummaryLink
                key="Disabled"
                onSelect={this.handleDisabledSummaryLinkSelect}
                onClearSelect={this.handleClearDisabledSummaryFilter}
                count={summary.TotalDisabledMachines}
                allowSelection={!this.props.filter.isDisabled}
                isSelected={this.state.isDisabledFilter}
            />
        );
    }

    protected setMachineResponseState(machinesResponse: ResourceCollection<TResourceType>) {
        const machineHealthStatusFastLookup = this.assembleHealthStatusFastLookup(machinesResponse);
        this.setState({ machinesResponse, machineHealthStatusFastLookup });
    }

    protected assembleHealthStatusFastLookup(machinesResponse: ResourceCollection<TResourceType>) {
        return createMachineHealthMap(machinesResponse, this.machineListTakeSize, this.state.machineHealthStatusFastLookup);
    }

    private getBaseState<T>(accessor: (state: Readonly<BaseAllMachinesSummaryState<TResourceType>>) => T) {
        return accessor(this.state as BaseAllMachinesSummaryState<TResourceType>);
    }
}

export default BaseAllMachinesSummary;
