import DividerMaterialUI from "material-ui/Divider";
import * as React from "react";
import { withTheme } from "~/components/Theme";

interface DividerProps {
    fullHeight?: boolean;
}

export const Divider: React.FC<DividerProps> = ({ fullHeight = true }) =>
    withTheme((theme) => {
        const margin = fullHeight ? { marginTop: 0 } : {};
        return <DividerMaterialUI style={{ backgroundColor: theme.divider, ...margin }} />;
    });

export default Divider;
