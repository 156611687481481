/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import type { FeedResource, S3FeedResource } from "~/client/resources/feedResource";
import { FeedType } from "~/client/resources/feedResource";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { Summary } from "~/components/form";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Note from "~/primitiveComponents/form/Note/Note";
import RadioButtonGroup from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import RadioButton from "~/primitiveComponents/form/RadioButton/index";
import Text from "~/primitiveComponents/form/Text/Text";
import { default as Sensitive, ObfuscatedPlaceholder } from "../../../../../components/form/Sensitive/Sensitive";
import type { FeedEditProps } from "./feedTypeRegistry";
import feedTypeRegistry from "./feedTypeRegistry";

class S3Feed extends React.Component<FeedEditProps<S3FeedResource>, never> {
    render() {
        if (this.props.feed.UseMachineCredentials === undefined) {
            this.props.feed.UseMachineCredentials = false;
        }

        return (
            <React.Fragment>
                <ExpandableFormSection
                    summary={Summary.summary(this.props.feed.UseMachineCredentials ? "Use credentials configured on the worker" : "Use explicitly specified credentials")}
                    title={"Credentials"}
                    errorKey={"UseMachineCredentials"}
                    help="Use explicitly specified credentials or the credentials configured on the worker."
                >
                    <RadioButtonGroup
                        value={this.props.feed.UseMachineCredentials}
                        onChange={(x) => {
                            if (x === true) {
                                this.props.feed.SecretKey = {
                                    HasValue: false,
                                };
                                this.props.feed.AccessKey = "";
                            }
                            this.props.feed.UseMachineCredentials = x;
                            this.props.onChange(this.props.feed);
                        }}
                        label="Execute using the credentials configured on the worker"
                    >
                        <RadioButton value={true} label="Yes" />
                        <RadioButton value={false} label="No" />
                    </RadioButtonGroup>
                </ExpandableFormSection>
                {this.props.feed.UseMachineCredentials === false && (
                    <>
                        <ExpandableFormSection key="accessKey" errorKey="accessKey" title="Access Key" summary={this.accessKeySummary()} help="The AWS access key to use when authenticating against Amazon Web Services.">
                            <Note>
                                Refer to the <ExternalLink href="AwsDocsManagingAccessKeys">AWS documentation</ExternalLink> for information on generating access and secret keys.
                            </Note>
                            <Text value={this.props.feed.AccessKey} onChange={(AccessKey) => this.props.onChange({ ...this.props.feed, AccessKey })} label="Access Key" error={this.props.getFieldError("AccessKey")} />
                        </ExpandableFormSection>
                        <ExpandableFormSection key="secretKey" errorKey="secretKey" title="Secret Key" summary={this.secretKeySummary()} help="The AWS secret key to use when authenticating against Amazon Web Services.">
                            <Sensitive value={this.props.feed.SecretKey} onChange={(SecretKey) => this.props.onChange({ ...this.props.feed, SecretKey })} label="Secret Key" error={this.props.getFieldError("SecretKey")} />
                        </ExpandableFormSection>
                    </>
                )}
            </React.Fragment>
        );
    }

    private accessKeySummary = () => {
        return this.props.feed.AccessKey ? Summary.summary(this.props.feed.AccessKey) : Summary.placeholder("No access key provided");
    };

    private secretKeySummary = () => {
        return this.props.feed.SecretKey && this.props.feed.SecretKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No secret key provided");
    };
}

feedTypeRegistry.registerFeedType({
    type: FeedType.S3,
    edit: S3Feed as React.ComponentType<FeedEditProps<FeedResource>>,
    text: "AWS S3 Bucket Feed",
    hasUri: false,
});
