import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectReleaseOverview, CommonProjectReleaseTopics } from "../../CommonComponents/CommonProjectReleaseViews";

export const ProjectReleaseVersionEditRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().release().edit,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectReleaseOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectReleaseTopics />;
    },
};
