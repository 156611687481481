import type { ResourceWithId } from "~/client/resources";

export enum DefectStatus {
    Unresolved = "Unresolved",
    Resolved = "Resolved",
}

export interface DefectResource extends ResourceWithId {
    Description: string;
    Status?: DefectStatus;
}
