import type { PackagingInstruction } from "../Registry/packagingRegistry";

type RenderPackagingInstruction = PackagingInstruction["render"];

export function manualPackagingInstruction(render: RenderPackagingInstruction): PackagingInstruction {
    return {
        displayOrder: 1,
        name: "Manually",
        render,
    };
}

export function commandLinePackagingInstruction(render: RenderPackagingInstruction): PackagingInstruction {
    return {
        displayOrder: 2,
        name: "Command Line",
        render,
    };
}

export function buildServerPackagingInstruction(render: RenderPackagingInstruction): PackagingInstruction {
    return {
        displayOrder: 3,
        name: "Build Server",
        render,
    };
}
