import { isEqual } from "lodash";
import React from "react";
import type { PropertyValueResource } from "~/client/resources";
import { isSensitiveValue } from "~/client/resources";
import { Note } from "~/components/form";

interface DefaultValueIndicatorProps {
    value: PropertyValueResource | undefined;
    defaultValue: PropertyValueResource | undefined;
    onChange: (newValue: string | undefined) => void;
}

const ResetValueText = "Reset to default";
const ValueIsInheritedFromTemplateText = "Inheriting default value from the variable template.";
const ValueMatchesDefaultText = "This value matches the default value in the variable template, but overrides it because it has been set here.";

class DefaultValueIndicator extends React.Component<DefaultValueIndicatorProps, never> {
    render() {
        const { value, defaultValue } = this.props;
        if (defaultValue === undefined || defaultValue === "") {
            return null;
        }

        if (value === undefined || value === null) {
            return <Note>{ValueIsInheritedFromTemplateText}</Note>;
        }

        const resetValueButton = (
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    if (this.props.onChange) {
                        this.props.onChange(undefined);
                    }
                }}
            >
                {ResetValueText}
            </a>
        );

        // don't attempt to compare sensitive values
        // we don't want any indication that sensitive values match
        if (isSensitiveValue(value) || isSensitiveValue(defaultValue)) {
            return <Note>{resetValueButton}</Note>;
        }

        if (isValueSameAsDefault(value, defaultValue)) {
            return (
                <Note>
                    {ValueMatchesDefaultText} {resetValueButton}
                </Note>
            );
        }

        return <Note>{resetValueButton}</Note>;
    }
}

function isValueSameAsDefault(value: PropertyValueResource | undefined, defaultValue: PropertyValueResource | undefined) {
    // If no default value is entered, its value ends up being ""
    // While the typing suggests that the value could also be `undefined` or `null`, I'm not sure this can actually occur in practice
    if (defaultValue === undefined || defaultValue === null || defaultValue === "") return false;

    return isEqual(value, defaultValue);
}

export default DefaultValueIndicator;
export { DefaultValueIndicatorProps, ResetValueText, ValueIsInheritedFromTemplateText, ValueMatchesDefaultText };
