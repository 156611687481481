import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationNodesOverview, CommonConfigurationNodesTopics } from "../../CommonComponents/CommonConfigurationNodesViews";

export const ConfigurationNodesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.nodes.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationNodesOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationNodesTopics />;
    },
};
