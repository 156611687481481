/* eslint-disable @typescript-eslint/no-explicit-any */

import type { Client } from "../client";
import type { MachineConnectionStatus, MachineResource, NewWorkerMachineResource, ResourceCollection, WorkerMachineResource } from "../resources";
import BasicRepository from "./basicRepository";

export type ListWorkerArgs = {
    skip?: number;
    take?: number;
    partialName?: string;
    roles?: string;
    isDisabled?: boolean;
    healthStatuses?: string;
    commStyles?: string;
    tenantIds?: string;
    tenantTags?: string;
    environmentIds?: string;
    shellNames?: string;
    workerPoolIds?: string;
};

class WorkerRepository extends BasicRepository<WorkerMachineResource, NewWorkerMachineResource> {
    constructor(client: Client) {
        super("Workers", client);
    }
    list(args?: ListWorkerArgs): Promise<ResourceCollection<WorkerMachineResource>> {
        return this.client.get(this.client.getLink("Workers"), args);
    }
    getConnectionStatus(machine: MachineResource): Promise<MachineConnectionStatus> {
        return this.client.get<MachineConnectionStatus>(machine.Links["Connection"]);
    }
    discover(host: string, port: number, type: any, proxyId: string | undefined): Promise<WorkerMachineResource> {
        return proxyId ? this.client.get<WorkerMachineResource>(this.client.getLink("DiscoverWorker"), { host, port, type, proxyId }) : this.client.get<WorkerMachineResource>(this.client.getLink("DiscoverWorker"), { host, port, type });
    }
}

export default WorkerRepository;
