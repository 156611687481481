import * as React from "react";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { FeedType } from "~/client/resources/feedResource";
import { PackageSelectionMode } from "~/client/resources/packageReference";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import PackageDownloadOptions from "~/components/PackageDownloadOptions/PackageDownloadOptions";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import Summary from "~/components/form/Sections/Summary";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import Note from "~/primitiveComponents/form/Note/Note";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/CommonSummaryHelper";
import { ActionExecutionLocation, GetPrimaryPackageReference, InitialisePrimaryPackageReference, SetPrimaryPackageReference } from "../../../client/resources";
import routeLinks from "../../../routeLinks";
import { useKeyedItemAccess } from "../../KeyAccessProvider/KeyedItemAccessProvider";
import type { KeyedItemProps } from "../../KeyAccessProvider/types";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import pluginRegistry from "../pluginRegistry";
import type { ActionEditProps } from "../pluginRegistry";

interface TransferPackageActionSummaryState {
    feedName: string;
}

class AzureCloudServiceActionSummary extends BaseComponent<ActionSummaryProps, TransferPackageActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (
            <div>
                {"Transfer package"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg} />
                {this.props.targetRolesAsCSV && (
                    <span>
                        {" "}
                        to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} />{" "}
                    </span>
                )}
            </div>
        ) : (
            <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>
        );
    }
}

interface TransferProperties {
    "Octopus.Action.Package.TransferPath": string;
}

type TransferPackageActionEditState = {};

type TransferPackageActionEditProps = ActionEditProps<TransferProperties>;
type TransferPackageActionEditInternalProps = TransferPackageActionEditProps & ActionWithFeeds & KeyedItemProps;

class TransferPackageActionEditInternal extends BaseComponent<TransferPackageActionEditInternalProps, TransferPackageActionEditState> {
    constructor(props: TransferPackageActionEditInternalProps) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds, this.props.itemKey), true);
    }

    transferPathSummary() {
        const transferPath = this.props.properties["Octopus.Action.Package.TransferPath"];
        if (!transferPath) {
            return Summary.placeholder("The transfer path has not been configured");
        }
        return Summary.summary(
            <span>
                The package will be moved to <strong>{transferPath}</strong> once it is transfered
            </span>
        );
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const pkg = GetPrimaryPackageReference(this.props.packages);

        const help =
            this.props.feeds.length > 0 ? (
                <span>
                    This step is used to push the contents of a package to one or more machines which may be sourced from an external feed or the Octopus built-in feed. You can configure the remote machines to deploy to in the{" "}
                    <InternalLink to={routeLinks.infrastructure.root} openInSelf={false}>
                        Infrastructure
                    </InternalLink>{" "}
                    tab.
                </span>
            ) : (
                <span>Choose the package you which to transfer</span>
            );

        return (
            <div>
                <ExpanderSectionHeading title="Package Details" />
                <ExpandableFormSection
                    errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Package"
                    summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds, this.props.itemKey)}
                    help={help}
                >
                    <DeferredPackageSelector
                        packageId={pkg.PackageId}
                        feedIdOrName={pkg.FeedId}
                        onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                        onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                        packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                        feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                        projectId={this.props.projectId}
                        feeds={this.props.feeds}
                        localNames={this.props.localNames}
                        feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven, FeedType.GitHub]}
                        refreshFeeds={this.loadFeeds}
                        parameters={this.props.parameters}
                        packageSelectionMode={pkg.Properties["SelectionMode"]}
                        packageSelectionModeError={this.props.getFieldError("SelectionMode")}
                        onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))}
                        packageParameterName={pkg.Properties["PackageParameterName"]}
                        packageParameterError={this.props.getFieldError("PackageParameterName")}
                        onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}
                    />
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (
                        <ProcessFeedLookup feedNameOrId={pkg.FeedId}>
                            {(feed) => (
                                <PackageDownloadOptions
                                    packageAcquisitionLocation={pkg.AcquisitionLocation}
                                    onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))}
                                    feed={feed}
                                    projectId={this.props.projectId}
                                    localNames={this.props.localNames}
                                />
                            )}
                        </ProcessFeedLookup>
                    )}
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Package.TransferPath"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Transfer Path"
                    summary={this.transferPathSummary()}
                    help="Enter the path the package should be moved to on the remote target."
                >
                    <VariableLookupText
                        localNames={this.props.localNames}
                        value={this.props.properties["Octopus.Action.Package.TransferPath"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.Package.TransferPath"]: x })}
                        error={this.props.getFieldError("Octopus.Action.Package.TransferPath")}
                        label="Transfer path"
                    />
                    <Note>The location that the package should be moved to once it is uploaded to the remote target.</Note>
                </ExpandableFormSection>
            </div>
        );
    }

    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
}

function TransferPackageActionEdit(props: React.PropsWithChildren<TransferPackageActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const itemKey = useKeyedItemAccess();

    return <TransferPackageActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} itemKey={itemKey} />;
}

pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.TransferPackage",
    summary: (properties, targetRolesAsCSV, packages) => <AzureCloudServiceActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: TransferPackageActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
});
