import * as React from "react";
import ActionButton, { ActionButtonType, IconPosition } from "~/components/Button/ActionButton";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { SimpleMenu } from "~/primitiveComponents/navigation/Menu/SimpleMenu";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";

export function TentacleDownloadButton() {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();

    return (
        <>
            <ActionButton type={ActionButtonType.Primary} icon={<DropDownIcon />} iconPosition={IconPosition.Right} label="Download Tentacle" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes} />
            <SimpleMenu
                compact={true}
                menuState={menuState}
                items={[
                    { type: "external-link", label: "Windows 32-bit/x86 installer", href: "LatestTentacleWinX86" },
                    { type: "external-link", label: "Windows 64-bit/x64 installer", href: "LatestTentacleWinX64" },
                    { type: "external-link", label: "Need a custom version?", href: "TentacleDownloadPage" },
                ]}
                accessibleName={"Download tentacle"}
            />
        </>
    );
}
