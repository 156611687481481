import type { Reducer } from "redux";
import { combineReducers } from "redux";
import type { DrawerAreaState } from "./DrawerAreaState";
import config from "./drawerArea";

const areaReducer: Reducer<DrawerAreaState> = combineReducers({ config });
export * from "./actions";
export * from "./selectors";

export default areaReducer;
