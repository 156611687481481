/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */

import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { TaskResource } from "~/client/resources";
import { repository, client } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "~/routeLinks";
import TaskLayout from "../../TaskLayout/TaskLayout";
import Task, { BuiltInTask } from "./Task";

interface TaskScreenState extends DataBaseComponentState {
    task?: TaskResource<any>;
    redirectTo?: string;
}

export class TaskScreen extends DataBaseComponent<RouteComponentProps<{ taskId: string }>, TaskScreenState> {
    taskId: string;
    constructor(props: RouteComponentProps<{ taskId: string }>) {
        super(props);
        this.taskId = this.props.match.params.taskId;
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const task = await repository.Tasks.get(this.taskId, { spaces: ["all"] });

            if (task.SpaceId && task.SpaceId != client.spaceId) {
                this.setState({ redirectTo: routeLinks.forSpace(task.SpaceId).task(task).root });
                return;
            }

            if (task.Name === BuiltInTask.Deploy && task.Arguments.DeploymentId) {
                this.setState({ redirectTo: routeLinks.deployment(task.Arguments.DeploymentId).root, task });
                return;
            }

            if (task.Name === BuiltInTask.RunbookRun && task.Arguments.RunbookRunId) {
                this.setState({ redirectTo: routeLinks.runbookRun(task.Arguments.RunbookRunId).root, task });
                return;
            }

            if (task.Name === BuiltInTask.ExportProjects) {
                this.setState({ redirectTo: routeLinks.exportTask(task.Id).root, task });
                return;
            }

            if (task.Name === BuiltInTask.ImportProjects) {
                this.setState({ redirectTo: routeLinks.importTask(task.Id).root, task });
                return;
            }

            this.setState({ task });
        });
    }

    render() {
        const { redirectTo, task } = this.state;
        if (redirectTo) {
            return <InternalRedirect to={{ pathname: redirectTo }} push={false} />;
        }

        return (
            <TaskLayout>
                <Task task={task} taskId={this.taskId} delayRender={() => task == null} />
            </TaskLayout>
        );
    }
}
