// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Analytic Link Context

import React, { useContext } from "react";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
const AnalyticLinkContext = React.createContext<string | null>(null);

interface AnalyticLinkContextProps {
    location: string;
}

export function AnalyticLinkProvider(props: React.PropsWithChildren<AnalyticLinkContextProps>) {
    return <AnalyticLinkContext.Provider value={props.location}>{props.children}</AnalyticLinkContext.Provider>;
}

export function useAnalyticLinkLocation(): string | null {
    const location = useContext(AnalyticLinkContext);

    return location;
}
