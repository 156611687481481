import React from "react";
import type { IconSize } from "~/areas/infrastructure/components/MachineHealthStatusIcons/IconSize";
import { getIconSize } from "~/areas/infrastructure/components/MachineHealthStatusIcons/getIconSize";
import styles from "./styles.module.less";

export interface HasWarningsMachineIconProps {
    iconSize?: IconSize;
    title?: string;
}

export function HasWarningsMachineIcon({ title = "Has Warnings", iconSize }: HasWarningsMachineIconProps) {
    return (
        <svg {...getIconSize(iconSize)} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={styles.healthStatusIcon}>
            <title>{title}</title>
            <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
                <circle stroke="#53B953" fill="#FFF" cx="7.944444" cy="7.5" r="7.5" />
                <path fill="#F8CE1C" d="M2.691494 11.009118l5.132503-8.844902 5.132503 8.844902" />
                <path stroke="#FFF" strokeWidth=".25" fill="#FFF" d="M8.282456 8V4.755095h-.916918V8zM8.282456 10.023197v-.939282h-.916918v.939282z" />
            </g>
        </svg>
    );
}
