import * as React from "react";
import FeaturesLayout from "~/areas/configuration/components/FeaturesLayout/FeaturesLayout";
import { DevToolsTab } from "../DevTools/DevToolsContext";

export function FeaturesDevToolsTabRegistration() {
    return (
        <DevToolsTab name={"Features"}>
            <FeaturesLayout fullWidth={true} dirtyTrackingDisabled={true} />
        </DevToolsTab>
    );
}
