import React from "react";

export const createHookHOC = <TContextProps extends unknown>(hook: () => TContextProps, displayName: string) => {
    return <TInternalProps extends TInjectedProps, TInjectedProps>(Component: React.ComponentType<TInternalProps>, mapContextsToProps: (context: TContextProps) => TInjectedProps) => {
        type TExternalProps = Omit<TInternalProps, keyof TInjectedProps>;

        const WithHookHoc: React.FC<TExternalProps> = (props) => {
            const contextProps = hook();
            const mappedProps = mapContextsToProps(contextProps);
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const allProps = { ...mappedProps, ...props } as TInternalProps;
            return <Component {...allProps} />;
        };
        WithHookHoc.displayName = displayName;
        return WithHookHoc;
    };
};

export default createHookHOC;
