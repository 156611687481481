import * as React from "react";
import { Route } from "react-router-dom";
import { SomethingsWrong } from "~/components/Images/NotFound/SomethingsWrong";
import routeLinks from "../../routeLinks";
import AreaTitle from "../AreaTitle/index";
import { EmptyPage, ImageHeight } from "../EmptyPage/EmptyPage";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";

const RedirectAs404 = () => <Route render={({ location }) => <InternalRedirect to={{ ...location, state: { is404: true } }} />} />;

const NotFound = () => (
    <main id="maincontent">
        <AreaTitle link={routeLinks.root} title="Page not found" />
        <EmptyPage title="Sorry, the page you are looking for could not be found" image={<SomethingsWrong height={ImageHeight} />} />
    </main>
);

export { NotFound, RedirectAs404 };
