import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

const OnboardingTextForTagSets = "Tenant tags help you to classify your tenants using custom tags that meet your needs, and tailor tenanted deployments for your projects and environments.";

const Onboarding = () => {
    return (
        <OnboardingPage
            title="Create tags to easily classify and group tenants"
            intro={<span>{OnboardingTextForTagSets}</span>}
            learnMore={
                <>
                    <ExternalLink href="MultiTenantTags">Learn more</ExternalLink>
                </>
            }
        />
    );
};

export default Onboarding;
