import type { Client } from "../client";
import type ExternalSecurityGroupProviderResource from "../resources/externalSecurityGroupProviderResource";

export default class ExternalSecurityGroupProviderRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    all(): Promise<ExternalSecurityGroupProviderResource[]> {
        return this.client.get(this.client.getLink("ExternalSecurityGroupProviders"));
    }
}
