import type { ServerTimezoneResource } from "~/client/resources/index";
import { Summary } from "~/components/form/index";

const timezoneSummary = (timezones: ServerTimezoneResource[], selectedTimeZoneId?: string) => {
    if (selectedTimeZoneId) {
        const timezone = timezones.find((tz) => tz.Id == selectedTimeZoneId);
        if (timezone) {
            return Summary.summary(timezone.Id == "UTC" ? `${timezone.Name}` : `${timezone.Name} - ${timezone.Id}`);
        }
        return Summary.summary(selectedTimeZoneId);
    }
    return Summary.placeholder("No timezone selected");
};

export default timezoneSummary;
