import cn from "classnames";
import * as React from "react";
import styles from "./CustomDialogContent.module.less";

interface CustomDialogContentProps {
    className?: string;
}

const CustomExtraContent: React.SFC<CustomDialogContentProps> = ({ className, children }) => {
    return <div className={cn(styles.padded, className)}>{children}</div>;
};

const CustomDialogContent: React.SFC<CustomDialogContentProps> = ({ className, children }) => {
    return <div className={cn(styles.content, styles.block, styles.padded, className)}>{children}</div>;
};

const CustomFlexDialogContent: React.SFC<CustomDialogContentProps> = ({ className, children }) => {
    return <div className={cn(styles.content, styles.flex, className)}>{children}</div>;
};

export default CustomDialogContent;

export { CustomDialogContent, CustomFlexDialogContent, CustomExtraContent };
