/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";

interface PageDividerProps {
    children: any;
    as?: React.ReactType<any>;
}

export const PageDivider = ({ children, as: Component = "p" }: PageDividerProps) => {
    return React.createElement(Component, null, children);
};

export default PageDivider;
