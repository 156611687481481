/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import type { DockerFeedResource, FeedResource } from "~/client/resources/feedResource";
import { FeedType } from "~/client/resources/feedResource";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { Summary } from "~/components/form";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Note from "~/primitiveComponents/form/Note/Note";
import Text from "~/primitiveComponents/form/Text/Text";
import AdvancedFeedCredentialsFormSection, { AuthenticationType } from "../AdvancedFeedCredentialsFormSection";
import type { FeedEditProps } from "./feedTypeRegistry";
import feedTypeRegistry from "./feedTypeRegistry";

const supportedAuthenticationTypes = [AuthenticationType.Anonymous, AuthenticationType.UsernameAndPassword, AuthenticationType.GoogleCloudJsonKey];
class DockerFeed extends React.Component<FeedEditProps<DockerFeedResource>> {
    render() {
        return (
            <React.Fragment>
                <ExpandableFormSection
                    errorKey="RegistryPath"
                    title="Registry Path"
                    summary={this.props.feed.RegistryPath ? Summary.summary(this.props.feed.RegistryPath) : Summary.placeholder("Please enter the path of the registry")}
                    help={
                        <span>
                            The path of the <ExternalLink href="DockerPullingFromADifferentRegistry">registry</ExternalLink> for the docker host to pull from as accessible from the target.
                        </span>
                    }
                >
                    <Text value={this.props.feed.RegistryPath} onChange={(RegistryPath) => this.props.onChange({ ...this.props.feed, RegistryPath })} label="Registry path" error={this.props.getFieldError("RegistryPath")} />
                    <Note>
                        Example: <code>localhost:5000</code>. Leave empty if the same as the URL host provided above.
                    </Note>
                </ExpandableFormSection>
                <AdvancedFeedCredentialsFormSection
                    model={{ username: this.props.feed.Username, password: this.props.feed.Password }}
                    isNew={!this.props.feed.Id}
                    onChanged={(model) => this.props.onChange({ ...this.props.feed, Password: model.password, Username: model.username })}
                    supportedAuthenticationTypes={supportedAuthenticationTypes}
                />
            </React.Fragment>
        );
    }
}

feedTypeRegistry.registerFeedType({
    type: FeedType.Docker,
    edit: DockerFeed as React.ComponentType<FeedEditProps<FeedResource>>,
    text: "Docker Container Registry",
    uriNotes: (
        <React.Fragment>
            <div>
                For the public Docker Hub registry, set the url to <code>https://index.docker.io</code>
            </div>
            <div>
                For Google Cloud Registry, see this <ExternalLink href="GCRRegistryLinks">documentation</ExternalLink>.
            </div>
        </React.Fragment>
    ),
    uriDefault: "https://index.docker.io",
});
