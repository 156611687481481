import type { ObjectInputPaths } from "@octopusdeploy/step-inputs";
import type { DeploymentTargetInputComponent, DeploymentTargetUI } from "@octopusdeploy/step-ui";
import { bold, code, italic, link, note, number, account, checkbox, radioButtons, section, sectionGroup, sensitiveText, text, select } from "@octopusdeploy/step-ui";
import type { Union, SampleDeploymentTargetInputs } from "./SampleDeploymentTargetInputs";

export const sampleDeploymentTargetUI: DeploymentTargetUI<SampleDeploymentTargetInputs> = {
    createInitialInputs: () => ({
        string: "Initial value",
        union: {
            type: "first",
            valueFromFirst: "Another initial value",
        },
        secondUnion: {
            type: "second",
            valueFromSecond: {
                type: "empty",
            },
        },
        boolean: false,
        region: "us-east1",
        select: "select-1",
        number: 100,
    }),
    editInputsForm: (inputs) => {
        return [
            text({
                input: inputs.string,
                label: "Some string input",
                helpText: "Enter a string!",
                note: note`This is a note for the string input. It even contains a ${link({ href: "https://www.octopus.com", title: "link" })} and some ${italic("differently")} ${bold("formatted")} ${code("text")}.`,
            }),
            checkbox({
                input: inputs.boolean,
                label: "A checkbox input",
                helpText: "Select whether the value is true or false",
                note: note`This is a note for the checkbox input`,
            }),
            radioButtons({
                input: inputs.region,
                label: "Region",
                helpText: "Pick a region",
                options: [
                    { label: "US East 1", newValue: "us-east1" },
                    { label: "US East 2", newValue: "us-east2" },
                    { label: "AU East 1", newValue: "au-east1" },
                ],
                note: note`This is a note for the radio buttons input`,
            }),
            select({
                input: inputs.select,
                label: "A select input",
                helpText: "Select an option",
                options: [
                    {
                        label: "Option 1",
                        newValue: "select-1",
                    },
                    {
                        label: "Option 2",
                        newValue: "select-2",
                    },
                    {
                        label: "Option 3",
                        newValue: "select-3",
                    },
                ],
                note: note`This is a note for the select input`,
            }),
            number({
                input: inputs.number,
                label: "Some number input",
                helpText: "Enter a number!",
                note: note`This is a note for the number input`,
            }),
            sectionGroup({
                title: "A section group",
                content: [
                    section({
                        title: "A section",
                        content: [
                            sensitiveText({
                                input: inputs.sensitiveValue,
                                label: "Sensitive value",
                                helpText: "Enter a sensitive value",
                                note: note`This is a note for the sensitive input`,
                            }),
                        ],
                    }),
                ],
            }),
            section({
                title: "Union section",
                content: [
                    radioButtons({
                        input: inputs.union,
                        label: "First or Second?",
                        helpText: "Pick between first and second",
                        options: [
                            {
                                label: "First option",
                                newValue: {
                                    type: "first",
                                    valueFromFirst: "some value",
                                },
                            },
                            {
                                label: "Second option",
                                newValue: {
                                    type: "second",
                                    valueFromSecond: { type: "empty" },
                                },
                            },
                        ],
                    }),
                    ...unionUI(inputs.union),
                ],
            }),
            section({
                title: "A second union section",
                content: [
                    select({
                        input: inputs.secondUnion,
                        label: "First or Second (from select)?",
                        helpText: "Pick between first and second",
                        options: [
                            {
                                label: "First option",
                                newValue: {
                                    type: "first",
                                    valueFromFirst: "some value",
                                },
                            },
                            {
                                label: "Second option",
                                newValue: {
                                    type: "second",
                                    valueFromSecond: { type: "empty" },
                                },
                            },
                        ],
                    }),
                    ...unionUI(inputs.secondUnion),
                ],
            }),
            section({
                title: "Accounts",
                content: [
                    account({
                        input: inputs.awsAccount,
                        label: "AWS Account",
                        helpText: "Select an AWS Account",
                    }),
                    account({
                        input: inputs.azureAccount,
                        label: "Azure Account",
                        helpText: "Select an Azure Account",
                    }),
                    account({
                        input: inputs.googleAccount,
                        label: "Google Account",
                        helpText: "Select a Google Account",
                    }),
                    account({
                        input: inputs.sshKeyPair,
                        label: "SSH Key Pair",
                        helpText: "Select an SSH Key Pair",
                    }),
                    account({
                        input: inputs.token,
                        label: "Token",
                        helpText: "Select a Token",
                    }),
                    account({
                        input: inputs.username,
                        label: "Username/Password",
                        helpText: "Select a Username/Password",
                    }),
                ],
            }),
        ];
    },
};

function unionUI(inputs: ObjectInputPaths<Union>): DeploymentTargetInputComponent[] {
    if (inputs.type === "first") {
        return [
            text({
                input: inputs.valueFromFirst,
                label: "String for first",
                helpText: "Enter a string for the first option",
            }),
        ];
    }
    return [
        sensitiveText({
            input: inputs.valueFromSecond,
            label: "Sensitive value for second",
            helpText: "Enter a sensitive value for the second option",
        }),
    ];
}
