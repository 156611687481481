/* eslint-disable @typescript-eslint/no-explicit-any */

import cn from "classnames";
import * as React from "react";
import { DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import DateFormatter from "~/utils/DateFormatter";
import type { DeploymentResource } from "../../../../../client/resources/deploymentResource";
import { getTaskStartTime } from "../../../../../client/resources/taskResource";
import type { TaskResource } from "../../../../../client/resources/taskResource";
import InternalLink from "../../../../../components/Navigation/InternalLink/InternalLink";
import routeLinks from "../../../../../routeLinks";
import DeploymentDetailsIcon from "./DeploymentDetailsIcon";
import styles from "./style.module.less";

interface DeploymentChildRowProps {
    deployment: DeploymentResource;
    task: TaskResource<{ DeploymentId: string }>;
    showChildRows: boolean;
    [others: string]: any;
}

const DeploymentChildRow: React.StatelessComponent<DeploymentChildRowProps> = (props) => {
    const { className, showChildRows, deployment, task } = props;

    return (
        <DataTableRow key={`${deployment.Id}`} className={cn(className, showChildRows ? styles.visible : styles.hidden, styles.deploymentsTableRow)}>
            <DataTableRowColumn />
            <DataTableRowColumn>
                <InternalLink to={routeLinks.task(deployment).root} accessibleName={`View deployment task for ${deployment.Name} queued for or started at ${getTaskStartTime(task)}`}>
                    <DeploymentDetailsIcon task={task} deployment={deployment} stripTopBottomPadding={true} />
                </InternalLink>
            </DataTableRowColumn>
            <DataTableRowColumn>
                <span className={styles.deploymentDate}>{DateFormatter.dateToLongFormat(getTaskStartTime(task))}</span>
            </DataTableRowColumn>
            <DataTableRowColumn />
        </DataTableRow>
    );
};

DeploymentChildRow.displayName = "DeploymentChildRow";
export default DeploymentChildRow;
