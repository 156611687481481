import * as React from "react";
import type { PropsWithChildren } from "react";
import type { EndpointRegistration, CategorizedEndpointRegistration, CategoryDefinition, StepPackageDeploymentTargetRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry, { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import type { Errors } from "~/components/DataBaseComponent";
import DataLoader from "~/components/DataLoader";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import PaperLayout from "~/components/PaperLayout";
import routeLinks from "~/routeLinks";
import { useSpaceAwareNavigation } from "../../../../components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { EndpointSelector } from "./Endpoints/EndpointSelector";

const MachineTargetNewDataLoader = DataLoader<LoadedData>();
type LoadedData = { registrations: EndpointRegistration[] };

interface MachineTargetNewProps {
    environmentId: string | undefined;
}

export function MachineTargetNew(props: MachineTargetNewProps) {
    return (
        <MachineTargetNewDataLoader
            load={async () => {
                const registrations = await endpointRegistry.getAllRegistrations();
                return { registrations };
            }}
            renderAlternate={({ busy, errors }) => <MachineTargetNewLayout busy={busy} errors={errors} />}
            renderWhenLoaded={(loadedData) => <MachineTargetNewWhenLoaded registrations={loadedData.registrations} environmentId={props.environmentId} />}
        />
    );
}

interface MachineTargetNewWhenLoadedProps {
    registrations: EndpointRegistration[];
    environmentId: string | undefined;
}

function MachineTargetNewWhenLoaded({ registrations, environmentId }: MachineTargetNewWhenLoadedProps) {
    const navigate = useNavigateToDeploymentTarget(environmentId);
    const categories = endpointRegistry.categorizeEndpoints(registrations);
    return (
        <MachineTargetNewLayout>
            <EndpointSelector heading={<Heading />} onSelect={navigate} scope={EndpointSelectionScope.DeploymentTarget} registrations={registrations} categories={categories} />
        </MachineTargetNewLayout>
    );
}

function MachineTargetNewLayout({ busy, errors, children }: PropsWithChildren<{ busy?: boolean; errors?: Errors }>) {
    return (
        <PaperLayout busy={busy} errors={errors} title="Add Deployment Target" breadcrumbTitle={"Deployment Targets"} breadcrumbPath={routeLinks.infrastructure.machines.root} fullWidth={true} flatStyle={true}>
            {children}
        </PaperLayout>
    );
}

function Heading() {
    return (
        <>
            What type of <strong>Deployment Target</strong> do you want to set up? Learn more about <ExternalLink href="DeploymentTargets">Deployment Targets</ExternalLink>
        </>
    );
}

function isStepPackageDeploymentTarget(obj: EndpointRegistration): obj is StepPackageDeploymentTargetRegistration {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (obj as StepPackageDeploymentTargetRegistration).targetType !== undefined;
}

export function useNavigateToDeploymentTarget(environmentId: string | undefined) {
    const navigation = useSpaceAwareNavigation();

    return React.useCallback(
        (registration: CategorizedEndpointRegistration, category: CategoryDefinition) => {
            if (endpointRegistry.isMachineRegistration(registration) && registration.discoverable) {
                return navigation.navigate(routeLinks.infrastructure.machines.discover(registration.key, environmentId, category?.category));
            } else {
                if (isStepPackageDeploymentTarget(registration)) return navigation.navigate(routeLinks.infrastructure.machines.create({ type: registration.communicationStyle, deploymentTargetTypeId: registration.key, environment: environmentId }));
                else return navigation.navigate(routeLinks.infrastructure.machines.create({ type: registration.communicationStyle, environment: environmentId }));
            }
        },
        [environmentId, navigation]
    );
}
