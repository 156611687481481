import type TelemetryConfigratuionResource from "~/client/resources/telemetryConfigurationResource";
import type { Client } from "../client";
import ConfigurationRepository from "./configurationRepository";

class TelemetryConfigurationRepository extends ConfigurationRepository<TelemetryConfigratuionResource> {
    constructor(client: Client) {
        super("TelemetryConfiguration", client);
    }
}

export default TelemetryConfigurationRepository;
