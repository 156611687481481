import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonTaskOverview, CommonTaskTopics } from "../../CommonComponents/CommonTaskViews";

export const TasksScriptConsoleRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.tasks.console,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonTaskOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonTaskTopics />;
    },
};
