import * as React from "react";
import styles from "./style.module.less";

const ListTitle: React.StatelessComponent = (props) => {
    return <h3 className={styles.listTitle}>{props.children}</h3>;
};

ListTitle.displayName = "ListTitle";

export default ListTitle;
