import * as React from "react";
import type { ProjectResource } from "~/client/resources";
import { Permission } from "~/client/resources";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import { Callout, CalloutType } from "../../../../primitiveComponents/dataDisplay/Callout/Callout";
import routeLinks from "../../../../routeLinks";

interface MissingProcessStepsMessageProps {
    project: ProjectResource;
    branchName?: string;
}

const MissingProcessStepsMessage: React.StatelessComponent<MissingProcessStepsMessageProps> = (props) => {
    return (
        <Callout title="Deployment process does not have any steps" type={CalloutType.Warning}>
            <div>
                The project's deployment process{props.branchName ? ` on branch "${props.branchName}"` : ""} doesn't have any steps.
                <PermissionCheck permission={Permission.ProcessEdit} project={props.project.Id} tenant="*">
                    <span>
                        {" "}
                        Add steps under the{" "}
                        <strong>
                            <InternalLink to={routeLinks.project(props.project).deployments.process.root}>Process</InternalLink>
                        </strong>{" "}
                        menu for this {props.branchName ? "branch or choose another branch" : "project"}.
                    </span>
                </PermissionCheck>
            </div>
        </Callout>
    );
};

export default MissingProcessStepsMessage;
