import type { Client } from "../client";
import type FeaturesConfigurationResource from "../resources/featuresConfigurationResource";
import ConfigurationRepository from "./configurationRepository";

class FeaturesConfigurationRepository extends ConfigurationRepository<FeaturesConfigurationResource> {
    constructor(client: Client) {
        super("FeaturesConfiguration", client);
    }
}

export default FeaturesConfigurationRepository;
