import React from "react";
import type { VariableEditorScope } from "./types";

const VariableEditorScopingContext = React.createContext<VariableEditorScope>("Unscoped");

export const useVariableEditorScoping = () => {
    return React.useContext(VariableEditorScopingContext);
};

type VariableEditorScopingProviderProps = {
    scope: VariableEditorScope;
};

export const VariableEditorScopingProvider: React.FC<VariableEditorScopingProviderProps> = (props) => {
    return <VariableEditorScopingContext.Provider value={props.scope}>{props.children}</VariableEditorScopingContext.Provider>;
};
