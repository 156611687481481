import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

export const OnboardingTextForFeeds = "Configure multiple projects to use a single set of credentials.";

const Onboarding = () => {
    return (
        <OnboardingPage
            title="Add credentials to use in version controlled projects"
            intro={<span>{OnboardingTextForFeeds}</span>}
            learnMore={
                <>
                    <ExternalLink href="ConfigAsCodeEap">Learn more</ExternalLink>
                </>
            }
        />
    );
};

export default Onboarding;
