import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { withPage } from "~/components/Page/Page";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import routeLinks from "../../../../routeLinks";
import { ExternalFeed } from "./ExternalFeed";
import { ExternalFeedTest } from "./ExternalFeedTest";
import { ExternalFeeds } from "./ExternalFeeds";
import { RedirectFeedBasedOnType } from "./RedirectFeedBasedOnType";

const ExternalFeedTestPage = withPage({ page: pageIds.library.feed.test })(ExternalFeedTest);
const ExternalFeedPage = withPage({ page: pageIds.library.feed.root })(ExternalFeed);
const CreateExternalFeedPage = withPage({ page: pageIds.library.feedCreate })(ExternalFeed);
const ListExternalFeedPage = withPage({ page: pageIds.library.feeds })(ExternalFeeds);

export class ExternalFeedRoutes extends React.Component<RouteComponentProps<void>> {
    render() {
        const libraryRouteLinks = routeLinks.library;
        return (
            <Switch>
                <ReloadableRoute path={libraryRouteLinks.feedCreate} render={(routeProps) => <CreateExternalFeedPage create {...routeProps} />} />
                <ReloadableRoute path={libraryRouteLinks.feed(":feedId").redirect} component={RedirectFeedBasedOnType} />
                <ReloadableRoute path={libraryRouteLinks.feed(":feedId").test} component={ExternalFeedTestPage} />
                <ReloadableRoute path={libraryRouteLinks.feed(":feedId").root} component={ExternalFeedPage} />
                <ReloadableRoute component={ListExternalFeedPage} />
            </Switch>
        );
    }
}

export default ExternalFeedRoutes;
