import * as React from "react";
import { ActionButton, ActionButtonType } from "~/components/Button/ActionButton";
import Dialog from "~/components/Dialog/Dialog";
import { DialogLayoutConnect } from "~/components/Dialog/DialogLayoutConnect";
import type { DialogLayoutCommonProps, DialogLayoutDispatchProps } from "~/components/DialogLayout/DialogLayout";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";

interface DeleteDialogLayoutProps extends DialogLayoutCommonProps {
    deleteButtonLabel?: string;
    deleteButtonBusyLabel?: string;
    open: boolean;
    onClose(): void;
    onDeleteClick(): Promise<boolean>;
}

class DeleteDialogInternal extends React.Component<DeleteDialogLayoutProps & DialogLayoutDispatchProps> {
    deleteClick = async () => {
        const result = await this.props.onDeleteClick();
        if (result) {
            this.props.close();
        }
    };

    render() {
        const save = <ActionButton key="Delete" type={ActionButtonType.Delete} label={this.props.deleteButtonLabel || "Delete"} busyLabel={this.props.deleteButtonBusyLabel || "Deleting..."} onClick={this.deleteClick} disabled={this.props.busy} />;
        const cancel = <ActionButton key="Cancel" label="Cancel" disabled={this.props.busy} onClick={() => this.props.onClose()} />;
        return (
            <Dialog open={this.props.open || false}>
                <DialogLayout actions={[cancel, save]} {...this.props} closeDialog={this.props.close} />
            </Dialog>
        );
    }
}

const DeleteDialog = DialogLayoutConnect.to(DeleteDialogInternal);
DeleteDialog.displayName = "DeleteDialog";
export default DeleteDialog;
