import type { MaintenanceConfigurationResource } from "~/client/resources";
import type { Client } from "../client";
import ConfigurationRepository from "./configurationRepository";

class MaintenanceConfigurationRepository extends ConfigurationRepository<MaintenanceConfigurationResource> {
    constructor(client: Client) {
        super("MaintenanceConfiguration", client);
    }
}

export default MaintenanceConfigurationRepository;
