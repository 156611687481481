/* eslint-disable @typescript-eslint/init-declarations */

import { repository } from "~/clientInstance";

interface CertificateIndex {
    [certificateId: string]: string;
}

const getCertificateIndex = async (ids?: string[]): Promise<CertificateIndex> => {
    const idx: { [certificateId: string]: string } = {};
    const certificates = await repository.Certificates.all({ ids });
    if (certificates) {
        certificates.forEach((cert) => (idx[cert.Id] = cert.Name));
    }
    return idx;
};

export { getCertificateIndex, CertificateIndex };
