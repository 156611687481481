import type { FeatureToggle } from "~/client/resources/enabledFeatureToggles";
import { session } from "~/clientInstance";

// Exposes the feature toggles to consumers
export function useEnabledFeatureToggle(featureToggle: FeatureToggle): boolean {
    if (!session.featureToggles) {
        throw "Feature toggles have not yet loaded. These settings are only available once logged in.";
    }
    return session.featureToggles.includes(featureToggle) ?? false;
}
