import * as React from "react";
import type { ExtensionsInfoResource } from "~/client/resources/extensionsInfoResource";
import { repository } from "../../../../clientInstance";
import type { DataBaseComponentState } from "../../../../components/DataBaseComponent";
import { DataBaseComponent } from "../../../../components/DataBaseComponent";
import PaperLayout from "../../../../components/PaperLayout";
import SimpleDataTable from "../../../../components/SimpleDataTable";

interface ExtensionsState extends DataBaseComponentState {
    extensions: ExtensionsInfoResource[];
}

class ExtensionsTable extends SimpleDataTable<ExtensionsInfoResource> {}

export class Extensions extends DataBaseComponent<{}, ExtensionsState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            extensions: [],
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const extensions = await repository.ServerStatus.getExtensionStats();

            this.setState({ extensions });
        });
    }

    render() {
        return (
            <PaperLayout title="Extensions" busy={this.state.busy} errors={this.errors}>
                {this.state.extensions && <ExtensionsTable data={this.state.extensions} headerColumns={["Name", "Author", "Version"]} onRow={this.onRow} />}
            </PaperLayout>
        );
    }

    private onRow = (data: ExtensionsInfoResource) => {
        return [<strong>{data.Name}</strong>, data.Author, data.Version];
    };
}
