import type { RunbookRunResource } from "~/client/resources";
import runbookSnapshotRouteLinks from "../Runbooks/RunbookSnapshots/runbookSnapshotRouteLinks";
import { process, scheduledTrigger, trigger } from "../routeLinkHelpers";

export const opsRouteLinks = (root: string) => {
    return {
        root,
        runbooks: `${root}/runbooks`,
        runbook: (id: string) => runbookLinks(`${root}/runbooks/${id}`),
        triggers: `${root}/triggers`,
        trigger: trigger(`${root}/triggers`),
        scheduledTrigger: scheduledTrigger(`${root}/triggers`),
        scheduledTriggers: {
            root: `${root}/triggers`,
            new: `${root}/triggers/scheduled/create/runbooks`,
            newForRunbook: (runbookId: string) => `${root}/triggers/scheduled/create/runbooks/${runbookId}`,
        },
    };
};

export const runbookProcessLinks = (root: string) => {
    return {
        runbookProcess: (id: string) => ({ root: `${root}/${id}`, process: process(`${root}/${id}`) }),
    };
};

export type RunbookRouteLinks = ReturnType<typeof runbookLinks>;
export function runbookLinks(root: string) {
    return {
        root,
        overview: `${root}/overview`,
        runslist: `${root}/runslist`,
        runbookProcess: runbookProcessLinks(`${root}/process`),
        ...runbookSnapshotRouteLinks(`${root}/snapshots`),
        runbookRun: (runbookRunId: string | RunbookRunResource) => {
            if (!!runbookRunId && typeof runbookRunId !== "string") {
                runbookRunId = runbookRunId.Id;
            }
            return {
                root: `${root}/runs/${runbookRunId}`,
            };
        },
        runNow: {
            root: `${root}/runNow`,
            runbookSnapshot: (runbookSnapshotId: string) => `${root}/runNow/${runbookSnapshotId}`,
        },
        settings: `${root}/settings`,
    };
}

export default opsRouteLinks;
