import { throttle } from "lodash";
import configureStore from "./configureStore";
import { loadState, saveState } from "./persistence";
import rootReducer from "./reducers";

const persistedState = loadState();

const store = configureStore(persistedState)(rootReducer);

store.subscribe(
    throttle(() => {
        const state = store.getState();
        saveState({
            drawerArea: state.drawerArea,
        });
    }, 250)
);

export default store;
