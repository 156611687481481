import { resolveStringPathWithSpaceId } from "./resolvePathWithSpaceId";

export function isUrlActive(currentUrl: string, spaceId: string | undefined, toUrl: string, exact: boolean) {
    if (!toUrl) {
        return false;
    }

    const resolvedToUrl = toUrl ? resolveStringPathWithSpaceId(toUrl, spaceId) : toUrl;
    const resolvedUrlWithoutQueryStrings = resolvedToUrl === null ? "" : resolvedToUrl.split("?")[0];

    return exact ? currentUrl === resolvedUrlWithoutQueryStrings : currentUrl.startsWith(resolvedUrlWithoutQueryStrings);
}
