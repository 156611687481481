/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { CertificateResource, ResourceCollection } from "~/client/resources";
import { Permission } from "~/client/resources";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import { NavigationButton, NavigationButtonType } from "~/components/Button";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import CertificateSearch from "~/components/form/CertificateSearch/CertificateSearch";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import CertificateOnboarding from "./CertificateOnboarding/CertificateOnboarding";
import CertificateSidebar from "./CertificateSidebar";

interface CertificatesState extends DataBaseComponentState {
    certificates?: ResourceCollection<CertificateResource>;
}

export default class Certificates extends DataBaseComponent<RouteComponentProps<void>, CertificatesState> {
    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = {
            certificates: null!,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const certificates = await repository.Certificates.list();
            this.setState({
                certificates,
            });
        });
    }

    render() {
        const addCertificateButton = (
            <PermissionCheck permission={Permission.CertificateCreate} wildcard={true}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add certificate" href={`${this.props.match.url}/create`} />
            </PermissionCheck>
        );
        const archiveButton = <NavigationButton type={NavigationButtonType.Secondary} label="View Archive" href={`${this.props.match.url}/archive`} />;
        const sectionControl = <ActionList actions={[archiveButton, addCertificateButton]} />;
        const sidebar = this.state.certificates && this.state.certificates.TotalResults > 0 ? <CertificateSidebar /> : null;
        const content = this.state.certificates && (this.state.certificates.TotalResults > 0 ? <CertificateSearch certificates={this.state.certificates} doBusyTask={this.doBusyTask} /> : <CertificateOnboarding />);

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout title="Certificates" sectionControl={sectionControl} busy={this.state.busy} errors={this.errors}>
                    <SidebarLayout sideBar={sidebar}>{content}</SidebarLayout>
                </PaperLayout>
            </LibraryLayout>
        );
    }
}
