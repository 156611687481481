/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { Switch } from "react-router-dom";
import { TriggerActionCategory } from "~/client/resources/triggerResource";
import { withPage } from "~/components/Page/Page";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import pageIds from "~/pageIds";
import type { EditDeploymentTriggerRouteProps } from "./EditDeploymentTriggers";
import EditDeploymentTrigger from "./EditDeploymentTriggers";
import type { EditScheduledTriggerRouteProps } from "./Scheduled/EditScheduledTriggers";
import EditScheduledTrigger from "./Scheduled/EditScheduledTriggers";
import Triggers from "./Triggers";

export const ScheduledTriggerPage = withPage({ page: pageIds.project().scheduledTrigger })(EditScheduledTrigger);
export const CreateScheduledTriggerPage = withPage({ page: pageIds.project().scheduledTriggerNew })(EditScheduledTrigger);
export const EditDeploymentTriggerPage = withPage({ page: pageIds.project().deploymentTargetTrigger })(EditDeploymentTrigger);
export const CreateDeploymentTriggerPage = withPage({ page: pageIds.project().deploymentTargetTriggerNew })(EditDeploymentTrigger);
export const TriggersPage = withPage({ page: pageIds.project().triggers })(Triggers);

export class TriggersRoute extends React.Component<any> {
    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute path={`${match.path}/scheduled/edit/:triggerId`} component={ScheduledTriggerPage} />
                <ReloadableRoute path={`${match.path}/scheduled/create`} render={(routeProps: EditScheduledTriggerRouteProps) => <CreateScheduledTriggerPage create triggerActionCategory={TriggerActionCategory.Deployment} {...routeProps} />} />
                <ReloadableRoute path={`${match.path}/edit/:triggerId`} component={EditDeploymentTriggerPage} />
                <ReloadableRoute path={`${match.path}/create`} render={(routeProps: EditDeploymentTriggerRouteProps) => <CreateDeploymentTriggerPage create {...routeProps} />} />
                <ReloadableRoute path={`${match.path}`} component={TriggersPage} />
            </Switch>
        );
    }
}
