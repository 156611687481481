import compact from "lodash/compact";
import keyBy from "lodash/keyBy";
import React from "react";
import type { ProfilerCache, PossiblyLoadedTrackedRequestProfiler, RequestListDataCache, TrackedRequest } from "./types";

export function useProfilerCacheState(trackedRequests: TrackedRequest[]): ProfilerCache {
    const [cacheState, setCacheState] = React.useState<RequestListDataCache>({});

    const fetch = React.useCallback((id: string) => cacheState[id], [cacheState]);

    const getAll = React.useCallback(() => {
        return compact(trackedRequests.map((x) => cacheState[x.profilerId]));
    }, [cacheState, trackedRequests]);

    const has = React.useCallback(
        (id: string) => {
            return Object.prototype.hasOwnProperty.call(cacheState, id) && !!cacheState[id];
        },
        [cacheState]
    );

    const updateAll = React.useCallback((profilers: PossiblyLoadedTrackedRequestProfiler[]) => {
        setCacheState((prev) => ({ ...prev, ...keyBy(profilers, (item) => item.profilerId) }));
    }, []);

    const update = React.useCallback((profiler: PossiblyLoadedTrackedRequestProfiler) => {
        setCacheState((prev) => ({ ...prev, [profiler.profilerId]: profiler }));
    }, []);

    const clear = React.useCallback(() => setCacheState({}), []);

    const cache: ProfilerCache = React.useMemo(
        () => ({
            fetch,
            getAll,
            has,
            updateAll,
            update,
            clear,
        }),
        [fetch, has, update, updateAll, clear, getAll]
    );
    return cache;
}
