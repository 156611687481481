import { flatten } from "lodash";
import type { KeyboardEventHandler } from "react";
import { useCallback } from "react";
import * as React from "react";
import { useLocation, useParams } from "react-router";
import { isUrlActive } from "~/components/Navigation/isUrlActive";
import { useOctopusTheme } from "~/components/Theme/index";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/ThirdPartyIcon/ThirdPartyIcon";
import type { MenuAnchorOrigin } from "~/primitiveComponents/navigation/Menu/CustomMenu";
import { CustomMenu } from "~/primitiveComponents/navigation/Menu/CustomMenu";
import { useMenuState } from "~/primitiveComponents/navigation/Menu/useMenuState";
import { MenuItemButton } from "~/primitiveComponents/navigation/MenuItems/MenuItemButton/MenuItemButton";
import { SimpleMenuItems } from "~/primitiveComponents/navigation/MenuItems/SimpleMenuItems";
import type { SimpleMenuItem } from "~/primitiveComponents/navigation/MenuItems/SimpleMenuItems";
import styles from "./NestedMenu.module.less";
const keycode = require("keycode");

interface NestedMenuProps {
    label: string;
    // For now, we only support nested menus with simple menu items
    // This makes it easier for us to know if any descendant links are active
    childMenuItems: SimpleMenuItem[];
    autoFocus?: boolean;
    onClose?: () => void;
    compact?: boolean;
}

export function NestedMenu({ childMenuItems, label, onClose, autoFocus, compact }: NestedMenuProps) {
    const { spaceId } = useParams<{ spaceId: string }>();
    const location = useLocation();
    const theme = useOctopusTheme();
    const isActive = hasActiveDescendants(location.pathname, spaceId, childMenuItems);
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const closeMenu = menuState.onClose;
    const close = useCallback(() => {
        onClose?.();
        closeMenu();
    }, [closeMenu, onClose]);
    const onKeyDown = useCallback<KeyboardEventHandler>(
        (ev) => {
            // We want to stop propagation so that navigation keydown events (like arrow up/down or home/end)
            // don't bubble up to the parent menu, causing focus to change for that menu too.
            // Normally, the modal also handles escape events, but we also don't want these to bubble up,
            // and instead want to let our onClose call chain handle closing of all the nested menus,
            // so that we have a single way of closing menus
            ev.stopPropagation();

            if (keycode(ev) === "esc") {
                close();
            }
        },
        [close]
    );

    return (
        <>
            <MenuItemButton isSelected={isActive} onClick={openMenu} autoFocus={autoFocus} compact={compact} menuButtonAttributes={buttonAriaAttributes}>
                <div className={styles.buttonContainer}>
                    <div className={styles.text}>{label}</div>
                    <ThirdPartyIcon iconType={ThirdPartyIconType.ArrowRight} color={theme.primaryText} />
                </div>
            </MenuItemButton>
            <CustomMenu menuId={menuState.menuId} onClose={close} isOpen={menuState.isOpen} anchorElement={menuState.anchorElement} anchorOrigin={anchorOrigin} onKeyDown={onKeyDown} accessibleName={label}>
                <SimpleMenuItems items={childMenuItems} onClose={close} autoFocus={true} compact={compact} />
            </CustomMenu>
        </>
    );
}

const anchorOrigin: MenuAnchorOrigin = { horizontal: "right", vertical: "top" };

function hasActiveDescendants(pathname: string, spaceId: string, children: SimpleMenuItem[]): boolean {
    const allLinks = flatten(
        children.map((childMenuItem) => {
            if (childMenuItem.type === "internal-link") {
                return [childMenuItem];
            }
            return [];
        })
    );
    return allLinks.some((l) => isUrlActive(pathname, spaceId, l.path, l.showAsActive === "if path matches exactly"));
}
