import type { SensitiveValue } from "@octopusdeploy/step-inputs";
import type { PathToInput, ObjectRuntimeInputs } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessorForDeploymentTarget, getPathToInput, toResourceSensitiveValue, toRuntimeSensitiveValue } from "@octopusdeploy/step-runtime-inputs";
import type { SensitiveTextComponent } from "@octopusdeploy/step-ui";
import React from "react";
import { Note } from "~/components/StepPackageEditor/Inputs/Note/Note";
import { Sensitive } from "~/components/form";

interface SensitiveProps<StepInputs> {
    configuredStepUIProps: SensitiveTextComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (path: PathToInput) => string;
}

export function DeploymentTargetSensitive<StepInputs>(props: SensitiveProps<StepInputs>) {
    const { input, label, note } = props.configuredStepUIProps;
    const inputAccessor = createInputValueAccessorForDeploymentTarget<StepInputs, SensitiveValue>(input);
    const inputPath = getPathToInput(input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const value = toResourceSensitiveValue(inputValue);
    return (
        <>
            <Sensitive
                value={value}
                onChange={(newValue) => {
                    const newInputValue = toRuntimeSensitiveValue(newValue);
                    const updatedInputs = inputAccessor.changeInputValue(props.inputs, newInputValue);
                    props.setInputs(updatedInputs);
                }}
                label={label}
                error={props.getFieldError(inputPath)}
            />
            <Note note={note} />
        </>
    );
}
