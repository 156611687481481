import CalamariNeedsUpgradeSshIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-CalamariNeedsUpgrade.Ssh.svg";
import CalamariNeedsUpgradeTentacleActiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-CalamariNeedsUpgrade.TentacleActive.svg";
import CalamariNeedsUpgradeTentaclePassiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-CalamariNeedsUpgrade.TentaclePassive.svg";
import DefaultAzureCloudServiceIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Default.AzureCloudService.svg";
import DefaultAzureServiceFabricClusterIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Default.AzureServiceFabricCluster.svg";
import DefaultAzureWebAppIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Default.AzureWebApp.svg";
import DefaultKubernetesIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Default.Kubernetes.svg";
import DefaultNoneIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Default.None.svg";
import DefaultOfflineDropIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Default.OfflineDrop.svg";
import DefaultSshIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Default.Ssh.svg";
import DefaultTentacleActiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Default.TentacleActive.svg";
import DefaultTentaclePassiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Default.TentaclePassive.svg";
import DisabledAzureCloudServiceIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Disabled.AzureCloudService.svg";
import DisabledAzureServiceFabricClusterIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Disabled.AzureServiceFabricCluster.svg";
import DisabledAzureWebAppIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Disabled.AzureWebApp.svg";
import DisabledKubernetesIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Disabled.Kubernetes.svg";
import DisabledNoneIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Disabled.None.svg";
import DisabledOfflineDropIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Disabled.OfflineDrop.svg";
import DisabledSshIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Disabled.Ssh.svg";
import DisabledTentacleActiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Disabled.TentacleActive.svg";
import DisabledTentaclePassiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Disabled.TentaclePassive.svg";
import HasWarningsSshIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-HasWarnings.Ssh.svg";
import HasWarningsTentacleActiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-HasWarnings.TentacleActive.svg";
import HasWarningsTentaclePassiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-HasWarnings.TentaclePassive.svg";
import HealthyAzureCloudServiceIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Healthy.AzureCloudService.svg";
import HealthyAzureServiceFabricClusterIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Healthy.AzureServiceFabricCluster.svg";
import HealthyAzureWebAppIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Healthy.AzureWebApp.svg";
import HealthyKubernetesIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Healthy.Kubernetes.svg";
import HealthyNoneIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Healthy.None.svg";
import HealthyOfflineDropIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Healthy.OfflineDrop.svg";
import HealthySshIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Healthy.Ssh.svg";
import HealthyTentacleActiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Healthy.TentacleActive.svg";
import HealthyTentaclePassiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Healthy.TentaclePassive.svg";
import NeedsUpgradeTentacleActiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-NeedsUpgrade.TentacleActive.svg";
import NeedsUpgradeTentaclePassiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-NeedsUpgrade.TentaclePassive.svg";
import UnavailableAzureCloudServiceIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unavailable.AzureCloudService.svg";
import UnavailableAzureServiceFabricClusterIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unavailable.AzureServiceFabricCluster.svg";
import UnavailableAzureWebAppIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unavailable.AzureWebApp.svg";
import UnavailableKubernetesIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unavailable.Kubernetes.svg";
import UnavailableOfflineDropIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unavailable.OfflineDrop.svg";
import UnavailableSshIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unavailable.Ssh.svg";
import UnavailableTentacleActiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unavailable.TentacleActive.svg";
import UnavailableTentaclePassiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unavailable.TentaclePassive.svg";
import UnhealthyAzureCloudServiceIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unhealthy.AzureCloudService.svg";
import UnhealthyAzureServiceFabricClusterIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unhealthy.AzureServiceFabricCluster.svg";
import UnhealthyAzureWebAppIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unhealthy.AzureWebApp.svg";
import UnhealthyKubernetesIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unhealthy.Kubernetes.svg";
import UnhealthyOfflineDropIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unhealthy.OfflineDrop.svg";
import UnhealthySshIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unhealthy.Ssh.svg";
import UnhealthyTentacleActiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unhealthy.TentacleActive.svg";
import UnhealthyTentaclePassiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unhealthy.TentaclePassive.svg";
import UnknownAzureCloudServiceIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unknown.AzureCloudService.svg";
import UnknownAzureServiceFabricClusterIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unknown.AzureServiceFabricCluster.svg";
import UnknownAzureWebAppIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unknown.AzureWebApp.svg";
import UnknownKubernetesIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unknown.Kubernetes.svg";
import UnknownNoneIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unknown.None.svg";
import UnknownOfflineDropIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unknown.OfflineDrop.svg";
import UnknownSshIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unknown.Ssh.svg";
import UnknownTentacleActiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unknown.TentacleActive.svg";
import UnknownTentaclePassiveIconPath from "~/areas/infrastructure/components/MachineStatusIcons/Server-Unknown.TentaclePassive.svg";
import { CommunicationStyle, MachineModelHealthStatus } from "~/client/resources";
import type { MachineResource } from "~/client/resources";
import { client } from "~/clientInstance";
import EndpointsHelper from "~/utils/EndpointsHelper/EndpointsHelper";
import { exhaustiveCheck } from "~/utils/exhaustiveCheck";

export function getCommunicationStyleIconUrl(communicationStyle: Exclude<CommunicationStyle, CommunicationStyle.StepPackage>): string {
    return machineStatusIcons[communicationStyle].defaultIcon;
}

export function getMachineIconUrl({ HealthStatus, IsDisabled, Endpoint, HasLatestCalamari }: MachineResource): string {
    const { CommunicationStyle: communicationStyle } = Endpoint;
    if (communicationStyle === CommunicationStyle.StepPackage) {
        return client.resolve(Endpoint.Links.Logo);
    }
    if (IsDisabled) {
        return machineStatusIcons[communicationStyle].disabled;
    }
    if (HealthStatus === MachineModelHealthStatus.Unknown) {
        return machineStatusIcons[communicationStyle].unknown;
    }
    if (communicationStyle === CommunicationStyle.None) {
        if (HealthStatus === MachineModelHealthStatus.Healthy) return machineStatusIcons[communicationStyle].healthy;
        throw new Error(`Unexpected machine icon scenario with communication style ${communicationStyle} and health status ${HealthStatus}`);
    }
    if (HealthStatus === MachineModelHealthStatus.Unavailable) {
        return machineStatusIcons[communicationStyle].unavailable;
    }
    if (HealthStatus === MachineModelHealthStatus.Unhealthy) {
        return machineStatusIcons[communicationStyle].unhealthy;
    }
    if (EndpointsHelper.isTentacle(Endpoint)) {
        const tentacleDetails = Endpoint.TentacleVersionDetails;
        if (tentacleDetails.UpgradeSuggested && !tentacleDetails.UpgradeLocked) {
            if (communicationStyle === CommunicationStyle.TentacleActive || communicationStyle === CommunicationStyle.TentaclePassive) {
                return machineStatusIcons[communicationStyle].needsUpgrade;
            }
            throw new Error(`Unexpected machine icon scenario with tentacle endpoint that needs upgrade, communication style ${communicationStyle}, and health status ${HealthStatus}`);
        }
    } else if (!HasLatestCalamari) {
        if (communicationStyle === CommunicationStyle.TentacleActive || communicationStyle === CommunicationStyle.TentaclePassive || communicationStyle === CommunicationStyle.Ssh) {
            return machineStatusIcons[communicationStyle].calamariNeedsUpgrade;
        }
        throw new Error(`Unexpected machine icon scenario with endpoint that doesn't have latest calamari, communication style ${communicationStyle}, and health status ${HealthStatus}`);
    }
    if (HealthStatus === MachineModelHealthStatus.HasWarnings) {
        if (communicationStyle === CommunicationStyle.TentacleActive || communicationStyle === CommunicationStyle.TentaclePassive || communicationStyle === CommunicationStyle.Ssh) {
            return machineStatusIcons[communicationStyle].hasWarnings;
        }
        throw new Error(`Unexpected machine icon scenario with communication style ${communicationStyle}, and health status ${HealthStatus}`);
    }
    if (HealthStatus === MachineModelHealthStatus.Healthy) {
        return machineStatusIcons[communicationStyle].healthy;
    }
    exhaustiveCheck(HealthStatus, "Health statuses not recognised");
}

const machineStatusIcons = {
    None: {
        disabled: DisabledNoneIconPath,
        unknown: UnknownNoneIconPath,
        healthy: HealthyNoneIconPath,
        defaultIcon: DefaultNoneIconPath,
    },
    TentaclePassive: {
        disabled: DisabledTentaclePassiveIconPath,
        unknown: UnknownTentaclePassiveIconPath,
        unavailable: UnavailableTentaclePassiveIconPath,
        unhealthy: UnhealthyTentaclePassiveIconPath,
        needsUpgrade: NeedsUpgradeTentaclePassiveIconPath,
        calamariNeedsUpgrade: CalamariNeedsUpgradeTentaclePassiveIconPath,
        hasWarnings: HasWarningsTentaclePassiveIconPath,
        healthy: HealthyTentaclePassiveIconPath,
        defaultIcon: DefaultTentaclePassiveIconPath,
    },
    TentacleActive: {
        disabled: DisabledTentacleActiveIconPath,
        unknown: UnknownTentacleActiveIconPath,
        unavailable: UnavailableTentacleActiveIconPath,
        unhealthy: UnhealthyTentacleActiveIconPath,
        needsUpgrade: NeedsUpgradeTentacleActiveIconPath,
        calamariNeedsUpgrade: CalamariNeedsUpgradeTentacleActiveIconPath,
        hasWarnings: HasWarningsTentacleActiveIconPath,
        healthy: HealthyTentacleActiveIconPath,
        defaultIcon: DefaultTentacleActiveIconPath,
    },
    Ssh: {
        disabled: DisabledSshIconPath,
        unknown: UnknownSshIconPath,
        unavailable: UnavailableSshIconPath,
        unhealthy: UnhealthySshIconPath,
        calamariNeedsUpgrade: CalamariNeedsUpgradeSshIconPath,
        hasWarnings: HasWarningsSshIconPath,
        healthy: HealthySshIconPath,
        defaultIcon: DefaultSshIconPath,
    },
    OfflineDrop: {
        disabled: DisabledOfflineDropIconPath,
        unknown: UnknownOfflineDropIconPath,
        unavailable: UnavailableOfflineDropIconPath,
        unhealthy: UnhealthyOfflineDropIconPath,
        healthy: HealthyOfflineDropIconPath,
        defaultIcon: DefaultOfflineDropIconPath,
    },
    AzureWebApp: {
        disabled: DisabledAzureWebAppIconPath,
        unknown: UnknownAzureWebAppIconPath,
        unavailable: UnavailableAzureWebAppIconPath,
        unhealthy: UnhealthyAzureWebAppIconPath,
        healthy: HealthyAzureWebAppIconPath,
        defaultIcon: DefaultAzureWebAppIconPath,
    },
    AzureCloudService: {
        disabled: DisabledAzureCloudServiceIconPath,
        unknown: UnknownAzureCloudServiceIconPath,
        unavailable: UnavailableAzureCloudServiceIconPath,
        unhealthy: UnhealthyAzureCloudServiceIconPath,
        healthy: HealthyAzureCloudServiceIconPath,
        defaultIcon: DefaultAzureCloudServiceIconPath,
    },
    AzureServiceFabricCluster: {
        disabled: DisabledAzureServiceFabricClusterIconPath,
        unknown: UnknownAzureServiceFabricClusterIconPath,
        unavailable: UnavailableAzureServiceFabricClusterIconPath,
        unhealthy: UnhealthyAzureServiceFabricClusterIconPath,
        healthy: HealthyAzureServiceFabricClusterIconPath,
        defaultIcon: DefaultAzureServiceFabricClusterIconPath,
    },
    Kubernetes: {
        disabled: DisabledKubernetesIconPath,
        unknown: UnknownKubernetesIconPath,
        unavailable: UnavailableKubernetesIconPath,
        unhealthy: UnhealthyKubernetesIconPath,
        healthy: HealthyKubernetesIconPath,
        defaultIcon: DefaultKubernetesIconPath,
    },
} as const;
