import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import URI from "urijs";

export enum QueryStateMode {
    PushHistory = "PushHistory",
    ReplaceHistory = "ReplaceHistory",
}

export const useQueryStringParam = (queryParameterName: string, mode: QueryStateMode = QueryStateMode.PushHistory): [string | null, (newValue: string) => void] => {
    const { push, replace } = useHistory();
    const { search, pathname } = useLocation();

    const params = new URLSearchParams(search);
    const value = params.get(queryParameterName);

    const updateQueryParamValue = useCallback(
        (newParamValue: string) => {
            if (newParamValue !== value) {
                const url = new URI(pathname);
                url.setQuery(queryParameterName, newParamValue);
                const newUrl = url.toString();
                if (mode === QueryStateMode.PushHistory) push(newUrl);
                else {
                    replace(newUrl);
                }
            }
        },
        [value, pathname, queryParameterName, mode, push, replace]
    );

    return [value, updateQueryParamValue];
};
