import * as React from "react";
import type { CloudRegionEndpointResource } from "~/client/resources/endpointResource";
import { AzureCloudRegion } from "~/components/Images/MachineSettings/AzureCloudRegion";
import { ExpandableFormSection, Summary, Select } from "~/components/form";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper";
import type { WorkerPoolResource } from "../../../../../client/resources/index";
import cloudRegionCategory from "./CloudRegionCategoryDefinition";
import EndpointCard from "./EndpointCard";
import type { CategorizedEndpointRegistration } from "./endpointRegistry";
import { CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
import styles from "./styles.module.less";

interface CloudRegionEndpointProps {
    endpoint: CloudRegionEndpointResource;
    workerPools: WorkerPoolResource[];
    onChange(newValue: CloudRegionEndpointResource): void;
}

class CloudRegionEndpoint extends React.Component<CloudRegionEndpointProps> {
    constructor(props: CloudRegionEndpointProps) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.workerPools.length > 1 && (
                    <ExpandableFormSection
                        errorKey={"DefaultWorkerPool"}
                        title="Worker Pool"
                        summary={this.props.endpoint.DefaultWorkerPoolId ? CommonSummaryHelper.resourceSummary(this.props.endpoint.DefaultWorkerPoolId, this.props.workerPools, "worker pool") : Summary.placeholder("No pool selected - default pool")}
                        help="Select a default pool for this target (optional)."
                    >
                        <Select
                            label={"Select a default pool"}
                            items={this.props.workerPools.map((e) => ({ value: e.Id, text: e.Name }))}
                            value={this.props.endpoint.DefaultWorkerPoolId}
                            allowFilter={true}
                            allowClear={true}
                            onChange={(x) => this.props.onChange({ ...this.props.endpoint, DefaultWorkerPoolId: x })}
                            sortItems={false}
                        />
                    </ExpandableFormSection>
                )}
            </div>
        );
    }
}

export default CloudRegionEndpoint;

const cloudRegionEndpointRegistration: CategorizedEndpointRegistration = {
    key: EndpointRegistrationKey.CloudRegion,
    displayOrder: 100,
    communicationStyle: CommunicationStyle.None,
    name: "Cloud Region",
    categories: [cloudRegionCategory],
    renderCard: ({ registration, category, onNavigate }) => (
        <EndpointCard
            logo={<AzureCloudRegion className={styles.centreThumbnail} title={registration.name} />}
            registrationName={registration.name}
            description="Execute steps multiple times with variables scoped for each iteration."
            onNavigate={onNavigate}
        />
    ),
};

export { cloudRegionEndpointRegistration };
