import * as React from "react";
import DeletableChip from "~/components/Chips/DeletableChip";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";

interface TypeaheadProps<T> extends FormFieldProps<T> {
    items: SelectItem[];
    fieldName?: string;
    addNewTemplate: (i: string) => string;
    onNew: (e: string) => void;
    openOnFocus?: boolean;
    autoFocus?: boolean;
    chipRenderer?: (r: SelectItem, onRequestDelete: () => void) => React.ReactElement;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    accessibleName?: string;
    validate?(value: string[]): string;
}

// TODO Config-as-code: Building a Typeahead component by using the Multiselect component (restricted to a single-select)
// is like fitting a square peg in a round hole. We went this way as we want to have a consistent UX when it comes to typing-ahead
// similar to the Role selector in the Process Editor (which uses a Multiselect). We have added a card to Config-as-code board
// to revisit this next cycle to separate the typeahead UX from the Multiselect component.
// Related: https://trello.com/c/CahXDEFq/243-refactor-the-typeahead-component-to-not-use-the-multiselect-component

const ReferencedDataMultiselect = MultiSelect<SelectItem>();

const Typeahead: React.FC<TypeaheadProps<string>> = (props) => {
    const onChange = (value: string[]) => {
        props.onChange(value[value.length - 1]);
    };

    const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => {
        return (
            <DeletableChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${r.Name}`}>
                {r.Name}
            </DeletableChip>
        );
    };

    return (
        <ReferencedDataMultiselect
            items={props.items.map((i) => ({ Id: i.Id, Name: i.Name }))}
            fieldName={props.fieldName}
            label={props.label}
            renderChip={props.chipRenderer ?? chipRenderer}
            value={props.value === "" ? [] : [props.value]}
            onNew={props.onNew}
            onChange={(value) => onChange(value)}
            error={props.error}
            openOnFocus={props.openOnFocus}
            autoFocus={props.autoFocus}
            hideFloatingLabel={props.hideFloatingLabel}
            addNewTemplate={props.addNewTemplate}
            accessibleName={props.accessibleName}
        />
    );
};

export const TypeaheadMultiSelector: React.FC<TypeaheadProps<string[]>> = (props) => {
    const onChange = (value: string[]) => {
        props.onChange(value);
    };

    const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => {
        return (
            <DeletableChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${r.Name}`}>
                {r.Name}
            </DeletableChip>
        );
    };

    return (
        <ReferencedDataMultiselect
            items={props.items}
            fieldName={props.fieldName}
            label={props.label}
            renderChip={props.chipRenderer ?? chipRenderer}
            value={props.value}
            onNew={props.onNew}
            onChange={(value) => onChange(value)}
            error={props.error}
            openOnFocus={props.openOnFocus}
            autoFocus={props.autoFocus}
            hideFloatingLabel={props.hideFloatingLabel}
            addNewTemplate={props.addNewTemplate}
            accessibleName={props.accessibleName}
        />
    );
};

export default Typeahead;
