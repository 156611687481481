import React from "react";
import type { FeatureToggle } from "~/client/resources/enabledFeatureToggles";
import { session } from "~/clientInstance";
import FormComponent from "~/components/FormComponent/FormComponent";
import { Checkbox, Text } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import styles from "./style.module.less";

interface AvailableToggles {
    [name: string]: boolean;
}

interface ToggleControlState {
    AvailableToggles: AvailableToggles;
    NewToggleName: string;
}

class ToggleControls<Props = {}> extends React.Component<Props, ToggleControlState> {
    constructor(props: Props) {
        super(props);

        if (!session.featureToggles) {
            return;
        }

        const availableToggles: { [key: string]: boolean } = {};
        for (const toggleName of session.featureToggles) {
            availableToggles[toggleName] = true;
        }

        this.state = {
            NewToggleName: "",
            AvailableToggles: availableToggles,
        };
    }

    setToggleState(name: string, enabled: boolean) {
        const updatedToggles = { ...this.state.AvailableToggles };
        updatedToggles[name] = enabled;

        this.setState({ AvailableToggles: updatedToggles });
        this.updateGlobalToggleState(updatedToggles);
    }

    addAvailableToggle() {
        if (this.state.NewToggleName !== null && this.state.NewToggleName !== "") {
            const toggles = { ...this.state.AvailableToggles };
            toggles[this.state.NewToggleName] = false;

            this.setState({
                AvailableToggles: toggles,
                NewToggleName: "",
            });

            this.updateGlobalToggleState(toggles);
        }
    }

    updateGlobalToggleState(toggles: AvailableToggles) {
        const newToggles: FeatureToggle[] = [];

        for (const name in toggles) {
            if (toggles[name]) {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                newToggles.push(name as FeatureToggle);
            }
        }

        session.featureToggles = newToggles;
    }

    renderToggles(toggles: AvailableToggles) {
        const toggleRows = [];

        for (const name in toggles) {
            const isEnabled = toggles[name];
            toggleRows.push(
                <div className={styles.toggleRow} key={name}>
                    <Checkbox label={name} value={isEnabled} onChange={(enabled) => this.setToggleState(name, enabled)} />
                </div>
            );
        }

        return toggleRows;
    }

    render() {
        const currentToggles = this.renderToggles(this.state.AvailableToggles);

        return (
            <div className={styles.toggleContainer}>
                <Callout title="Feature toggle controls" type={CalloutType.Warning}>
                    This only controls the toggle values in the UI (not the values on the Server)
                </Callout>
                {currentToggles}
                <FormComponent onFormSubmit={() => this.addAvailableToggle()}>
                    <Text label={"Add toggle"} value={this.state.NewToggleName} onChange={(NewToggleName) => this.setState({ NewToggleName })} hideUnderline={true} />
                </FormComponent>
            </div>
        );
    }
}

export default ToggleControls;
