import React from "react";
import type { IconSize } from "./IconSize";
import { getIconSize } from "./getIconSize";
import styles from "./styles.module.less";

export interface UnknownMachineIconProps {
    iconSize?: IconSize;
    title?: string;
}

export function UnknownMachineIcon({ title = "Unknown", iconSize }: UnknownMachineIconProps) {
    return (
        <svg {...getIconSize(iconSize)} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" className={styles.healthStatusIcon}>
            <title>{title}</title>
            <g fill="none" fillRule="evenodd">
                <circle cx="8" cy="8" r="8" fill="#9E9E9E" />
                <path
                    d="M10.36274 7.31506c.19916-.210222.356823-.4481.472998-.713644.116176-.265544.174262-.54768.174262-.846416 0-.497894-.12447-.957056-.373418-1.3775-.248947-.420444-.583638-.755134-1.004082-1.00408C9.212056 3.12447 8.752894 3 8.255 3c-.497894 0-.957056.124472-1.3775.37342-.420444.248946-.755134.583636-1.00408 1.00408C5.62447 4.797944 5.5 5.257106 5.5 5.755h1.360904c0-.376187.138302-.70258.41491-.979187.276607-.276607.603-.41491.979186-.41491.376187 0 .70258.138303.979187.41491.276608.276608.41491.597468.41491.96259 0 .365123-.138302.697047-.41491.995784L8.38777 7.5972c-.254478.276607-.4481.57534-.580872.896204-.1549.342994-.23235.691514-.23235 1.045572v.36512h1.360904c0-.475765.07745-.868542.23235-1.178343.099578-.199157.2932-.453633.580873-.763434l.614066-.64726zm-1.427288 5.344037v-1.360904H7.574548v1.360904h1.360904z"
                    stroke="#FFF"
                    strokeWidth=".5"
                    fill="#FFF"
                />
            </g>
        </svg>
    );
}
