import * as React from "react";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import { BaseComponent } from "../BaseComponent/BaseComponent";

interface OpenConfirmActionDialogMenuItemProps {
    dialogTitle: string;
    onConfirmActionClick(): Promise<void> | void;
    acceptOnClick?: (onClick: () => void) => void;
}

interface OpenConfirmActionDialogMenuItemState {
    open: boolean;
}

export default class OpenConfirmActionDialogMenuItem extends BaseComponent<OpenConfirmActionDialogMenuItemProps, OpenConfirmActionDialogMenuItemState> {
    constructor(props: OpenConfirmActionDialogMenuItemProps) {
        super(props);
        this.state = { open: false };
    }

    onClick = () => {
        this.setState({ open: true });
    };

    render() {
        if (this.props.acceptOnClick) {
            this.props.acceptOnClick(this.onClick);
        }

        return (
            <ConfirmationDialog
                title={this.props.dialogTitle}
                open={this.state.open}
                onClose={() => this.setState({ open: false })}
                onContinueClick={() => {
                    this.props.onConfirmActionClick();
                    this.setState({ open: false });
                }}
            />
        );
    }
}
