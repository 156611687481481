import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationSpaceOverview, CommonConfigurationSpaceTopics } from "../../CommonComponents/CommonConfigurationSpaceViews";

export const ConfigurationSpacesNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.spaces.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationSpaceOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationSpaceTopics />;
    },
};
