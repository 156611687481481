import React from "react";
import { useSelector } from "react-redux";
import { client } from "../../../../clientInstance";
import type { DoBusyTask } from "../../../DataBaseComponent";
import { pageConfigSelector } from "../../../PageLayout/reducers/pageLayoutArea";
import type { IProfiler } from "../types";
import type { TrackedRequestProfilerRequest, ProfilerCache, TrackedRequest } from "./types";

export interface ProfileLoadingOptions {
    doBusyTask: DoBusyTask;
    trackedRequests: TrackedRequest[];
    enabled: boolean;
    cache: ProfilerCache;
}

export function useProfilerLoadingEffect({ doBusyTask, cache, enabled, trackedRequests }: ProfileLoadingOptions) {
    const { page } = useSelector(pageConfigSelector);
    const { has, updateAll } = cache;

    React.useEffect(() => {
        doBusyTask(async () => {
            if (!enabled) {
                return;
            }

            const uncachedRequests = trackedRequests.filter((x) => !has(x.profilerId));

            if (uncachedRequests.length === 0) {
                return;
            }

            const requestsToCache: TrackedRequestProfilerRequest[] = uncachedRequests.map((trackedRequest) => {
                return {
                    type: "unresolved-request",
                    promise: client.get<IProfiler>(`/mini-profiler/results?id=${trackedRequest.profilerId}`),
                    profilerId: trackedRequest.profilerId,
                    url: trackedRequest.originalRequestUrl,
                    page,
                };
            });

            updateAll(requestsToCache);

            const resolvedRequests = await Promise.all(requestsToCache.map((x) => x.promise));

            updateAll(
                resolvedRequests.map((profiler) => ({
                    type: "resolved-request",
                    profilerId: profiler.Id,
                    url: profiler.Root.Name,
                    profiler,
                    page,
                }))
            );
        });
    }, [has, updateAll, trackedRequests, doBusyTask, enabled, page]);
}
