import { pageId } from "~/utils/pageId";

export default () => ({
    runbookSnapshots: pageId({ area: "Project", id: "Project.RunbookSnapshots", name: "RunbookSnapshots", routeLink: null }),
    runbookSnapshotCreate: pageId({ area: "Project", id: "Project.RunbookSnapshotNew", name: "Add New RunbookSnapshot", routeLink: null }),
    runbookSnapshot: () => {
        return {
            root: pageId({ area: "Project", id: "Project.RunbookSnapshot", name: "RunbookSnapshot", routeLink: null }),
            edit: pageId({ area: "Project", id: "Project.RunbookSnapshotEdit", name: "Edit RunbookSnapshot", routeLink: null }),
            runbookRuns: {
                specific: pageId({ area: "Project", id: "Project.RunbookRun", name: "RunbookRun", routeLink: null }),
                retry: pageId({ area: "Project", id: "Project.RunbookRunRetry", name: "Retry RunbookRun", routeLink: null }),
                create: pageId({ area: "Project", id: "Project.RunbookRunNew", name: "Add New RunbookRun", routeLink: null }),
            },
        };
    },
});
