import * as React from "react";
import pageIds from "~/pageIds";
import ExternalLink from "../../../../../Navigation/ExternalLink";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "../../CommonComponents/CommonViews";

export const ProjectImportRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.importExport.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (
            <OverviewContainer>
                <OverviewHighlightText>
                    <p>
                        Projects can be exported and imported into a new space. Please read the <ExternalLink href="ProjectExportImport">docs</ExternalLink> before beginning, to ensure all factors have been considered.
                    </p>
                    <p>This page displays the import and export tasks that have recently been run.</p>
                </OverviewHighlightText>
            </OverviewContainer>
        );
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return (
            <TopicsContainer>
                <LearnMoreResources>
                    <div>
                        <ExternalLink href="ProjectExportImport">Exporting and Importing Projects</ExternalLink>
                    </div>
                </LearnMoreResources>
            </TopicsContainer>
        );
    },
};
