import { useState } from "react";
import * as React from "react";
import LifecycleMap from "~/areas/library/components/Lifecycle/LifecycleMap/index";
import type { ResourcesById } from "~/client/repositories/basicRepository";
import type { EnvironmentResource } from "~/client/resources/environmentResource";
import type { LifecycleResource } from "~/client/resources/lifecycleResource";
import type { ProjectGroupResource } from "~/client/resources/projectGroupResource";
import { ActionButton, ActionButtonType } from "~/components/Button/index";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { MarkdownEditor, Select } from "~/components/form/index";
import Note from "~/primitiveComponents/form/Note/Note";
import routeLinks from "~/routeLinks";
import styles from "./style.module.less";

type AdvancedProjectSectionProps = {
    description: string;
    onDescriptionChanged: (newValue: string) => void;

    projectGroups: ProjectGroupResource[];
    projectGroupId: string | undefined;
    onProjectGroupChanged: (newValue: string | undefined) => void;

    lifecycles: LifecycleResource[] | undefined;
    selectedLifecycle: LifecycleResource | undefined;
    onLifecycleChanged: (newValue: string | undefined) => void;
    environmentsById: ResourcesById<EnvironmentResource>;
};

const AdvancedProjectSection = (props: AdvancedProjectSectionProps) => {
    const [showLifecycleMap, setUseShowLifecycleMap] = useState(false);

    return (
        <React.Fragment>
            <MarkdownEditor label="Project description" value={props.description} onChange={props.onDescriptionChanged} />
            <Select value={props.projectGroupId} onChange={props.onProjectGroupChanged} items={props.projectGroups.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Project group" />

            {props.lifecycles && (
                <>
                    <Select value={props.selectedLifecycle ? props.selectedLifecycle.Id : ""} onChange={props.onLifecycleChanged} items={props.lifecycles.map((l) => ({ value: l.Id, text: l.Name }))} label="Lifecycle" />
                    <Note>
                        Lifecycles determine which environments the project can be deployed to, and the promotion rules between those environments.{" "}
                        <InternalLink to={routeLinks.library.lifecycles} openInSelf={false}>
                            Create or modify lifecycles
                        </InternalLink>
                    </Note>

                    {Object.keys(props.environmentsById).length > 0 && <ActionButton type={ActionButtonType.Ternary} label={showLifecycleMap ? "Hide lifecycle" : "Show lifecycle"} onClick={() => setUseShowLifecycleMap(!showLifecycleMap)} />}
                    {showLifecycleMap && (
                        <div>
                            {props.selectedLifecycle && (
                                <div className={styles.lifecycleMap}>
                                    <LifecycleMap environmentsById={props.environmentsById} lifecyclePreview={props.selectedLifecycle} />
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </React.Fragment>
    );
};

export default AdvancedProjectSection;
