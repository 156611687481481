import * as React from "react";
import type { AzureCloudServiceEndpointResource, AccountResource } from "~/client/resources";
import { AzureCloudServiceEndpointDeploymentSlot } from "~/client/resources/endpointResource";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { AzureCloudService } from "~/components/Images/MachineSettings/AzureCloudService";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { ExpandableFormSection, FormSectionHeading, Summary, Note } from "~/components/form";
import AccountSelect from "~/components/form/AccountSelect/AccountSelect";
import RadioButton from "~/primitiveComponents/form/RadioButton/RadioButton";
import RadioButtonGroup, { BooleanRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/RadioButtonGroup";
import Select from "~/primitiveComponents/form/Select/Select";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper";
import { AccountType } from "../../../../../client/resources/index";
import type { WorkerPoolResource } from "../../../../../client/resources/index";
import Text from "../../../../../primitiveComponents/form/Text/Text";
import AzureCategory from "./AzureCategoryDefinition";
import EndpointCard from "./EndpointCard";
import type { BuiltInEndpointRegistration } from "./endpointRegistry";
import { CommunicationStyle, EndpointRegistrationKey } from "./endpointRegistry";
import styles from "./styles.module.less";

interface AzureCloudServiceEndpointProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | boolean;
    endpoint: AzureCloudServiceEndpointResource;
    accounts: AccountResource[];
    workerPools: WorkerPoolResource[];
    refreshAccounts: () => Promise<{}>;
    onChange(newValue: AzureCloudServiceEndpointResource): void;
    getFieldError(field: string): string;
}

interface AzureCloudServiceEndpointState {
    accountIsBound: boolean;
}

class AzureCloudServiceEndpointDeploymentSlotRadioButtonGroup extends RadioButtonGroup<AzureCloudServiceEndpointDeploymentSlot> {}

class AzureCloudServiceEndpoint extends React.Component<AzureCloudServiceEndpointProps, AzureCloudServiceEndpointState> {
    constructor(props: AzureCloudServiceEndpointProps) {
        super(props);
        this.state = {
            accountIsBound: false,
        };
    }

    render() {
        return (
            <div>
                <ExpandableFormSection
                    errorKey="Account"
                    title="Account"
                    focusOnExpandAll
                    summary={CommonSummaryHelper.resourceSummary(this.props.endpoint.AccountId, this.props.accounts, "account")}
                    help="Select the account to use for the connection."
                >
                    <AccountSelect
                        onRequestRefresh={this.props.refreshAccounts}
                        value={this.props.endpoint.AccountId}
                        type={[AccountType.AzureSubscription]}
                        allowClear={true}
                        onChange={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.AccountId = x;
                            this.props.onChange(endpoint);
                        }}
                        items={this.props.accounts}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey={"CloudServiceName"}
                    title="Azure Cloud Service"
                    summary={this.props.endpoint.CloudServiceName ? Summary.summary(this.props.endpoint.CloudServiceName) : Summary.placeholder("No Cloud Service provided")}
                    help="The name of the cloud service to which files will be deployed."
                >
                    <Text
                        label="Cloud Service Name"
                        value={this.props.endpoint.CloudServiceName}
                        onChange={(name) => {
                            this.props.onChange({ ...this.props.endpoint, CloudServiceName: name });
                        }}
                    />
                    <Note>The Cloud Service Name provided must exactly match the Azure Cloud Service resource being targeted.</Note>
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey={"StorageAccountName"}
                    title="Azure Storage Account"
                    summary={this.props.endpoint.StorageAccountName ? Summary.summary(this.props.endpoint.StorageAccountName) : Summary.placeholder("No Storage Account provided")}
                    help="The name of a storage account that CSPKG files will be uploaded to before deployment."
                >
                    <Text
                        label="Storage Account Name"
                        value={this.props.endpoint.StorageAccountName}
                        onChange={(name) => {
                            this.props.onChange({ ...this.props.endpoint, StorageAccountName: name });
                        }}
                    />
                    <Note>The Storage Account Name provided must exactly match the Azure Storage Account resource being used.</Note>
                </ExpandableFormSection>

                {this.props.workerPools.length > 1 && (
                    <ExpandableFormSection
                        errorKey={"DefaultWorkerPool"}
                        title="Worker Pool"
                        summary={this.props.endpoint.DefaultWorkerPoolId ? CommonSummaryHelper.resourceSummary(this.props.endpoint.DefaultWorkerPoolId, this.props.workerPools, "worker pool") : Summary.placeholder("No pool selected - default pool")}
                        help="Select a default pool for this target (optional)."
                    >
                        <Select
                            label={"Select a default pool"}
                            items={this.props.workerPools.map((e) => ({ value: e.Id, text: e.Name }))}
                            value={this.props.endpoint.DefaultWorkerPoolId}
                            allowFilter={true}
                            allowClear={true}
                            onChange={(x) => this.props.onChange({ ...this.props.endpoint, DefaultWorkerPoolId: x })}
                            sortItems={false}
                        />
                    </ExpandableFormSection>
                )}

                <FormSectionHeading title="Deployment" />

                <ExpandableFormSection errorKey="AzureCloudServiceEndpointDeploymentSlot" title="Slot" summary={this.slotSummary()} help="Select a slot to deploy to.">
                    <Note>
                        Deployment Slots provide a nice way to implement Blue-Green deployments. Learn more about <ExternalLink href="VipSwap">Deployment Slots</ExternalLink>
                    </Note>
                    <AzureCloudServiceEndpointDeploymentSlotRadioButtonGroup
                        value={this.props.endpoint.Slot}
                        onChange={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.Slot = x;
                            this.props.onChange(endpoint);
                        }}
                    >
                        <RadioButton value={AzureCloudServiceEndpointDeploymentSlot.Staging} label={AzureCloudServiceEndpointDeploymentSlot.Staging} isDefault={true} />
                        <RadioButton value={AzureCloudServiceEndpointDeploymentSlot.Production} label={AzureCloudServiceEndpointDeploymentSlot.Production} />
                    </AzureCloudServiceEndpointDeploymentSlotRadioButtonGroup>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="SwapIfPossible" title="Swap" summary={this.swapSummary()} help="Specify to swap staging to production rather than a new deployment.">
                    <BooleanRadioButtonGroup
                        value={this.props.endpoint.SwapIfPossible}
                        onChange={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.SwapIfPossible = x;
                            this.props.onChange(endpoint);
                        }}
                    >
                        <RadioButton value={true} label="Swap staging to production if possible" isDefault={true} />
                        <Note>Azure can swap staging to production deployments by switching virtual IP addresses.</Note>
                        <RadioButton value={false} label="Always deploy" />
                    </BooleanRadioButtonGroup>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="UseCurrentInstanceCount" title="Instance Count" summary={this.instanceCountSummary()} help="Select the source to use the instance count from.">
                    <Note>
                        If you have previously scaled your service by changing the number of instances using the Azure management portal, Octopus can retrieve these values and use them in the deployment, so that the instance count remains unchanged.
                    </Note>
                    <BooleanRadioButtonGroup
                        value={this.props.endpoint.UseCurrentInstanceCount}
                        onChange={(x) => {
                            const endpoint = this.props.endpoint;
                            endpoint.UseCurrentInstanceCount = x;
                            this.props.onChange(endpoint);
                        }}
                    >
                        <RadioButton value={true} label="Use the instance count from the current Azure deployment" isDefault={true} />
                        <RadioButton value={false} label="Use the instance count defined in the service configuration XML file" />
                    </BooleanRadioButtonGroup>
                </ExpandableFormSection>
            </div>
        );
    }

    slotSummary() {
        const slot = this.props.endpoint.Slot;
        if (slot) {
            if (slot === AzureCloudServiceEndpointDeploymentSlot.Staging) {
                return Summary.default(<span>Using the {slot} deployment slot</span>);
            }
            return Summary.summary(<span>Using the {slot} deployment slot</span>);
        }
        return Summary.placeholder("Deployment slot not specified");
    }

    swapSummary() {
        const swap = this.props.endpoint.SwapIfPossible;
        if (swap === true) {
            return Summary.default("When deploying to production swap staging to production, if possible");
        }
        if (swap === false) {
            return Summary.summary("When deploying to production always perform a new deployment");
        }
        return Summary.placeholder("Swap setting not specified");
    }

    instanceCountSummary() {
        const instanceCount = this.props.endpoint.UseCurrentInstanceCount;
        if (instanceCount === true) {
            return Summary.default("Using the instance count from the current Azure deployment");
        }
        if (instanceCount === false) {
            return Summary.summary("Using the instance count defined in the service configuration XML file");
        }
        return Summary.placeholder("Instance count not specified");
    }
}

export default AzureCloudServiceEndpoint;
const azureCloudServiceEndpointRegistration: BuiltInEndpointRegistration = {
    key: EndpointRegistrationKey.AzureCloudService,
    displayOrder: 100,
    categories: [AzureCategory],
    name: "Azure Cloud Service",
    communicationStyle: CommunicationStyle.AzureCloudService,
    renderCard: ({ registration, category, onNavigate }) => (
        <EndpointCard logo={<AzureCloudService className={styles.centreThumbnail} title={registration.name} />} registrationName={registration.name} description="Connect to an existing Azure Cloud Service (legacy)." onNavigate={onNavigate} />
    ),
};

export { azureCloudServiceEndpointRegistration };
