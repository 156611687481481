import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import { OpenDialogConnect } from "~/components/Dialog/OpenDialogConnect";
import type { OpenDialogProps } from "~/components/Dialog/OpenDialogConnect";
import dialogIdGenerator from "~/components/Dialog/dialogIdGenerator";
import type { Icon } from "~/components/IconButton/IconButton";
import IconButton from "~/components/IconButton/IconButton";
import type { ToolTipContent } from "~/primitiveComponents/dataDisplay/ToolTip/ToolTip";
import styles from "./style.module.less";

interface OpenDialogIconButtonProps {
    toolTipContent: ToolTipContent;
    icon: Icon;
    wideDialog?: boolean;
    disabled?: boolean;
}

class OpenDialogIconButtonInternal extends React.Component<OpenDialogIconButtonProps & OpenDialogProps, never> {
    private uniqueId: string;

    constructor(props: OpenDialogIconButtonProps & OpenDialogProps) {
        super(props);

        this.uniqueId = dialogIdGenerator.next();
    }

    render() {
        const isOpen = this.props.openDialogs[this.uniqueId];

        return (
            <div className={styles.buttonDialogContainer}>
                <IconButton icon={this.props.icon} disabled={this.props.disabled} toolTipContent={this.props.toolTipContent} onClick={() => this.props.openDialog(this.uniqueId)} />
                <Dialog open={isOpen} wide={this.props.wideDialog}>
                    {this.props.children}
                </Dialog>
            </div>
        );
    }
}

const OpenDialogIconButton = OpenDialogConnect.to(OpenDialogIconButtonInternal);

export default OpenDialogIconButton;
