import { createContext } from "react";
import * as React from "react";

interface ReloadableRouteIgnoreProps {
    doNotReloadWhenTheseKeysChange: string[];
}

const ReloadableRouteIgnoreContext = createContext<ReloadableRouteIgnoreProps | null>(null);

export const ReloadableRouteConfiguration: React.FC<ReloadableRouteIgnoreProps> = (props) => {
    return <ReloadableRouteIgnoreContext.Provider value={props}>{props.children}</ReloadableRouteIgnoreContext.Provider>;
};

export const useReloadableRouteIgnoreKeys = () => {
    const reloadableRouteIgnoreContext = React.useContext(ReloadableRouteIgnoreContext);
    return reloadableRouteIgnoreContext?.doNotReloadWhenTheseKeysChange ?? null;
};
