/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */

import * as React from "react";
import IconButton, { Icon } from "~/components/IconButton/IconButton";
import { Note } from "~/components/form";
import Text from "~/primitiveComponents/form/Text/Text";
import { noOp } from "~/utils/noOp";
import IconButtonList from "../../IconButtonList/IconButtonList";
import InputWithActions from "../../InputWithActions";
import type FormFieldProps from "../FormFieldProps";
import styles from "./style.module.less";

export interface LogoEditorSettings {
    file?: File;
    reset: boolean;
}

interface LogoEditorProps extends FormFieldProps<LogoEditorSettings> {
    autoFocus?: boolean;
    type?: string;
    placeholder?: string;
    error?: string;
    onValidate?(value: string): void;
}

export default class LogoEditor extends React.Component<LogoEditorProps, {}> {
    input: HTMLInputElement = undefined!;
    constructor(props: LogoEditorProps) {
        super(props);
    }

    handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length < 1) {
            this.onValidate("No file selected");
            return;
        }

        const file = e.target.files[0];
        const ValidImageTypes = ["image/gif", "image/jpeg", "image/png"];
        if (ValidImageTypes.indexOf(file.type) < 0) {
            this.onValidate("Selected file was not an image");
            return;
        }

        this.onValidate("");
        const value: LogoEditorSettings = {
            file,
            reset: false,
        };

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
    onValidate(message: string) {
        if (this.props.onValidate) {
            this.props.onValidate(message);
        }
    }
    useDefault = () => {
        const value: LogoEditorSettings = {
            file: undefined,
            reset: true,
        };
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    clear = () => {
        const value: LogoEditorSettings = {
            file: undefined,
            reset: false,
        };

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    chooseFile = () => {
        this.input.click();
    };

    render() {
        const { value, error, placeholder } = this.props;

        const v = value == null || (value.file == null && !value.reset) ? "Current logo" : value.file ? value.file.name : "Default logo";

        return (
            <div className={styles.logoEditor}>
                <div className={styles.logoContainer}>
                    <InputWithActions
                        input={
                            <div className={styles.logoInput}>
                                <Text type="text" onChange={noOp} placeholder={placeholder} value={v} error={error} onClick={this.chooseFile} />
                            </div>
                        }
                        actions={
                            <IconButtonList
                                buttons={[<IconButton onClick={this.chooseFile} icon={Icon.SelectLogo} toolTipContent="Choose image" />, <IconButton onClick={this.useDefault} icon={Icon.UseDefaultImage} toolTipContent="Use default image" />]}
                            />
                        }
                    />
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.handleFileChange}
                        ref={(input: HTMLInputElement) => {
                            this.input = input;
                        }}
                    />
                </div>
                <Note>A transparent PNG, no larger than 100x100 pixels, is recommended. JPG and gif are also suitable.</Note>
            </div>
        );
    }
}
