import type { Client } from "../client";
import type { NewSubscriptionResource, SubscriptionResource } from "../resources";
import BasicRepository from "./basicRepository";

class SubscriptionRepository extends BasicRepository<SubscriptionResource, NewSubscriptionResource> {
    constructor(client: Client) {
        super("Subscriptions", client);
    }
}

export default SubscriptionRepository;
