import type { ProjectResource } from "~/client/resources/projectResource";
import type { Client } from "../client";
import type { LifecycleResource } from "../resources";
import BasicRepository from "./basicRepository";

class LifecycleRepository extends BasicRepository<LifecycleResource, LifecycleResource> {
    constructor(client: Client) {
        super("Lifecycles", client);
    }
    preview(lifecycle: LifecycleResource): Promise<LifecycleResource> {
        return this.client.get(lifecycle.Links["Preview"]);
    }
    projects(lifecycle: LifecycleResource) {
        return this.client.get<ProjectResource[]>(lifecycle.Links["Projects"]);
    }
}

export default LifecycleRepository;
