import jsSHA from "jssha";

export const TutorialPackageExperimentControlVariation = "tutorial-package-experiment-control";
export const TutorialPackageExperimentNoPackageVariation = "tutorial-package-experiment-no-package";

export function getTutorialPackageExperimentVariation(userId: string | undefined): typeof TutorialPackageExperimentControlVariation | typeof TutorialPackageExperimentNoPackageVariation {
    // We are running an A/B test experiment for showing the tutorial section of the getting started tutorial.
    // We are going to segment based on the user-id, just dividing users randomly into one of two variations based
    // on their user id.
    if (!userId) return TutorialPackageExperimentControlVariation;
    // Hash the user id, take the last byte as an int, and see if it is odd or even
    const sha = new jsSHA("SHA-1", "TEXT", { encoding: "UTF8" });
    sha.update(userId);
    const hash = sha.getHash("UINT8ARRAY");
    return hash[hash.length - 1] % 2 === 0 ? TutorialPackageExperimentControlVariation : TutorialPackageExperimentNoPackageVariation;
}
