import React from "react";
import type { AccountResource } from "../../../client/resources";
import { useKeyedItemAccess } from "../../KeyAccessProvider/KeyedItemAccessProvider";
import isBound from "../BoundField/isBound";
import { OverflowSummary, PlaceholderSummary } from "../Sections/Summary";

type AccountSelectionSummaryProps = {
    accountIdOrName: string | undefined | null;
    accounts: AccountResource[];
    renderBound?: (accountIdOrName: string) => React.ReactNode;
    renderFound: (account: AccountResource) => React.ReactNode;
    renderMissing: (accountIdOrName: string) => React.ReactNode;
    renderFalsy?: () => React.ReactNode;
};

const defaultBoundRender: AccountSelectionSummaryProps["renderBound"] = (accountIdOrName) => (
    <OverflowSummary>
        Account is bound to <strong>{accountIdOrName}</strong>
    </OverflowSummary>
);

export const AccountSelectionSummary: React.FC<AccountSelectionSummaryProps> = ({
    accountIdOrName,
    accounts,
    renderBound = defaultBoundRender,
    renderFound,
    renderMissing,
    renderFalsy = () => <PlaceholderSummary>No account has been selected</PlaceholderSummary>,
}) => {
    const keyedBy = useKeyedItemAccess();

    if (accountIdOrName === undefined || accountIdOrName === null) {
        return <React.Fragment>{renderFalsy()}</React.Fragment>;
    }

    if (isBound(accountIdOrName)) {
        return <React.Fragment>{renderBound(accountIdOrName)}</React.Fragment>;
    }

    const found = accounts.find((x) => x[keyedBy] === accountIdOrName);

    if (!found) {
        return <React.Fragment>{renderMissing(accountIdOrName)}</React.Fragment>;
    }

    return <React.Fragment>{renderFound(found)}</React.Fragment>;
};
