import type { ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, getPathToInput, isBoundValue, isNotBoundValue } from "@octopusdeploy/step-runtime-inputs";
import type { NumberComponent } from "@octopusdeploy/step-ui";
import React from "react";
import { getInputPropertyForInputAtPath } from "~/components/StepPackageEditor/Inputs/schemaTraversal";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import { BoundNumber } from "~/primitiveComponents/form/Number/Number";
import ParseHelper from "~/utils/ParseHelper";
import { Note } from "../../Note/Note";

export function getStepNumberSummary<StepInputs>(component: NumberComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessor(component.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        if (inputValue === undefined) return "empty";
        return {
            isDefaultValue: false,
            value: inputValue.toString(),
        };
    } else {
        return {
            isDefaultValue: false,
            value: inputValue.expression,
        };
    }
}

interface StepNumberProps<StepInputs> {
    configuredStepUIProps: NumberComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    localNames: string[] | undefined;
    getFieldError: (name: PathToInput) => string;
}

export function StepNumber<StepInputs>(props: StepNumberProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, number | undefined>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.configuredStepUIProps.input);
    const inputProperty = getInputPropertyForInputAtPath(inputPath, props.getInputSchema(props.inputs));
    const emptyValue = inputProperty.isRequired ? 0 : undefined;
    const value = isNotBoundValue(inputValue) ? inputValue : inputValue.expression;
    const resetValue = isNotBoundValue(inputValue) ? inputValue : ParseHelper.safeParseFloat(inputValue.expression, emptyValue);

    return (
        <>
            <BoundNumber
                value={value}
                resetValue={resetValue}
                variableLookup={{
                    localNames: props.localNames,
                }}
                isBound={isBoundValue(inputValue)}
                onIsBoundChanged={(bound) => {
                    if (bound && isNotBoundValue(inputValue)) {
                        const updatedInputs = inputAccessor.changeInputValue(props.inputs, {
                            type: "bound",
                            expression: inputValue === undefined ? "" : inputValue.toString(),
                        });
                        props.setInputs(updatedInputs);
                    }
                }}
                onChange={(newValue: string | number | undefined) => {
                    if (typeof newValue === "string") {
                        const updatedInputs = inputAccessor.changeInputValue(props.inputs, {
                            type: "bound",
                            expression: newValue,
                        });
                        props.setInputs(updatedInputs);
                    } else {
                        const updatedInputs = inputAccessor.changeInputValue(props.inputs, newValue ?? emptyValue);
                        props.setInputs(updatedInputs);
                    }
                }}
                label={props.configuredStepUIProps.label}
                error={props.getFieldError(inputPath)}
            />
            <Note note={props.configuredStepUIProps.note} />
        </>
    );
}
