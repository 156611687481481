import React from "react";
import { useKeyedItemAccess } from "./KeyedItemAccessProvider";
import type { KeyedItemProps } from "./types";

export const withContextualKeyedItemAccess = <TProps extends {}>(Component: React.ComponentType<TProps & KeyedItemProps>) => {
    //The external props is the Props without KeyedItemProps
    type ExternalProps = Omit<TProps, keyof KeyedItemProps>;

    const WithContextualKeyAccess: React.FC<ExternalProps> = (props) => {
        const itemKey = useKeyedItemAccess();
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return <Component itemKey={itemKey} {...(props as TProps)} />;
    };
    return WithContextualKeyAccess;
};

export default withContextualKeyedItemAccess;
