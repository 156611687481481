import type { ActionTemplateParameterResource } from "./actionTemplateParameterResource";
import type { NamedResource } from "./namedResource";
import type { NewSpaceScopedResource, SpaceScopedResource } from "./spaceScopedResource";

export enum VariableSetContentType {
    Variables = "Variables",
    ScriptModule = "ScriptModule",
}

export interface LibraryVariableSetResource extends NewLibraryVariableSetResource, SpaceScopedResource {}

export interface NewLibraryVariableSetResource extends NamedResource, NewSpaceScopedResource {
    Description: string;
    VariableSetId: string;
    ContentType: VariableSetContentType;
    Templates: ActionTemplateParameterResource[];
}

export default LibraryVariableSetResource;
