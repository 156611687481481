/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { ActivityElement } from "~/client/resources";
import type { ScheduledTaskDetailsResource } from "~/client/resources/scheduledTaskDetailsResource";
import { TaskState } from "~/client/resources/taskState";
import { repository } from "~/clientInstance";
import { NavigationButton } from "~/components/Button/NavigationButton";
import type { DataBaseComponentState, Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import TaskLogBlock from "~/components/TaskLogLines/TaskLogBlock";
import Select from "~/primitiveComponents/form/Select/Select";
import routeLinks from "~/routeLinks";
import { noOp } from "~/utils/noOp";
import styles from "./style.module.less";

interface LogsBaseState extends DataBaseComponentState {
    detail?: ScheduledTaskDetailsResource;
    activityElement?: UniqueActivityElement;
    verbose: boolean;
    tail: boolean;
    hasLoadedOnce?: boolean;
}

export class LogsBase extends DataBaseComponent<{}, LogsBaseState> {
    private name: string;
    private title: string;

    constructor(name: string, title: string, props: {}) {
        super(props);
        this.name = name;
        this.title = title;
        this.state = { verbose: false, tail: true };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.doRefresh = await this.startRefreshLoop(() => this.refresh(this.state.verbose, this.state.tail), 5000);
        });
    }

    async refresh(verbose: boolean, tail: boolean) {
        this.setState({ verbose, tail });
        const args = { verbose, tail: tail ? 30 : null! };
        const detail = await repository.Scheduler.getDetails(this.name, args);
        return {
            detail,
            activityElement: this.setIdPrefix(detail.ActivityLog, 0),
            hasLoadedOnce: true,
        };
    }

    // This is a bit hacky since auto-deploys that kick off from same deployment will have the same task prefix
    setIdPrefix(element: ActivityElement, n: number): UniqueActivityElement {
        return {
            ...element,
            uniqueId: n + "/" + element.Id,
            Children: element.Children ? element.Children.map((c) => this.setIdPrefix(c, n)) : null!,
        };
    }

    setVerbose(value: boolean) {
        this.setState({ verbose: value }, async () => this.doRefresh());
    }

    setTail(value: boolean) {
        this.setState({ tail: value }, async () => this.doRefresh());
    }

    render() {
        const detail = this.state.detail;

        const section = detail && (
            <div>
                <NavigationButton href={detail.Links["Raw"]} label="Download" external={true} />
            </div>
        );

        const body = detail && (
            <div className={styles.body}>
                <div className={styles.filters}>
                    <div>
                        <Select
                            value={this.state.verbose.toString()}
                            onChange={(verbose) => this.setVerbose(verbose === "true")}
                            items={[
                                { text: "Info", value: "false" },
                                { text: "Verbose", value: "true" },
                            ]}
                            label="Log level"
                        />
                    </div>
                    <div>
                        <Select
                            value={this.state.tail.toString()}
                            onChange={(tail) => this.setTail(tail === "true")}
                            items={[
                                { text: "Last 20", value: "true" },
                                { text: "All", value: "false" },
                            ]}
                            label="Log tail"
                        />
                    </div>
                </div>
                <TaskLogBlock element={this.state.activityElement!} taskState={TaskState.Executing} expandedIds={null!} collapsible={false} showRunTime={false} setExpanded={noOp} showAdditional={() => this.setTail(false)} />
            </div>
        );

        return (
            <PaperLayout
                title={this.title}
                breadcrumbTitle={"Diagnostics"}
                breadcrumbPath={routeLinks.configuration.diagnostics.root}
                sectionControl={section}
                busy={this.state.busy}
                enableLessIntrusiveLoadingIndicator={this.state.hasLoadedOnce}
                errors={this.errors}
            >
                {body}
            </PaperLayout>
        );
    }

    private doRefresh: Refresh = () => Promise.resolve();
}

export default LogsBase;
