import type React from "react";

//then we really need a way to discriminate actions, hence we require flux/redux type actions.
export function reduceReducers<TState, TAction>(...reducers: Array<React.Reducer<TState, TAction>>) {
    const ReducedReducer: React.Reducer<TState, TAction> = (state, action) => {
        return reducers.reduce((prev, reducer) => reducer(prev, action), state);
    };

    return ReducedReducer;
}
