// When we have input components that are not underneath a section or section group,
// we still want to visually group them together, even though there is no heading under which to group them
// This function groups up any sequential input components into an array, making it easy to pass them down to an UnnamedSection
export function groupInputComponents<NonInputComponent, TInputComponent>(
    content: Array<NonInputComponent | TInputComponent>,
    isNonInputComponent: (component: NonInputComponent | TInputComponent) => component is NonInputComponent
): Array<NonInputComponent | TInputComponent[]> {
    // The algorithm becomes much simpler if we build this up in reverse
    // (i.e. add any new items to the beginning of the accumulation array), and then do a final reverse at the end
    return content
        .reduce<Array<NonInputComponent | TInputComponent[]>>((accumulatedInReverse, nextComponent) => {
            if (isNonInputComponent(nextComponent)) {
                return [nextComponent, ...accumulatedInReverse];
            }

            const [lastItem, ...rest] = accumulatedInReverse;
            if (Array.isArray(lastItem)) {
                return [[...lastItem, nextComponent], ...rest];
            }
            return [[nextComponent], ...accumulatedInReverse];
        }, [])
        .reverse();
}
