/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { Filter } from "~/areas/tasks/components/Tasks/Tasks";
import { getTaskQuery } from "~/areas/tasks/components/Tasks/Tasks";
import { convertQueryToQueryString } from "~/components/QueryStringFilters/QueryStringFilters";
import { Permission } from "../../../../client/resources";
import type { IExecutionResource } from "../../../../client/resources/deploymentResource";
import type { TaskResource } from "../../../../client/resources/taskResource";
import { session } from "../../../../clientInstance";

export default (root: string) => {
    const taskRoot = `${root}/tasks`;
    function filteredTasks(tasksFilter: Filter) {
        const queryString = convertQueryToQueryString(getTaskQuery(tasksFilter));
        return `${taskRoot}${queryString}`;
    }
    return {
        tasks: {
            root: taskRoot,
            console: `${taskRoot}/console`,
            rootForSpace: (spaceId: string | null) => {
                const hasTaskViewInCurrentSpace = session.currentPermissions!.scopeToSpace(spaceId).hasPermissionInAnyScope(Permission.TaskView);
                const shouldFilterToCurrentSpace = spaceId && hasTaskViewInCurrentSpace;
                const includeSystem = !shouldFilterToCurrentSpace;
                return filteredTasks({
                    includeSystem,
                    spaces: shouldFilterToCurrentSpace ? [spaceId!] : [],
                });
            },
            filtered: filteredTasks,
            interruption: (interruptionId: string) => `${taskRoot}/interruption/${interruptionId}`,
        },
        task: (taskId: string | TaskResource<any> | IExecutionResource) => {
            if (!!taskId && typeof taskId !== "string") {
                taskId = isDeploymentResource(taskId) ? taskId.TaskId : taskId.Id;
            }
            return {
                root: `${taskRoot}/${taskId}`,
                raw: `${taskRoot}/${taskId}/raw`,
            };
        },
    };
};

function isDeploymentResource(resource: TaskResource<any> | IExecutionResource): resource is IExecutionResource {
    return !!(resource as IExecutionResource).TaskId;
}
