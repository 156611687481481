import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryBuiltInRepositoryOverview, CommonLibraryBuiltInRepositoryTopics } from "../../CommonComponents/CommonLibraryBuiltInRepositoryViews";

export const LibraryBuiltInRepositoryPackageRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.builtInRepository.package,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryBuiltInRepositoryOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryBuiltInRepositoryTopics />;
    },
};
