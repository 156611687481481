import type { PathToInput } from "@octopusdeploy/step-runtime-inputs";

export function getPathAsString(pathToInput: PathToInput) {
    return pathToInput.reduce<string>((prev, current, index) => {
        if (typeof current === "symbol") {
            throw new Error("Symbol keys are not supported");
        }
        if (index === 0 && typeof current === "number") {
            throw new Error("The first segment of the path must be a string");
        }
        // prevent returned path from starting with a dot
        const includeLeadingDot = index !== 0;
        const stringSegment = includeLeadingDot ? `.${current}` : current;
        const next = typeof current === "string" ? stringSegment : `[${current}]`;
        return `${prev}${next}`;
    }, "");
}
