import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryFeedOverview, CommonLibraryFeedTopics } from "../../CommonComponents/CommonLibraryFeedViews";

export const LibraryFeedRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.feed.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryFeedOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryFeedTopics />;
    },
};
