export const UseDefaultBranch = { UseDefaultBranch: true };
type UseDefaultBranch = typeof UseDefaultBranch;
export type SpecifiedGitRef = string;
export type BranchSpecifier = SpecifiedGitRef | UseDefaultBranch;

export function ShouldUseDefaultBranch(branch: BranchSpecifier): branch is UseDefaultBranch {
    return typeof branch === "object";
}

export function getURISafeBranchSpecifier(branch: BranchSpecifier): BranchSpecifier {
    // Checking for ":" because the template is ":branchName", we don't want to encode that.
    if (typeof branch === "string" && branch[0] !== ":") {
        return encodeURIComponent(branch);
    }

    return branch;
}
