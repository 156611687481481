import type { InputPathToArray, ObjectInputPathsAndPathToObject } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition } from "@octopusdeploy/step-runtime-inputs";
import { createInputArrayAccessor, getArrayFromArrayInputPath, getPathToArrayInput } from "@octopusdeploy/step-runtime-inputs";
import type { InlineComponent, InlineInputRows, InlineListComponent, NoteExpression } from "@octopusdeploy/step-ui";
import cn from "classnames";
import React from "react";
import ActionButton from "~/components/Button/ActionButton";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { StepCheckbox } from "~/components/StepPackageEditor/Inputs/Components/Checkbox/Checkbox";
import { StepPackageSelect } from "~/components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/Select/Select";
import { Text } from "~/components/StepPackageEditor/Inputs/Components/Text/Text";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import { exhaustiveCheck } from "~/utils/exhaustiveCheck";
import { Note } from "../../Note/Note";
import { mapInitialInputs } from "../../mapInitialInputs";
import { getSchemaForInputArray } from "../../schemaTraversal";
import styles from "./style.module.less";

export function getInlineListSummary<StepInputs>(component: InlineListComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputArrayAccessor = createInputArrayAccessor<StepInputs, unknown>(component.input);
    const numberOfItems = inputArrayAccessor.getInputValue(inputs).length;
    if (numberOfItems === 0) {
        return "empty";
    }

    // todo-step-ui Add a set of summary chips of the individual items in the list
    if (numberOfItems === 1) {
        return { isDefaultValue: false, value: `${numberOfItems} item` };
    }
    return { isDefaultValue: false, value: `${numberOfItems} items` };
}

export interface InlineListProps<StepInputs> {
    configuredStepUIProps: InlineListComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    localNames: string[] | undefined;
    getFieldError: (name: PathToInput) => string;
    note?: NoteExpression[];
}

type Item = ObjectRuntimeInputs<unknown>;
class UnknownRemoveItemList extends RemoveItemsList<Item> {}

export function InlineList<StepInputs>(props: InlineListProps<StepInputs>) {
    const inputArrayAccessor = createInputArrayAccessor<StepInputs, unknown>(props.configuredStepUIProps.input);
    const items = inputArrayAccessor.getInputValue(props.inputs);

    function addNewItem() {
        // TODO: in this case we are pushing an initial input type into the runtime input type array
        // This could cause issues with trying to retrieve runtime schema from those inputs (although likely wont as the types sufficiently overlap)
        // We should investigate creating a shared base between these types
        const newItem = { ...props.configuredStepUIProps.newItem };
        const newInputs = inputArrayAccessor.changeInputValue(props.inputs, [...items, newItem]);
        const currentRuntimeSchema = getSchemaForInputArray(props.configuredStepUIProps.input, props.getInputSchema(newInputs));
        const newInputSchema = currentRuntimeSchema.itemTypes[currentRuntimeSchema.itemTypes.length - 1];
        // Only objects are supported as the input type for arrays when using the List component
        // so the value for isRequired here doesn't matter as the object will be parsed and
        // the requiredness of each property will be evaluated individually
        const mappedInputs = mapInitialInputs(newInputSchema, false, [...getPathToArrayInput(props.configuredStepUIProps.input), currentRuntimeSchema.itemTypes.length - 1], newInputs);

        props.setInputs(mappedInputs);
    }

    function removeItem(item: Item) {
        const newInputs = inputArrayAccessor.changeInputValue(
            props.inputs,
            items.filter((i) => i !== item)
        );
        props.setInputs(newInputs);
    }

    return (
        <>
            <Note note={props.note} />
            <UnknownRemoveItemList
                data={items}
                listActions={[<ActionButton label="Add" key="add" onClick={addNewItem} />]}
                onRemoveRow={removeItem}
                onRow={(_, index) => {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    const inputPathToArray = props.configuredStepUIProps.input as InputPathToArray<Item>;
                    return (
                        <InlineListRow<StepInputs>
                            itemIndex={index}
                            inputPathToArray={inputPathToArray}
                            formContent={props.configuredStepUIProps.editItemForm}
                            getFieldError={props.getFieldError}
                            localNames={props.localNames}
                            inputs={props.inputs}
                            setInputs={props.setInputs}
                            getInputSchema={props.getInputSchema}
                        />
                    );
                }}
            />
        </>
    );
}

interface InlineListRowProps<StepInputs> {
    inputPathToArray: InputPathToArray<Item>;
    itemIndex: number;
    formContent: (arrayItemInputs: ObjectInputPathsAndPathToObject<Item>) => InlineInputRows;
    getFieldError: (name: PathToInput) => string;
    localNames: string[] | undefined;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
}

function InlineListRow<StepInputs>({ inputPathToArray, itemIndex, formContent, inputs, setInputs, localNames, getFieldError, getInputSchema }: InlineListRowProps<StepInputs>) {
    const inputPathToArrayItem = getArrayFromArrayInputPath(inputPathToArray)[itemIndex];
    const inputRows = formContent(inputPathToArrayItem);
    return (
        <>
            <div>
                {inputRows.map((inputRow, index) => (
                    <span key={index} className={styles.row}>
                        {inputRow.map((inputComponent) => (
                            <div className={cn(styles.inputs, inputRow.length > 1 ? styles.multiInput : "")} key={inputComponent.label}>
                                <InlineInput<StepInputs> component={inputComponent} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} localNames={localNames} getInputSchema={getInputSchema} />
                            </div>
                        ))}
                    </span>
                ))}
            </div>
        </>
    );
}

interface InlineInputProps<StepInputs> {
    component: InlineComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    getFieldError: (name: PathToInput) => string;
    localNames: string[] | undefined;
}

function InlineInput<StepInputs>({ component, inputs, setInputs, getFieldError, localNames, getInputSchema }: InlineInputProps<StepInputs>) {
    switch (component.type) {
        case "inline-text":
            return <Text<StepInputs> input={component.input} label={component.label} inputs={inputs} setInputs={setInputs} getFieldError={getFieldError} localNames={localNames} />;
        case "inline-checkbox":
            return <StepCheckbox<StepInputs> setInputs={setInputs} getFieldError={getFieldError} localNames={localNames} inputs={inputs} input={component.input} label={component.label} />;
        case "inline-select":
            return <StepPackageSelect<StepInputs> setInputs={setInputs} inputs={inputs} input={component.input} label={component.label} options={component.options} getInputSchema={getInputSchema} getFieldError={getFieldError} />;
    }
    exhaustiveCheck(component, "Unsupported inline component type");
}
