/* eslint-disable @typescript-eslint/no-non-null-assertion */
export type IsLoopStillRunning = () => boolean;

export interface Dependencies {
    hidden(): boolean;
    now(): number;
    setTimeout(handler: () => Promise<void>, timeout: number): number;
    clearTimeout(timeoutId: number | undefined): void;
    addEventListener(type: string, listener: () => Promise<void>): void;
    removeEventListener(type: string, listener: () => Promise<void>): void;
}

export class RefreshLoop {
    private refreshLoop: Loop = undefined!;

    constructor(
        private readonly callback: (isLoopStillRunning: IsLoopStillRunning) => Promise<void>,
        private readonly refreshInterval: (hidden: boolean) => number,
        private readonly config: Dependencies = {
            hidden: () => document.hidden,
            now: () => Date.now(),
            setTimeout: (handler: () => Promise<void>, timeout: number) => window.setTimeout(handler, timeout),
            clearTimeout: (timeoutId: number) => window.clearTimeout(timeoutId),
            addEventListener: (type: string, listener: () => Promise<void>) => window.document.addEventListener(type, listener),
            removeEventListener: (type: string, listener: () => Promise<void>) => window.document.removeEventListener(type, listener),
        }
    ) {}

    public refresh = () => {
        this.replaceLoop();
        return this.refreshLoop.startAndRefreshImmediately();
    };

    public start() {
        this.refreshLoop = new Loop(this.callbackInternal, this.refreshInterval, this.config);
        this.config.addEventListener("visibilitychange", this.visibilityChanged);
        this.refreshLoop.start();
    }

    public stop = () => {
        this.config.removeEventListener("visibilitychange", this.visibilityChanged);
        this.refreshLoop?.stop();
    };

    private callbackInternal = async (refresher: Loop) => {
        await this.callback(() => this.isRunning(refresher));
    };

    private isRunning = (refresher: Loop) => {
        return this.refreshLoop === refresher && this.refreshLoop.isRunning();
    };

    private visibilityChanged = async () => {
        this.replaceLoop();
        if (this.config.hidden()) {
            this.refreshLoop.start();
        } else {
            await this.refreshLoop.startAndRefreshImmediately();
        }
    };

    private replaceLoop() {
        this.refreshLoop.stop();

        this.refreshLoop = new Loop(this.callbackInternal, this.refreshInterval, this.config);
    }
}

class Loop {
    private timeoutId: number | undefined;
    private stopped: boolean = true;

    constructor(private readonly callback: (refresher: Loop) => Promise<void>, private readonly refreshInterval: (hidden: boolean) => number, private readonly config: Dependencies) {}

    public stop() {
        this.stopped = true;
        this.config.clearTimeout(this.timeoutId);
        this.timeoutId = undefined;
    }

    public start() {
        this.stopped = false;
        this.timeoutId = this.config.setTimeout(this.loop, this.getRefreshInterval());
    }

    public async startAndRefreshImmediately() {
        this.stopped = false;
        await this.loop();
    }

    public isRunning = () => !this.stopped;

    private getRefreshInterval = () => this.refreshInterval(this.config.hidden());

    private loop = async () => {
        if (this.stopped) {
            return;
        }

        await this.callback(this);

        if (this.stopped) {
            return;
        }

        this.timeoutId = this.config.setTimeout(this.loop, this.getRefreshInterval());
    };
}
