import * as React from "react";
import CenteredLayout from "~/components/CenteredLayout/CenteredLayout";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import type { UnhandledErrorState } from "~/components/UnhandledError/reducers";

interface AuthenticationLayoutProps {
    unhandledError: UnhandledErrorState;
}

const AuthenticationLayoutInternal: React.FC<AuthenticationLayoutProps> = (props) => {
    return (
        <CenteredLayout>
            {props.unhandledError && <ErrorPanel message={props.unhandledError.message} errors={props.unhandledError.errors} />}
            {props.children}
        </CenteredLayout>
    );
};

export default AuthenticationLayoutInternal;
