import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter } from "react-router-dom";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import ReloadableRoute from "../../../../components/ReloadableRoute";
import { EnhancedRunbookRoutes as RunbookRoutes, OperationsOverviewPage } from "../Runbooks/RunbookRoutes";
import { EnhancedRunbooksRoute as RunbooksRoute } from "../Runbooks/RunbooksRoute";
import { TriggersRoute } from "../Runbooks/Triggers/TriggersRoute";
import { opsRouteLinks } from "./opsRouteLinks";

type OperationsRouteProps = { path: string } & RouteComponentProps;

class OperationsRoute extends React.Component<OperationsRouteProps> {
    render() {
        const links = opsRouteLinks(this.props.path);

        return (
            <Switch>
                <ReloadableRoute path={links.root} exact={true} render={(props) => <OperationsOverviewPage {...props} />} />
                <ReloadableRoute path={links.triggers} render={(props) => <TriggersRoute {...props} />} />
                <RunbookRoutes path={links.runbook(":runbookId").root} />
                <RunbooksRoute path={links.runbooks} />
                <RedirectAs404 />
            </Switch>
        );
    }
}

const EnhancedOperationsRoute = withRouter(OperationsRoute);
export default EnhancedOperationsRoute;
