import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";

export interface DataTableRowProps {
    displayNoBorder?: boolean;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void;
}

export class DataTableRow extends React.Component<DataTableRowProps> {
    render() {
        const { displayNoBorder, className, ...otherProps } = this.props;

        return (
            <tr {...otherProps} className={cn(className, styles.dataTableRow, displayNoBorder ? styles.noBorder : "")}>
                {this.props.children}
            </tr>
        );
    }
}
