import { uniqueId } from "lodash";
import type { MouseEventHandler } from "react";
import React, { useCallback, useMemo } from "react";
import type { MenuState } from "~/primitiveComponents/navigation/Menu/CustomMenu";

export type MenuTargetAriaAttributes = {
    "aria-controls": string;
    "aria-haspopup": "menu";
    "aria-expanded": "true" | "false";
};

export function useMenuState(): [MouseEventHandler, MenuState, MenuTargetAriaAttributes] {
    const [anchorElement, setAnchorElement] = React.useState<Element | null>(null);
    const openMenu = useCallback<MouseEventHandler>((event) => setAnchorElement(event.currentTarget), [setAnchorElement]);
    const onClose = useCallback(() => setAnchorElement(null), [setAnchorElement]);
    const menuId = React.useMemo(() => uniqueId("menu-"), []);

    const menuState = useMemo(
        () => ({
            isOpen: Boolean(anchorElement),
            onClose,
            anchorElement,
            menuId,
        }),
        [anchorElement, menuId, onClose]
    );

    const triggerAriaAttributes = {
        "aria-controls": menuId,
        "aria-haspopup": "menu" as const,
        "aria-expanded": toBooleanString(menuState.isOpen),
    };

    return [openMenu, menuState, triggerAriaAttributes];
}

function toBooleanString(value: boolean): "true" | "false" {
    return value ? "true" : "false";
}
