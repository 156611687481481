import { client } from "~/clientInstance";
import { pageId } from "~/utils/pageId";
import dashboardRouteLinks from "./dashboardRouteLinks";

const dashboardLinks = dashboardRouteLinks(client.spaceId ?? "");

class DashboardPageIds {
    root = pageId({ area: "Dashboard", id: "Dashboard", name: "Dashboard", routeLink: dashboardLinks.root });
    configure = pageId({ area: "Dashboard", id: "Dashboard.Configure", name: "Configure Dashboard", routeLink: dashboardLinks.configure });
}

export default new DashboardPageIds();
