import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";

type DataTableFooterColumnProps = React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>;

export class DataTableFooterColumn extends React.Component<DataTableFooterColumnProps> {
    render() {
        const { className, ...otherProps } = this.props;
        return (
            <td className={cn(className, styles.dataTableFooterColumn)} {...otherProps}>
                {this.props.children}
            </td>
        );
    }
}
