import type { Client } from "../client";
import type { ProjectGroupResource } from "../resources";
import BasicRepository from "./basicRepository";

class ProjectGroupRepository extends BasicRepository<ProjectGroupResource, ProjectGroupResource> {
    constructor(client: Client) {
        super("ProjectGroups", client);
    }
}

export default ProjectGroupRepository;
