import type { ObjectResourceInputs, ObjectRuntimeInputs, PathToInput, RootInputSchema } from "@octopusdeploy/step-runtime-inputs";
import { asResourceInputs, asRuntimeInputs, convertFromJsonSchemaToInputSchema } from "@octopusdeploy/step-runtime-inputs";
import * as React from "react";
import { useCallback } from "react";
import type { DeploymentTargetInputDependencies } from "~/components/StepPackageDeploymentTargetEditor/DeploymentTargetInputDependencies";
import { getPathAsString } from "~/components/StepPackageEditor/Inputs/getPathAsString";
import type { UnknownStepPackageDeploymentTarget } from "./StepPackageDeploymentTarget";
import { TypedStepPackageDeploymentTargetEditor } from "./TypedStepPackageDeploymentTargetEditor";

export interface StepPackageDeploymentTargetEditorProps {
    inputs: ObjectResourceInputs<unknown>;
    stepPackage: UnknownStepPackageDeploymentTarget;
    setInputs(inputs: ObjectResourceInputs<unknown>): void;
    getFieldError(field: string): string;
    isExpandedByDefault: boolean;
    inputDependencies: DeploymentTargetInputDependencies;
}

export function StepPackageDeploymentTargetEditor(props: StepPackageDeploymentTargetEditorProps) {
    const { inputs, getFieldError, stepPackage, inputDependencies, isExpandedByDefault } = props;
    const { inputJsonSchema } = stepPackage;

    const getFieldErrorFromInputPath = React.useCallback(
        (pathToInput: PathToInput) => {
            return getFieldError(`inputs.${getPathAsString(pathToInput)}`);
        },
        [getFieldError]
    );

    const getRootInputSchema = useCallback(
        (inputs: ObjectResourceInputs<unknown> | ObjectRuntimeInputs<unknown>): RootInputSchema => {
            return convertFromJsonSchemaToInputSchema(inputJsonSchema, inputs);
        },
        [inputJsonSchema]
    );

    const setInputs = (inputs: ObjectResourceInputs<unknown>) => {
        const inputSchema = getRootInputSchema(inputs);
        const resourceInputs = asResourceInputs(inputSchema.properties, inputs);
        props.setInputs(resourceInputs);
    };

    const stepResourceInputs: ObjectResourceInputs<unknown> = inputs ?? {};
    const runtimeSchema = getRootInputSchema(stepResourceInputs);
    const runtimeInputs = asRuntimeInputs(runtimeSchema.properties, stepResourceInputs);

    return (
        <div>
            <TypedStepPackageDeploymentTargetEditor<unknown>
                stepPackage={props.stepPackage}
                setInputs={setInputs}
                inputs={runtimeInputs}
                getRootInputSchema={getRootInputSchema}
                getFieldError={getFieldErrorFromInputPath}
                inputDependencies={inputDependencies}
                isExpandedByDefault={isExpandedByDefault}
            />
        </div>
    );
}

export default StepPackageDeploymentTargetEditor;
