import type ActionTemplateSearchResource from "~/client/resources/actionTemplateSearchResource";
import Environment from "~/environment";
import type { StepPackageDeploymentTargetType } from "../../client/resources/stepPackageDeploymentTargetType";

export function hidePrereleaseStepPackages(stepPackage: StepPackageDeploymentTargetType | ActionTemplateSearchResource) {
    if (Environment.isInDevelopmentMode()) {
        return true;
    }
    return !stepPackage.Prerelease;
}
