import type { ServerConfigurationResource, ServerConfigurationSettingsSetResource } from "~/client/resources";
import type { Client } from "../client";
import ConfigurationRepository from "./configurationRepository";

class ServerConfigurationRepository extends ConfigurationRepository<ServerConfigurationResource> {
    constructor(client: Client) {
        super("ServerConfiguration", client);
    }
    settings() {
        return this.client.get<ServerConfigurationSettingsSetResource[]>(this.client.getLink("ServerConfigurationSettings"));
    }
}

export default ServerConfigurationRepository;
