import { compact } from "lodash";
import * as React from "react";
import { useState } from "react";
import { GitRefChip } from "~/areas/projects/components/Releases/GitRefChip/GitRefChip";
import type { Filter } from "~/areas/tasks/components/Tasks/Tasks";
import { ProcessType } from "~/client/resources";
import type { ActionTemplateUsageResource, ProjectSummaryResource } from "~/client/resources";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout";
import ActionButton from "~/components/Button";
import { ActionButtonType } from "~/components/Button/ActionButton";
import FilterSearchBox from "~/components/FilterSearchBox";
import InternalLink from "~/components/Navigation/InternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import SimpleDataTable from "~/components/SimpleDataTable";
import { Checkbox, Select, UnstructuredFormSection } from "~/components/form";
import ToolTip from "~/primitiveComponents/dataDisplay/ToolTip";
import { ControlledTabsContainer, TabItem } from "~/primitiveComponents/navigation/Tabs";
import routeLinks from "~/routeLinks";
import { ActionTemplateUsageProcessLink, ActionTemplateUsageStepLink } from "./ActionTemplateUsageLinks";
import styles from "./style.module.less";

interface ActionTemplateUsageTableProps {
    usages: ActionTemplateUsageResource[];
    templateVersion: number;
    onUpdateAction(u: ActionTemplateUsageResource): void;
    onUpdateAll(): void;
    onFilterUsages: (newValue: ActionTemplateUsageVersionControlledFilter) => Promise<void>;
    showVcsUsages?: boolean;
    projects?: ProjectSummaryResource[];
    vcsProjects?: ProjectSummaryResource[];
}

export interface ActionTemplateUsageVersionControlledFilter extends Filter {
    branch: string;
    project: string;
    withUpdates: boolean;
    process?: string;
}

class FilterLayout extends AdvancedFilterLayout<ActionTemplateUsageVersionControlledFilter> {}

export const ActionTemplateUsageTable = (props: ActionTemplateUsageTableProps) => {
    const defaultFilter = {
        branch: "",
        spaces: [],
        includeSystem: false,
        project: "",
        withUpdates: false,
        process: "",
    };

    const [tab, changeTab] = useState<string>("version-controlled");
    const [filter, setFilter] = useState<ActionTemplateUsageVersionControlledFilter>(defaultFilter);

    const onFilterChanged = async (filter: ActionTemplateUsageVersionControlledFilter) => {
        setFilter(filter);
        await props.onFilterUsages(filter);
    };

    const onTabChanged = async (tab: string) => {
        setFilter(defaultFilter);
        await props.onFilterUsages(defaultFilter);
        changeTab(tab);
    };

    if (props.usages.length === 0 && !props.showVcsUsages) {
        return <NoResults />;
    }

    const vcsProjects = props.vcsProjects ?? [];

    if (props.showVcsUsages) {
        return (
            <ControlledTabsContainer value={tab} onChange={onTabChanged}>
                <TabItem label="Version-Controlled Projects" value={"version-controlled"}>
                    <UnstructuredFormSection>
                        <p>
                            This page shows up to twenty recent releases where this step template was used. You can also filter this list by searching for specific branches. Note: If your branch has never been used to create a release but uses this
                            template, it will not show in this list.
                        </p>
                        <FilterLayout
                            filter={filter}
                            filterSections={[
                                { render: <Checkbox label="Update available only" value={filter.withUpdates} onChange={async (withUpdates) => await onFilterChanged({ ...filter, withUpdates })}></Checkbox> },
                                {
                                    render: (
                                        <Select
                                            value={filter.project}
                                            onChange={async (project) => await onFilterChanged({ ...filter, project: project ?? "" })}
                                            items={vcsProjects.map((p) => ({ value: p.Id, text: p.Name }))}
                                            allowClear={true}
                                            allowFilter={true}
                                            fieldName="project"
                                            placeholder="All projects"
                                        />
                                    ),
                                },
                            ]}
                            defaultFilter={defaultFilter}
                            onFilterReset={() => onFilterChanged(defaultFilter)}
                            renderContent={() =>
                                table(
                                    props.usages.filter((u) => u.Branch),
                                    props,
                                    ["Project", "Process", "Step", "Release", "Branch", "Version", null],
                                    "Version-Controlled Projects"
                                )
                            }
                            additionalHeaderFilters={[
                                <FilterSearchBox
                                    inputClassName={styles.filterInput}
                                    placeholder="Search for branches..."
                                    value={filter.branch}
                                    onChange={async (branch) => await onFilterChanged({ ...filter, branch })}
                                    autoFocus={true}
                                    fullWidth={false}
                                />,
                            ]}
                        ></FilterLayout>
                    </UnstructuredFormSection>
                </TabItem>
                <TabItem label="Database-Backed Projects" value={"database-backed"}>
                    <UnstructuredFormSection>{filterableDatabaseUsages(filter, props, onFilterChanged)}</UnstructuredFormSection>
                </TabItem>
            </ControlledTabsContainer>
        );
    }
    return filterableDatabaseUsages(filter, props, onFilterChanged);
};

function filterableDatabaseUsages(filter: ActionTemplateUsageVersionControlledFilter, props: ActionTemplateUsageTableProps, onFilterChanged: (filter: ActionTemplateUsageVersionControlledFilter) => Promise<void>) {
    const updateAllButton = props.usages.some((u) => !u.Branch && props.templateVersion.toString() !== u.Version) ? <ActionButton type={ActionButtonType.Ternary} onClick={() => props.onUpdateAll()} label="Update All..." /> : null;

    return (
        <FilterLayout
            filter={filter}
            filterSections={[
                { render: <Checkbox label="Update available only" value={filter.withUpdates} onChange={async (withUpdates) => await onFilterChanged({ ...filter, withUpdates })}></Checkbox> },
                {
                    render: (
                        <Select
                            value={filter.process}
                            className={styles.select}
                            onChange={async (process) => await onFilterChanged({ ...filter, process })}
                            items={[
                                { text: ProcessType.Deployment, value: ProcessType.Deployment },
                                { text: ProcessType.Runbook, value: ProcessType.Runbook },
                            ]}
                            allowClear={true}
                            allowFilter={true}
                            fieldName="process"
                            placeholder="Process Type"
                            labelStyles={{ width: "500px" }}
                        />
                    ),
                },
            ]}
            defaultFilter={filter}
            onFilterReset={() => onFilterChanged(filter)}
            renderContent={() =>
                table(
                    props.usages.filter((u) => !u.Branch),
                    props,
                    ["Project", "Process", "Step", "Version", updateAllButton],
                    "Action Template Usages"
                )
            }
            additionalHeaderFilters={[
                <Select
                    value={filter.project}
                    onChange={async (project) => await onFilterChanged({ ...filter, project: project ?? "" })}
                    items={(props.projects ?? []).filter((p) => !(props.vcsProjects ?? []).some((p2) => p.Id == p2.Id)).map((p) => ({ value: p.Id, text: p.Name }))}
                    allowClear={true}
                    allowFilter={true}
                    fieldName="project"
                    placeholder="All projects"
                    labelStyles={{ width: "300px" }}
                />,
            ]}
        ></FilterLayout>
    );
}

function table(usages: ActionTemplateUsageResource[], props: ActionTemplateUsageTableProps, headerColumns: unknown[], title: string) {
    return (
        <SimpleDataTable
            title={title}
            data={usages}
            headerColumns={headerColumns}
            onRow={(u: ActionTemplateUsageResource) =>
                compact([
                    <InternalLink to={routeLinks.project(u.ProjectSlug).root}>{u.ProjectName}</InternalLink>,
                    <ActionTemplateUsageProcessLink usage={u} />,
                    <ActionTemplateUsageStepLink usage={u} />,
                    u.Release,
                    u.Branch && <GitRefChip vcsRef={{ GitRef: u.Branch }} showCommit={false} className={styles.gitRefChip} />,
                    u.Version,
                    getUpdateStatus(props, u),
                ])
            }
        />
    );
}
function getUpdateStatus(props: ActionTemplateUsageTableProps, u: ActionTemplateUsageResource): React.ReactNode {
    if (props.templateVersion.toString() === u.Version) {
        return "Up to date";
    } else if (!u.Branch) {
        return <ActionButton type={ActionButtonType.Secondary} onClick={(e) => props.onUpdateAction(u)} label="Update..." accessibleName="update-usage" />;
    } else {
        return (
            <div>
                <ToolTip content={"Please update from the step page"}>Update available</ToolTip>
            </div>
        );
    }
}
