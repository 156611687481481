import ConfigurationRepository from "~/client/repositories/configurationRepository";
import type { LetsEncryptConfigurationResource } from "~/client/resources";
import type { Client } from "../client";

class LetsEncryptConfigurationRepository extends ConfigurationRepository<LetsEncryptConfigurationResource> {
    constructor(client: Client) {
        super("LetsEncryptConfiguration", client);
    }
}

export default LetsEncryptConfigurationRepository;
