/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import BusyFromPromise from "~/components/BusyFromPromise";
import BusyRefreshIndicator from "~/components/BusyRefreshIndicator";
import styles from "./style.module.less";

interface BusyRefreshContainerProps {
    busy: Promise<any> | boolean;
}

const BusyRefreshContainer: React.StatelessComponent<BusyRefreshContainerProps> = (props) => {
    return (
        <div className={styles.controlWithRefreshContainer}>
            {props.children}
            <div className={styles.refreshContainer}>
                <BusyFromPromise promise={props.busy}>{(busy: boolean) => <BusyRefreshIndicator show={busy} />}</BusyFromPromise>
            </div>
        </div>
    );
};

BusyRefreshContainer.displayName = "BusyRefreshContainer";

export default BusyRefreshContainer;
