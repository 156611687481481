/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { TeamResource } from "~/client/resources";
import { ContextualMissingChip, TeamChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import { useKeyedItemAccess } from "../KeyAccessProvider/KeyedItemAccessProvider";
import Lookup from "../Lookup";

interface TeamMultiSelectProps extends FormFieldProps<string[]> {
    items: TeamResource[];
    canBeDeleted?: (team: TeamResource) => boolean;
    label?: string | JSX.Element;
    error?: string;
    descriptionPostfix?: (team: TeamResource) => string;
}

const TeamTypedMultiSelect = MultiSelect<TeamResource>();

export const TeamMultiSelect: React.FC<TeamMultiSelectProps> = (props) => {
    const itemKey = useKeyedItemAccess();

    const chipRenderer = (r: TeamResource | SelectItem, onRequestDelete: () => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r[itemKey]}
                getIdFromElement={(element) => element[itemKey]}
                render={(item) => {
                    const onRequestDeleteProp = props.canBeDeleted!(item) ? { onRequestDelete } : {};
                    return <TeamChip {...onRequestDeleteProp} deleteButtonAccessibleName={`Delete ${item.Name}`} team={item} descriptionPostfix={props.descriptionPostfix ? props.descriptionPostfix(item) : null!} />;
                }}
                renderFallback={<ContextualMissingChip lookupKey={r[itemKey]} type={ChipIcon.Team} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <TeamTypedMultiSelect label="Select teams" renderChip={chipRenderer} itemKey={itemKey} {...props} />;
};

TeamMultiSelect.defaultProps = {
    canBeDeleted: (team) => true,
};
