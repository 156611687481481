/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import type { ActionEvent, AnalyticActionDispatcher } from "~/analytics/Analytics";
// import type { AnalyticActionDispatcher, ActionResultEvent } from "~/analytics/Analytics";
import { Action, ActionStatus, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { ConnectivityCheckResponse, ConnectivityCheckResponseMessage } from "~/client/resources/dynamicFormResources";
import { ConnectivityCheckResponseMessageCategory } from "~/client/resources/dynamicFormResources";
import { client } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import styles from "./style.module.less";

interface DynamicConnectivityCheckDialogProps {
    title: string;
    url: string;
    values: { [key: string]: any };
    onOkClick: () => void;
}

interface DynamicConnectivityCheckDialogState extends DataBaseComponentState {
    checkComplete?: boolean;
    messages?: ConnectivityCheckResponseMessage[];
}

interface DynamicConnectivityCheckDialogInternalProps extends DynamicConnectivityCheckDialogProps {
    dispatchAction: AnalyticActionDispatcher;
}

class DynamicConnectivityCheckDialogInternal extends DataBaseComponent<DynamicConnectivityCheckDialogInternalProps, DynamicConnectivityCheckDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            let url = this.props.url;
            if (this.props.url.startsWith("/")) {
                url = `~${this.props.url}`;
            }

            client
                .post<ConnectivityCheckResponse>(url, this.props.values)
                .then((resp: ConnectivityCheckResponse) => {
                    const determineStatus = () => {
                        if (resp.Messages.some((m) => m.Category == ConnectivityCheckResponseMessageCategory.Error)) {
                            return ActionStatus.Failed;
                        }
                        if (resp.Messages.some((m) => m.Category == ConnectivityCheckResponseMessageCategory.Warning)) {
                            return ActionStatus.SucessWithWarning;
                        }
                        return ActionStatus.Success;
                    };

                    const status = determineStatus();
                    const event: ActionEvent = {
                        status: status,
                        resource: "Version Control Settings",
                        action: Action.ServerResponse,
                    };
                    this.props.dispatchAction(status, event);

                    this.setState({
                        checkComplete: true,
                        messages: resp.Messages,
                    });
                })
                .catch((err) => {
                    this.setState({ checkComplete: true });
                    throw err;
                });
        });
    }

    detailFor(category: ConnectivityCheckResponseMessageCategory, message: string, index: number) {
        let calloutType = CalloutType.Success;
        if (category === ConnectivityCheckResponseMessageCategory.Warning) {
            calloutType = CalloutType.Warning;
        }
        if (category === ConnectivityCheckResponseMessageCategory.Error) {
            calloutType = CalloutType.Danger;
        }
        return (
            <Callout title={category} type={calloutType} key={`callout-${index}`}>
                <div className={styles.testingResult}>{message}</div>
            </Callout>
        );
    }

    render() {
        return (
            <OkDialogLayout title={`Verifying ${this.props.title}`} hideCancel={true} busy={!this.state.checkComplete || this.state.busy} errors={this.errors} onOkClick={this.props.onOkClick}>
                {this.state.checkComplete && this.state.messages && <div>{this.state.messages.map((e, index) => this.detailFor(e.Category, e.Message, index))}</div>}
            </OkDialogLayout>
        );
    }
}

export default (props: DynamicConnectivityCheckDialogProps) => {
    const dispatchAction = useAnalyticActionDispatch();

    return <DynamicConnectivityCheckDialogInternal {...props} dispatchAction={dispatchAction} />;
};
