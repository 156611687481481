import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonTenantOverviewViews } from "../../CommonComponents/CommonTenantOverviewViews";
import { CommonTenantTopics } from "../../CommonComponents/CommonTenantViews";

export const TenantVariablesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.tenant.variables,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonTenantOverviewViews />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonTenantTopics />;
    },
};
