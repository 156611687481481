import * as React from "react";
import type { UserResource } from "~/client/resources";
import { ChipIcon, MissingChip } from "~/components/Chips";
import { UserChip, userDisplayName } from "~/components/Chips/UserChip";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";

interface UserMultiSelectProps extends FormFieldProps<string[]> {
    items: UserResource[];
    label?: string | JSX.Element;
    error?: string;
}

interface UserResourceWithName extends UserResource {
    Name: string;
}

const UserTypedMultiSelect = MultiSelect<UserResourceWithName>();

export const UserMultiSelect: React.FC<UserMultiSelectProps> = (props) => {
    const chipRenderer = (r: UserResourceWithName | SelectItem, onRequestDelete: () => void) => {
        const usersWithName = props.items.map((u) => ({ ...u, Name: u.DisplayName }));

        return (
            <Lookup
                lookupCollection={usersWithName}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <UserChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} user={item} />}
                renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.Environment} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    const { items, ...rest } = props;
    return (
        <UserTypedMultiSelect
            items={items.map((i) => {
                const user = { ...i, ...{ Name: `${userDisplayName(i)} ${i.EmailAddress || ""}` } };
                return user;
            })}
            fieldName="users"
            renderChip={chipRenderer}
            renderItem={(i) => ({ primaryText: userDisplayName(i), secondaryText: i.EmailAddress })}
            {...rest}
        />
    );
};
