import { startCase } from "lodash";
import * as React from "react";
import type { SFC } from "react";
import type { ServerDocumentCounts } from "~/client/resources/serverDocumentCount";
import Section from "~/components/Section";
import { DataTable } from "~/primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableBody } from "~/primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
import { DataTableRowHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowHeaderColumn";
import DataTableSectionHeading from "~/primitiveComponents/dataDisplay/DataTable/DataTableSectionHeading";

interface ServerDocumentCountsProps {
    documentCounts: ServerDocumentCounts;
}

const ServerDocumentCountsTable: SFC<ServerDocumentCountsProps> = (props) => {
    return (
        <Section sectionHeader="System-wide document counts">
            {Object.entries(props.documentCounts).map(([group, documentCounts]) => {
                return (
                    <div key={group}>
                        <DataTableSectionHeading title={group} />
                        <DataTable>
                            <DataTableBody>
                                {Object.entries(documentCounts).map(([documentType, count]) => {
                                    return (
                                        <DataTableRow key={documentType}>
                                            <DataTableRowHeaderColumn>{startCase(documentType)}</DataTableRowHeaderColumn>
                                            <DataTableRowColumn>{count}</DataTableRowColumn>
                                        </DataTableRow>
                                    );
                                })}
                            </DataTableBody>
                        </DataTable>
                    </div>
                );
            })}
        </Section>
    );
};

ServerDocumentCountsTable.displayName = "System-wide document counts";

export default ServerDocumentCountsTable;
