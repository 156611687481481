import type { TransitionProps } from "@material-ui/core/transitions/transition";
import type { PropsWithChildren } from "react";
import React, { forwardRef, useContext } from "react";

const transitionComponentOverrideContext = React.createContext<React.ComponentType<TransitionProps> | null>(null);

export function OverrideTransitionComponents(props: PropsWithChildren<{ transitionComponent: React.ComponentType<TransitionProps> }>) {
    return <transitionComponentOverrideContext.Provider value={DefaultTransitionComponent}>{props.children}</transitionComponentOverrideContext.Provider>;
}

export function useTransitionComponentOverride(): React.ComponentType<TransitionProps> | null {
    return useContext(transitionComponentOverrideContext);
}

/**
 * This component can be used in place of a Material-ui transition component. It is designed to be used during testing
 * It does not invoke the onEnter/onExit/etc. callbacks of a normal transition component and instead always renders its children
 * This makes it ideal for testing
 * Some components (eg Modal from material-ui) will unmount their children asynchronously due to their usage of Transitions
 * By providing a custom component that does not invoke any of the on* methods from other transitions, we can bypass this logic
 * and force components like Modal to unmount their children synchronously
 * @param props
 * @constructor
 */
export const DefaultTransitionComponent = forwardRef((props: React.PropsWithChildren<TransitionProps>, ref: React.Ref<HTMLDivElement>) => {
    return (
        // Material-ui assumes that this component needs to be focusable, hence the tabIndex
        <>
            {props.in && (
                <div ref={ref} tabIndex={0}>
                    {props.children}
                </div>
            )}
        </>
    );
});
