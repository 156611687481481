import * as React from "react";
import { Text } from "~/components/form";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import ParseHelper from "~/utils/ParseHelper";
import type FormFieldProps from "../../../components/form/FormFieldProps";
import type { TextOtherProps } from "../Text/Text";

type NumberOtherProps = Omit<TextOtherProps, "type">;

type NumberProps = NumberOtherProps & FormFieldProps<number | undefined>;

export default function Number(props: NumberProps) {
    const { value, onChange, ...otherProps } = props;

    const handleChange = (newValue: string) => {
        const newValueParsed = newValue ? ParseHelper.safeParseFloat(newValue, 0) : undefined;
        if (onChange) onChange(newValueParsed);
    };

    return <Text type="number" onChange={handleChange} value={value === undefined ? "" : value.toString()} {...otherProps} />;
}

export const BoundNumber = withBoundField<number | undefined, NumberOtherProps>(Number);
