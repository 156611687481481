/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { TeamResource, InvitationResource } from "~/client/resources";
import { client, repository } from "~/clientInstance";
import CopyToClipboard from "~/components/CopyToClipboardButton/CopyToClipboardButton";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { Section } from "~/components/Section/Section";
import styles from "./style.module.less";

interface UserInviteDialogProps {
    team: TeamResource;
}

interface UserInviteDialogState extends DataBaseComponentState {
    invite?: InvitationResource;
}

export class UserInviteDialog extends DataBaseComponent<UserInviteDialogProps, UserInviteDialogState> {
    constructor(props: UserInviteDialogProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const invite = await repository.Invitations.invite([this.props.team.Id], this.props.team.SpaceId!);
            this.setState({ invite });
        });
    }

    render() {
        const resolveLink = (invite: InvitationResource) => `${client.resolve(invite.Links.Register)}`;
        const anchor = (link: string) => (
            <a href={link} target="_blank" rel={"noopener noreferrer"} onClick={(e) => e.stopPropagation()}>
                {link}
            </a>
        );

        const body = this.state.invite && (
            <div>
                <Section>
                    Invitation codes allow users to register their own accounts on the Octopus Deploy server. Each code can be used at most once, and all codes will expire 48 hours after creation. <br />
                </Section>
                <Section>Please provide the link below to the invited user:</Section>
                <Section>
                    <div className={styles.linkWithCopy}>
                        <div className={styles.copyAction}>
                            <CopyToClipboard value={resolveLink(this.state.invite)} />
                        </div>
                        <div className={styles.link}>{anchor(resolveLink(this.state.invite))}</div>
                    </div>
                </Section>
            </div>
        );

        return (
            <OkDialogLayout title={`Invite User to ${this.props.team.Name} team`} hideCancel={true} busy={this.state.busy} errors={this.errors} onOkClick={() => true}>
                {body}
            </OkDialogLayout>
        );
    }
}
