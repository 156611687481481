/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { Text } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { RefreshLoop } from "~/utils/RefreshLoop/refresh-loop";
import type { OctopusSpaceRepository } from "../../../../client/repository";
import type { TaskResource } from "../../../../client/resources";
import { Permission, TaskState } from "../../../../client/resources";
import { repository, session } from "../../../../clientInstance";
import BusyIndicator from "../../../../components/BusyIndicator/BusyIndicator";
import ActionButton from "../../../../components/Button";
import type { DoBusyTask } from "../../../../components/DataBaseComponent/DataBaseComponent";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import { Section } from "../../../../components/Section/Section";
import ToolTip from "../../../../primitiveComponents/dataDisplay/ToolTip/ToolTip";
import routeLinks from "../../../../routeLinks";
import { TaskFilterState } from "../../../tasks/components/Tasks/Tasks";

interface DeleteSpaceProps {
    spaceName: string;
    spaceId: string;
    isDefaultSpace: boolean;
    isTaskQueueStopped: boolean;
    doBusyTask: DoBusyTask;
    onChange(canDelete: boolean): void;
    stopTaskQueue(): Promise<void>;
}

interface DeleteSpaceState {
    confirmationSpaceName: string;
    taskQueueIsStopping: boolean;
    taskQueueWasStopped: boolean;
    runningTasks?: Array<TaskResource<{}>>;
}

//eslint-disable-next-line react/no-unsafe
export default class DeleteSpace extends React.Component<DeleteSpaceProps, DeleteSpaceState> {
    private stopRefreshLoop: (() => void) | undefined;

    constructor(props: DeleteSpaceProps) {
        super(props);
        this.state = {
            confirmationSpaceName: "",
            taskQueueWasStopped: props.isTaskQueueStopped,
            taskQueueIsStopping: false,
        };
    }

    async UNSAFE_componentWillMount() {
        await this.props.doBusyTask(async () => {
            const spaceRepositoryPromise = repository.forSpace(this.props.spaceId);
            const refreshLoop = new RefreshLoop(
                async (isLoopStillRunning) => {
                    const runningTasks = await loadTasksForSpace(await spaceRepositoryPromise);
                    if (isLoopStillRunning!) {
                        this.setState({ runningTasks });
                    }
                },
                () => 3000
            );
            this.stopRefreshLoop = refreshLoop.stop;
            this.setState({ runningTasks: await loadTasksForSpace(await spaceRepositoryPromise) });
            refreshLoop.start();
        });
    }

    componentWillUnmount() {
        this.props.onChange(false);

        if (this.stopRefreshLoop !== undefined) {
            this.stopRefreshLoop();
        }
    }

    render() {
        if (this.state.runningTasks === undefined) {
            return null;
        }
        if (this.props.isDefaultSpace) {
            return (
                <div>
                    <p>
                        It seems you are trying to delete <strong>{this.props.spaceName}</strong> space, but this space is marked as the <strong>default</strong> space.
                    </p>
                    <p>Before this can be done you need to disable the default space.</p>
                </div>
            );
        }

        const canDeleteSpace = this.props.isTaskQueueStopped && this.state.runningTasks.length === 0;

        return (
            <div>
                <Callout title="This is a major destructive action" type={CalloutType.Danger}>
                    This action <strong>cannot</strong> be undone. This will permanently delete the <strong>{this.props.spaceName}</strong> space and all of its contents, including projects, environments, releases and deployment history.
                </Callout>

                {!this.props.isTaskQueueStopped && this.renderStopTaskQueue()}
                {this.props.isTaskQueueStopped && (!this.state.taskQueueWasStopped || this.state.runningTasks.length > 0) && this.renderTaskQueueIsNowStopped()}
                {this.props.isTaskQueueStopped && this.state.runningTasks.length > 0 && this.renderWaitForRunningTasksToComplete()}
                {!!canDeleteSpace && (
                    <div>
                        <p>Please type in the name of the space to confirm.</p>
                        <Text value={this.state.confirmationSpaceName} onChange={this.onChange} />
                    </div>
                )}
            </div>
        );
    }

    private renderStopTaskQueue = () => {
        return (
            <div>
                <p>
                    To delete this space you need to first <strong>stop processing tasks in this space</strong>.
                </p>
                <ToolTip content={"This helps guarantee that no tasks are running within this space when it is deleted"}>
                    <ActionButton
                        label={this.state.taskQueueIsStopping ? "Stopping the processing of tasks..." : "Stop processing tasks"}
                        onClick={() => this.stopTaskQueue()}
                        disabled={this.props.isTaskQueueStopped || this.state.taskQueueIsStopping}
                    />
                </ToolTip>
            </div>
        );
    };

    private renderTaskQueueIsNowStopped = () => {
        return <p>Processing of tasks for this space has been stopped, which means that no queued tasks will begin running.</p>;
    };

    private renderWaitForRunningTasksToComplete = () => {
        const pleaseWaitFor = "Please wait for "; // In a variable so that the trailing space is not removed by auto-formatting
        return (
            <div>
                <p>
                    {pleaseWaitFor}
                    <InternalLink
                        openInSelf={false}
                        to={routeLinks.forSpace(this.props.spaceId).tasks.filtered({
                            state: TaskFilterState.Running,
                            spaces: [this.props.spaceId],
                            includeSystem: false,
                        })}
                    >
                        {this.state.runningTasks!.length} running task{this.state.runningTasks!.length === 1 ? "" : "s"}
                    </InternalLink>{" "}
                    to complete before the space can be deleted.
                </p>
                <Section>
                    <BusyIndicator show={this.state.runningTasks!.length > 0} inline={true} />
                </Section>
            </div>
        );
    };

    private stopTaskQueue = async () => {
        await this.props.doBusyTask(async () => {
            this.setState({ taskQueueIsStopping: true });
            try {
                await this.props.stopTaskQueue();
            } finally {
                this.setState({ taskQueueIsStopping: false });
            }
        });
    };

    private onChange = (confirmationSpaceName: string) => {
        this.setState({ confirmationSpaceName });
        this.props.onChange(confirmationSpaceName.replace(/\s+/g, " ") === this.props.spaceName.replace(/\s+/g, " "));
    };
}

async function loadTasksForSpace(spaceRepository: OctopusSpaceRepository): Promise<Array<TaskResource<{}>>> {
    if (session.currentPermissions!.scopeToSpace(spaceRepository.spaceId).hasPermissionInAnyScope(Permission.TaskView)) {
        const tasks = await spaceRepository.Tasks.filter({
            states: [TaskState.Executing, TaskState.Cancelling].join(","),
            includeSystem: false,
        });
        return tasks.Items;
    }

    // Assume there are no running tasks. They will be blocked when they try to submit the request if there are any tasks they can't see
    return [];
}
