import classNames from "classnames";
import React from "react";
import ExternalLink from "../Navigation/ExternalLink";
import styles from "./PrivateSpaceInfo.module.less";

export function PrivateSpaceInfo({ isPrivateSpace }: { isPrivateSpace: boolean }) {
    if (!isPrivateSpace) return null;

    return (
        <div className={classNames(styles.wrapper)}>
            <span className={styles.headline}>This is your private space</span>
            <span className={styles.content}>The private space is yours to explore and learn Octopus.</span>
            <ExternalLink href="PrivateSpaceDocs">Learn more</ExternalLink>
        </div>
    );
}
