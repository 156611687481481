import React from "react";
import type { ActionType } from "typesafe-actions";
import { createAction, getType } from "typesafe-actions";
import { combineConstantReducers } from "~/utils/Reducers/combineConstantReducers";
import type { CapturedClientMetricsProvider } from "../../captureClientMetrics";

export interface DirtyItemState {
    name: string;
    model: object | undefined;
    cleanModel: object | undefined;
}

export type DirtyLookup = { [name: string]: DirtyItemState };

export interface DevToolsTabState {
    name: string;
    children: React.ReactNode;
}

export const actions = {
    registerDirty: createAction("dirty/register", (resolve) => (state: DirtyItemState) => resolve(state)),
    unregisterDirty: createAction("dirty/unregister", (resolve) => (name: string) => resolve(name)),

    registerTab: createAction("devtools/registertab", (resolve) => (state: DevToolsTabState) => resolve(state)),
    unregisterTab: createAction("devtools/unregistertab", (resolve) => (name: string) => resolve(name)),

    openDrawer: createAction("drawer/open"),
    toggleDrawer: createAction("drawer/toggle"),
    closeDrawer: createAction("drawer/close"),
    toggleFullscreen: createAction("drawer/toggle/fullscreen"),
};

type DevToolsActions = ActionType<typeof actions>;

const dirtyLookupReducer = (state: DirtyLookup, action: DevToolsActions) => {
    switch (action.type) {
        case getType(actions.registerDirty):
            return {
                ...state,
                [action.payload.name]: action.payload,
            };
        case getType(actions.unregisterDirty):
            if (Object.prototype.hasOwnProperty.call(state, action.payload)) {
                const { [action.payload]: deleted, ...next } = state;
                return next;
            }
            return state;
    }
    return state;
};

interface DevDrawerState {
    open: boolean;
    fullscreen: boolean;
}

const drawerReducer = (state: DevDrawerState, action: DevToolsActions) => {
    switch (action.type) {
        case getType(actions.openDrawer):
            return { ...state, open: true };
        case getType(actions.closeDrawer):
            return { ...state, open: false };
        case getType(actions.toggleDrawer):
            return { ...state, open: !state.open };
        case getType(actions.toggleFullscreen):
            return { ...state, fullscreen: !state.fullscreen };
    }
    return state;
};

interface DevToolsTabsState {
    tabs: Map<string, React.ReactNode>;
}

const devToolsTabsReducer = (state: DevToolsTabsState, action: DevToolsActions): DevToolsTabsState => {
    switch (action.type) {
        case getType(actions.registerTab):
            state.tabs.set(action.payload.name, action.payload.children);
            return {
                ...state,
            };
        case getType(actions.unregisterTab):
            state.tabs.delete(action.payload);
            return {
                ...state,
            };
    }
    return state;
};

export interface DevToolsContextState {
    dirty: DirtyLookup;
    drawer: DevDrawerState;
    devToolsTabs: DevToolsTabsState;
}

export type DevToolsContextActions = {
    dispatch: React.Dispatch<DevToolsActions>;
};

export const reducer = combineConstantReducers<DevToolsContextState, DevToolsActions>({
    dirty: dirtyLookupReducer,
    drawer: drawerReducer,
    devToolsTabs: devToolsTabsReducer,
});

const DevToolsContextStateInternal = React.createContext<DevToolsContextState | undefined>(undefined);
const DevToolsContextActionsInternal = React.createContext<DevToolsContextActions | undefined>(undefined);
const DevToolsCapturedClientMetricsContext = React.createContext<CapturedClientMetricsProvider | undefined>(undefined);

const INITIAL_DEVTOOLS_STATE: DevToolsContextState = {
    dirty: {},
    drawer: { open: false, fullscreen: false },
    devToolsTabs: { tabs: new Map<string, React.ReactNode>() },
};

export const DevToolsContextProvider: React.FC<{ clientMetricsProvider: CapturedClientMetricsProvider }> = (props) => {
    const [state, dispatch] = React.useReducer(reducer, INITIAL_DEVTOOLS_STATE);

    return (
        <DevToolsContextStateInternal.Provider value={state}>
            <DevToolsContextActionsInternal.Provider value={{ dispatch }}>
                <DevToolsCapturedClientMetricsContext.Provider value={props.clientMetricsProvider}>{props.children}</DevToolsCapturedClientMetricsContext.Provider>
            </DevToolsContextActionsInternal.Provider>
        </DevToolsContextStateInternal.Provider>
    );
};

DevToolsContextProvider.displayName = "DevToolsContextProvider";

export const useDevToolsState = () => {
    return React.useContext(DevToolsContextStateInternal);
};

export const useDevToolsDispatch = () => {
    return React.useContext(DevToolsContextActionsInternal);
};

export const useClientMetrics = () => {
    return React.useContext(DevToolsCapturedClientMetricsContext);
};

type TrackDirtyProps = DirtyItemState;

export const TrackDirty: React.FC<TrackDirtyProps> = ({ cleanModel, model, name }) => {
    useTrackDirtyEffect(name, cleanModel, model);
    return null;
};

export const useTrackDirtyEffect = (name: string, cleanModel: object | undefined, model: object | undefined) => {
    const context = React.useContext(DevToolsContextActionsInternal);
    const dispatch = context?.dispatch;
    React.useEffect(() => {
        dispatch?.(actions.registerDirty({ cleanModel, model, name }));
        return () => {
            dispatch?.(actions.unregisterDirty(name));
        };
    }, [cleanModel, dispatch, model, name]);
};

type DevToolsTabProps = DevToolsTabState;

export const DevToolsTab: React.FC<DevToolsTabProps> = ({ name, children }) => {
    useDevToolsTabEffect(name, children);
    return null;
};

export const useDevToolsTabEffect = (name: string, children: React.ReactNode) => {
    const context = React.useContext(DevToolsContextActionsInternal);
    const dispatch = context?.dispatch;
    React.useEffect(() => {
        dispatch?.(actions.registerTab({ name, children }));
        return () => {
            dispatch?.(actions.unregisterTab(name));
        };
    }, [dispatch, children, name]);
};
