import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationLetsEncryptOverview, CommonConfigurationLetsEncryptTopics } from "../../CommonComponents/CommonConfigurationLetsEncryptViews";

export const ConfigurationLetsEncryptRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.letsEncrypt.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationLetsEncryptOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationLetsEncryptTopics />;
    },
};
