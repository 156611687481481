/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { ProjectResource, ProjectSummary } from "~/client/resources";
import Permission from "~/client/resources/permission";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import routeLinks from "~/routeLinks";

export type ProjectRedirectOption = {
    vcsRedirect?: boolean;
    newlyCreatedProjectRedirect?: boolean;
};

export const ProjectToOverviewRedirect: React.FC = (): React.ReactElement => {
    const projectContext = useProjectContext();

    const redirectOption = {
        vcsRedirect: true,
    };

    const projectOverviewPath = OverviewRedirectPathForProject(projectContext.state.model, projectContext.state.summary, redirectOption);
    return <InternalRedirect to={projectOverviewPath} />;
};

const RedirectPathBaseForProject = (project: ProjectResource, summary: ProjectSummary | undefined, redirectOption: ProjectRedirectOption | undefined = {}) => {
    if (IsAllowedToSeeOperationsOverview(project.Id) && !IsAllowedToSeeDeploymentsOverview(project.Id)) {
        return routeLinks.project(project.Slug).operations.root;
    }

    if (redirectOption.vcsRedirect && !summary) {
        return routeLinks.project(project.Slug).settings.versionControl;
    }

    if (summary) {
        const hasNoProcessOrRunbooks = summary.HasDeploymentProcess === false && summary.HasRunbooks === false;
        return hasNoProcessOrRunbooks || summary.HasDeploymentProcess ? routeLinks.project(project.Slug).deployments.root : routeLinks.project(project.Slug).operations.root;
    }

    return routeLinks.project(project.Slug).deployments.root;
};

export const OverviewRedirectPathForProject = (project: ProjectResource, summary: ProjectSummary | undefined, redirectOption: ProjectRedirectOption | undefined = {}): string => {
    if (!project) {
        return null!;
    }

    const baseUrl = RedirectPathBaseForProject(project, summary, redirectOption);

    if (redirectOption.newlyCreatedProjectRedirect) {
        return `${baseUrl}?newlyCreatedProject=true`;
    }

    return baseUrl;
};

export const IsAllowedToSeeDeploymentsOverview = (projectId: string | undefined | null): boolean => {
    if (!projectId) {
        return false;
    }
    const isAllowedToSeeDeploymentsOverview = isAllowed({
        permission: [Permission.ReleaseView, Permission.ProcessView],
        project: projectId,
        tenant: "*",
    });
    return isAllowedToSeeDeploymentsOverview;
};

export const IsAllowedToSeeOperationsOverview = (projectId: string): boolean => {
    if (!projectId) {
        return false;
    }
    const isAllowedToSeeDeploymentsOverview = isAllowed({
        permission: Permission.RunbookView,
        project: projectId,
        wildcard: true,
    });
    return isAllowedToSeeDeploymentsOverview;
};
