import * as React from "react";
import type { AutoCompleteProps } from "../AutoComplete";
import AutoComplete from "../AutoComplete";
import { withBoundField } from "../form/BoundField/BoundField";

export const BoundAutoComplete = withBoundField((props: AutoCompleteProps) => (
    <div style={{ display: "flex", alignItems: "baseline", width: "100%" }}>
        <AutoComplete {...props} />
    </div>
));
