import * as React from "react";
import styles from "./style.module.less";

interface ReadonlyTextProps {
    text: string;
    className?: string;
    monoSpacedFont?: boolean;
}

// This component is supposed to be styled the same as a <Text /> component.
// This idea being that if they were side by side and the text component was not focused and did not have an underline,
// then they would look identical
const ReadonlyText: React.SFC<ReadonlyTextProps> = (props) => {
    return (
        <div className={styles.container}>
            <div className={`${props.className} ${styles.content}`} title={props.text}>
                <div className={`${props.monoSpacedFont ? styles.monospacedFont : ""} ${styles.textOverflow}`}>{props.text}</div>
            </div>
        </div>
    );
};

export default ReadonlyText;
