import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import type { CategoryDefinition } from "./endpointRegistry";

const category: CategoryDefinition = {
    category: "Windows",
    title: (
        <React.Fragment>
            What type of <strong>Windows target</strong> do you want to set up?
        </React.Fragment>
    ),
    help: (
        <Note>
            Learn about the differences between Listening and Polling <ExternalLink href="TentacleCommunicationModes">Tentacle Communication Modes</ExternalLink>
        </Note>
    ),
    displayOrder: 10,
};

export default category;
