import { isEqual } from "lodash";
import * as React from "react";
import type { DataBaseComponentState, Errors } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import { OctopusError } from "../../client/resources";
import BusyFromPromise from "../BusyFromPromise";
import { createErrorsFromOctopusError } from "../DataBaseComponent/Errors";

interface RenderAlternateProps {
    busy: boolean;
    clearError: () => void;
    errors: Errors | undefined;
}

interface DataLoaderProps<TData> {
    load(): Promise<TData>;
    reloadOnPropChanges?: boolean;
    renderWhenLoaded(data: TData): React.ReactNode;
    renderAlternate(props: RenderAlternateProps): React.ReactNode;
}

interface DataLoaderState<TData> extends DataBaseComponentState {
    loadedData?: TData;
    errors: Errors | undefined;
}

export default function DataLoader<TData>() {
    return class DataLoadInner extends DataBaseComponent<DataLoaderProps<TData>, DataLoaderState<TData>> {
        constructor(props: DataLoaderProps<TData>) {
            super(props);
            this.state = { errors: undefined };
        }

        async componentDidMount() {
            await this.load();
        }

        async componentDidUpdate(prevProps: DataLoaderProps<TData>) {
            if (this.props.reloadOnPropChanges && !isEqual(prevProps, this.props)) {
                await this.load();
            }
        }

        async load() {
            await this.doBusyTask(async () => {
                try {
                    this.setState({
                        loadedData: await this.props.load(),
                    });
                } catch (e) {
                    if (e instanceof OctopusError) {
                        this.setState({ errors: createErrorsFromOctopusError(e) });
                    }
                    throw e;
                }
            });
        }

        render() {
            return this.state.loadedData && !this.state.errors ? (
                this.props.renderWhenLoaded(this.state.loadedData)
            ) : (
                <BusyFromPromise promise={this.state.busy}>{(busy: boolean) => this.props.renderAlternate({ errors: this.errors, clearError: () => this.setState({ errors: undefined }), busy })}</BusyFromPromise>
            );
        }
    };
}
