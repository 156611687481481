import type { PathToInput, ObjectRuntimeInputs } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessorForDeploymentTarget, getPathToInput } from "@octopusdeploy/step-runtime-inputs";
import type { TextComponent } from "@octopusdeploy/step-ui";
import React from "react";
import { Note } from "~/components/StepPackageEditor/Inputs/Note/Note";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";

interface DeploymentTargetTextProps<StepInputs> {
    configuredStepUIProps: TextComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (path: PathToInput) => string;
}

export function DeploymentTargetText<StepInputs>(props: DeploymentTargetTextProps<StepInputs>) {
    const { input, label, note } = props.configuredStepUIProps;
    const inputAccessor = createInputValueAccessorForDeploymentTarget<StepInputs, string>(input);
    const inputPath = getPathToInput(input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    return (
        <>
            <DebounceText
                value={inputValue}
                onChange={(newValue) => {
                    const updatedInputs = inputAccessor.changeInputValue(props.inputs, newValue);
                    props.setInputs(updatedInputs);
                }}
                label={label}
                error={props.getFieldError(inputPath)}
            />
            <Note note={note} />
        </>
    );
}
