import * as React from "react";
import type { RenderProps } from "~/components/Dialog/CustomDialog";
import ActionButton, { ActionButtonType } from "../../Button/ActionButton";
import { CustomDialogActions } from "./CustomDialogActions";
import { CustomDialogContent } from "./CustomDialogContent";
import type { DialogFrameProp } from "./CustomDialogFrames";
import { LargeDialogFrame } from "./CustomDialogFrames";
import { CustomDialogTitleBar } from "./CustomDialogTitleBar";

export type CustomInfoDialogLayoutProps = RenderProps &
    DialogFrameProp & {
        title: string;
        closeButtonLabel?: string;
        additionalActions?: React.ReactNode;
    };

export const CustomInfoDialogLayout: React.SFC<CustomInfoDialogLayoutProps> = ({ closeButtonLabel = "Close", children, title, close, frame: Frame = LargeDialogFrame, additionalActions }) => {
    return (
        <Frame>
            <CustomDialogTitleBar title={title} />
            <CustomDialogContent>{children}</CustomDialogContent>
            <CustomDialogActions actions={<ActionButton key="close" onClick={close} label={closeButtonLabel} type={ActionButtonType.Primary} />} additionalActions={additionalActions} />
        </Frame>
    );
};

export default CustomInfoDialogLayout;
