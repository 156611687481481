/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import type { GitCredentialResource } from "~/client/resources";
import { useKeyedItemAccess } from "~/components/KeyAccessProvider/KeyedItemAccessProvider";
import type { KeyedItemProps } from "~/components/KeyAccessProvider/types";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type FormFieldProps from "~/components/form/FormFieldProps";
import SelectWithAddRefresh from "~/components/form/SelectWithAddRefresh/SelectWithAddRefresh";
import routeLinks from "~/routeLinks";
import styles from "./style.module.less";

type GitCredentialSelectProps = FormFieldProps<string> & {
    fixedLabel?: boolean;
    items: GitCredentialResource[];
    placeholder?: string;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    onRequestRefresh(): Promise<any>;
    onFilterChanged?(value: string): void;
};

interface TextState {
    error?: string;
    refreshing: boolean;
}

export const GitCredentialSelect: React.FC<GitCredentialSelectProps> = (props) => {
    const keyedBy = useKeyedItemAccess();
    return <GitCredentialSelectInternal itemKey={keyedBy} {...props} />;
};

type GitCredentialSelectInternalProps = GitCredentialSelectProps & KeyedItemProps;

export class GitCredentialSelectInternal extends React.Component<GitCredentialSelectInternalProps, TextState> {
    constructor(props: GitCredentialSelectInternalProps) {
        super(props);
        this.state = {
            error: null!,
            refreshing: false,
        };
    }

    handleChange = (gitCredentialId: string | undefined) => {
        const value = gitCredentialId === "" ? null : gitCredentialId;
        if (this.props.validate) {
            const result = this.props.validate(value!);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.props.onChange!(value!);
    };

    getItems() {
        const map = (credential: GitCredentialResource) => ({ value: credential.Id, text: credential.Name });
        return this.props.items.map(map);
    }

    selectionRenderer = (gitCredentialId: string) => {
        const credential = this.props.items.find((credential) => credential.Id === gitCredentialId);
        if (!credential) {
            return gitCredentialId;
        }

        return <div className={styles.innerContainer}>{credential.Name}</div>;
    };

    render() {
        const { value } = this.props;

        return (
            <div className={styles.outerContainer}>
                <SelectWithAddRefresh
                    value={value}
                    addUrl={`#${routeLinks.library.gitCredentials.root}`}
                    onRequestRefresh={this.onRequestRefresh}
                    label="Select Git credential"
                    allowClear={true}
                    allowFilter={true}
                    onChange={this.handleChange}
                    error={this.state.error || this.props.error}
                    items={this.getItems()}
                    selectionRenderer={this.selectionRenderer}
                />
            </div>
        );
    }

    private onRequestRefresh = async () => {
        this.setState({ refreshing: true });
        try {
            await this.props.onRequestRefresh();
        } finally {
            this.setState({ refreshing: false });
        }
    };
}

export const BoundGitCredentialSelect = withBoundField(GitCredentialSelect);
export default GitCredentialSelect;
