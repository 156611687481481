import React from "react";
import type { NameOrIdKey } from "./types";

const KeyAccessProviderContext = React.createContext<NameOrIdKey | undefined>(undefined);
KeyAccessProviderContext.displayName = "KeyAccessProviderContext";

type KeyAccessProviderProps = {
    accessItemsBy: NameOrIdKey;
};

export const KeyedItemAccessProvider: React.FC<KeyAccessProviderProps> = (props) => {
    return <KeyAccessProviderContext.Provider value={props.accessItemsBy}>{props.children}</KeyAccessProviderContext.Provider>;
};

export const useKeyedItemAccess = () => {
    //The default when no context exists is to access by Id
    return React.useContext(KeyAccessProviderContext) ?? "Id";
};

export const KeyedItemAccessConsumer: React.FC<{ children: (keyAccess: NameOrIdKey) => React.ReactNode }> = ({ children }) => {
    return (
        <KeyAccessProviderContext.Consumer>
            {(accessItemsBy) => {
                return children(accessItemsBy ?? "Id");
            }}
        </KeyAccessProviderContext.Consumer>
    );
};

export default KeyedItemAccessProvider;
