import { Switch } from "@material-ui/core";
import { useCallback } from "react";
import * as React from "react";
import { MenuItemButton } from "~/primitiveComponents/navigation/MenuItems/MenuItemButton/MenuItemButton";
import styles from "./MenuItemToggle.module.less";

export interface MenuItemToggleProps {
    isEnabled: boolean;
    label: string;
    setIsEnabled: (isEnabled: boolean) => void;
    autoFocus?: boolean;
    compact?: boolean;
}

export function MenuItemToggle({ setIsEnabled, isEnabled, label, autoFocus, compact }: MenuItemToggleProps) {
    const toggleIsEnabled = useCallback(() => setIsEnabled(!isEnabled), [isEnabled, setIsEnabled]);

    // TODO: This should be role="menuitemcheckbox"
    return (
        <MenuItemButton onClick={toggleIsEnabled} autoFocus={autoFocus} compact={compact}>
            <div className={styles.container}>
                <div className={styles.label}>{label}</div>
                <Switch size="small" checked={isEnabled} />
            </div>
        </MenuItemButton>
    );
}
