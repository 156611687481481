import { identity } from "lodash";
import type { Reducer } from "react";
import { useReducer, useMemo } from "react";

const patchReducer = <TState extends {}, TAction extends {}, TReducer extends Reducer<TState, TAction>, TPatchedState extends TState>(reducer: TReducer, patcher: (state: TState, action: TAction) => TPatchedState) => {
    const patchedReducer: Reducer<TPatchedState, TAction> = (state: TPatchedState, action: TAction) => {
        const nextState = reducer(state, action);
        return patcher(nextState, action);
    };
    return patchedReducer;
};
const usePatchReducer = <TState, TAction, TNextState extends TState>(reducer: Reducer<TState, TAction>, initialState: TNextState, patcher: (state: TState, action: TAction) => TNextState, initializer: (state: TNextState) => TNextState = identity) => {
    const patchedReducer = useMemo(() => patchReducer(reducer, patcher), [reducer, patcher]);
    return useReducer(patchedReducer, initialState, initializer);
};
export default usePatchReducer;
export { patchReducer, usePatchReducer };
