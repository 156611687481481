import type { UserIdentityMetadataResource } from "~/client/authentication";
import type UserAuthenticationResource from "~/client/resources/userAuthenticationResource";
import type { Client } from "../client";

class UserIdentityMetadataRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    all(): Promise<UserIdentityMetadataResource> {
        return this.client.get(this.client.getLink("UserIdentityMetadata"));
    }
    authenticationConfiguration(userId: string | null) {
        return this.client.get<UserAuthenticationResource>(this.client.getLink("UserAuthentication"), { userId });
    }
}

export default UserIdentityMetadataRepository;
