export type Options = Array<{ value: string; text: string }>;

export default function selectOptionsToItems(selectOptions?: string): Options {
    const options = selectOptions || "";
    return options
        .split("\n")
        .filter((line) => line.length !== 0)
        .map((line) => line.split("|"))
        .map((keyValuePair) => (keyValuePair.length > 1 ? keyValuePair : [keyValuePair[0], keyValuePair[0]]))
        .map((keyValuePair) => ({
            value: keyValuePair[0].trim(),
            text: keyValuePair[1].trim(),
        }));
}
