import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter, Route } from "react-router-dom";
import type { ProcessType } from "~/client/resources";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import routeLinks from "~/routeLinks";
import { BranchAwareRedirect } from "../../ProjectsRoutes/BranchAwareRedirect";
import { ProcessStepsPage, ProcessListLayoutPage } from "../Pages";

type ProcessRouteProps = {
    path: string;
    processType: ProcessType;
};

type Props = ProcessRouteProps & RouteComponentProps<{ projectSlug: string }>;

class ProcessRoute extends React.Component<Props> {
    render() {
        return (
            <BranchAwareRedirect>
                <ErrorContextProvider>
                    <Switch>
                        <Route path={`${this.props.path}/steps`} exact={true} render={(props) => <ProcessStepsPage processType={this.props.processType} {...props} />} />
                        <Route path={`${this.props.path}`} exact={true} render={(props) => <ProcessListLayoutPage processType={this.props.processType} {...props} />} />
                        <OldProcessRedirectRoute {...this.props} />
                        <RedirectAs404 />
                    </Switch>
                </ErrorContextProvider>
            </BranchAwareRedirect>
        );
    }
}

class OldProcessRedirectRoute extends React.Component<Props> {
    render() {
        // If anyone has bookmarked an older step route, we redirect them back to the list. We want to K.I.S.S. and avoid mapping the old route parameters to the new query-string parameters (that felt like overkill).
        const redirectToProcessList = <InternalRedirect to={routeLinks.project(this.props.match.params.projectSlug).deployments.process.root} />;
        return (
            <Switch>
                <ReloadableRoute path={`${this.props.path}/step/new/:actionType/:template?`} render={() => redirectToProcessList} />
                <ReloadableRoute path={`${this.props.path}/step/:stepId/new/:actionType/:template?`} render={() => redirectToProcessList} />
                <ReloadableRoute path={`${this.props.path}/step/:stepId/:reloadKey?`} render={() => redirectToProcessList} />
                <ReloadableRoute path={`${this.props.path}/steptemplates`} render={() => redirectToProcessList} />
                <ReloadableRoute path={`${this.props.path}/childsteptemplates/:parentStepId`} render={() => redirectToProcessList} />
            </Switch>
        );
    }
}

const EnhancedProcessRoute = withRouter(ProcessRoute);

export default EnhancedProcessRoute;
