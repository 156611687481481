import React from "react";

type MiniProfileRequestViewProps = {
    profilerId: string;
};

export const MiniProfilerRequestView: React.FC<MiniProfileRequestViewProps> = (props) => {
    return <iframe style={{ width: "100%", height: "100%", backgroundColor: "#FFF" }} src={`${window.location.origin}/mini-profiler/results?id=${props.profilerId}`} />;
};

export default MiniProfilerRequestView;
