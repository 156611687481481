import * as React from "react";
import CodeEditor, { TextFormat } from "~/components/CodeEditor/CodeEditor";
import { commandLinePackagingInstruction } from "../../Instructions";
import { CommonCommandLineTextForOctoExe } from "../CommonRegistrationText";
import styles from "../Registrations.module.less";

export const CommandLinePackaging: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging .NET Core applications from command line, we recommend using <code>dotnet publish</code> on the project followed by <code>octo.exe pack</code> on the output directory.
        </p>
        {CommonCommandLineTextForOctoExe}
        <div className={styles.codeContainer}>
            For example:
            <br />
            <CodeEditor
                value={`# Publish the application to a folder
dotnet publish source/MyApp.Web --output published-app --configuration Release

# Package the folder into a ZIP
octo pack --id MyApp.Web --version 1.0.0 --basePath published-app`}
                allowFullScreen={false}
                readOnly={true}
                language={TextFormat.PlainText}
            />
        </div>
    </div>
);

export default commandLinePackagingInstruction(() => <CommandLinePackaging />);
