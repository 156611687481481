/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { connect } from "react-redux";
import type { match as Match } from "react-router";
import { useParams, useRouteMatch } from "react-router";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import { saveLogo } from "~/client/repositories/logoUpload";
import type { MultiTenancyStatusResource, ResourceCollection, TenantResource } from "~/client/resources";
import { repository } from "~/clientInstance";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { List } from "~/components/List/List";
import InternalLink from "~/components/Navigation/InternalLink";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import { Permission } from "../../../client/resources/permission";
import FormPaperLayout from "../../../components/FormPaperLayout/FormPaperLayout";
import Logo from "../../../components/Logo/Logo";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import { ExpandableFormSection, FormSectionHeading, MarkdownEditor, Summary } from "../../../components/form";
import type { SummaryNode } from "../../../components/form";
import LogoEditor from "../../../components/form/LogoEditor/LogoEditor";
import type { LogoEditorSettings } from "../../../components/form/LogoEditor/LogoEditor";
import { required } from "../../../components/form/Validators";
import Text from "../../../primitiveComponents/form/Text/Text";
import routeLinks from "../../../routeLinks";
import { configurationActions } from "../../configuration/reducers/configurationArea";
import { AddTenantDialog } from "../Tenants";
import { tenantsActions } from "../tenantsArea";

interface TenantModel {
    name: string;
    description: string | null;
    logo: LogoEditorSettings;
    tenantTags: string[];
    clonedFromTenantId: string | null;
}

interface GlobalDispatchProps {
    onSpaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource) => void;
    onTenantSaved: (tenant: TenantResource) => void;
}

interface TenantSettingsState extends FormBaseComponentState<TenantModel> {
    tenant: TenantResource;
    deleted: boolean;
    redirectTo?: string;
}

const AccessDenied = "AccessDenied";
const NotFound = "NotFound";
const NotCloned = "NotCloned";
type AvailableCloneFromTenantDetails = { clonedFromTenant: TenantResource };
type PossiblyClonedFromTenantDetails = typeof NotCloned | CloneFromTenantDetails;
type CloneFromTenantDetails = typeof NotFound | typeof AccessDenied | AvailableCloneFromTenantDetails;

class ClonedTenantsList extends List<TenantResource> {}

interface RouteParams {
    tenantId: string;
}

interface InitialData {
    tenant: TenantResource;
    clonedFromTenantDetails: PossiblyClonedFromTenantDetails;
    clonedTenantsCollection: ResourceCollection<TenantResource>;
}

const TenantSettingsFormPage = FormPage<InitialData>();
const title = "Settings";

const TenantSettingsPage: React.FC = () => {
    const { tenantId } = useParams<RouteParams>();
    const match = useRouteMatch();

    async function getCloneDetails(tenant: TenantResource): Promise<PossiblyClonedFromTenantDetails> {
        if (!tenant.ClonedFromTenantId) {
            return NotCloned;
        }

        const canAccessClone = isAllowed({
            permission: Permission.TenantView,
            tenant: tenant.ClonedFromTenantId,
            wildcard: true,
        });
        if (!canAccessClone) {
            return AccessDenied;
        }
        try {
            const clonedFromTenant = await repository.Tenants.get(tenant.ClonedFromTenantId);
            return { clonedFromTenant };
        } catch (error) {
            // if it's any other error let it go, if we failed to load due to bad data, just move on with 'NotFound'
            if (error.StatusCode === 404) {
                return NotFound;
            } else {
                throw error;
            }
        }
    }

    return (
        <TenantSettingsFormPage
            title={title}
            load={async () => {
                const [tenant, clonedTenantsCollection] = await Promise.all([repository.Tenants.get(tenantId), repository.Tenants.list({ clonedFromTenantId: tenantId })]);

                const clonedFromTenantDetails = await getCloneDetails(tenant);

                return {
                    tenant,
                    clonedTenantsCollection,
                    clonedFromTenantDetails,
                };
            }}
            renderWhenLoaded={(initialData) => {
                return <TenantSettings initialData={initialData} match={match || undefined} />;
            }}
        />
    );
};

type TenantSettingsInternalProps = {
    initialData: InitialData;
} & GlobalDispatchProps & { match?: Match<any> };

class TenantSettingsInternal extends FormBaseComponent<TenantSettingsInternalProps, TenantSettingsState, TenantModel> {
    constructor(props: TenantSettingsInternalProps) {
        super(props);

        this.state = {
            tenant: this.props.initialData.tenant,
            model: this.buildModel(props.initialData.tenant),
            cleanModel: this.buildModel(props.initialData.tenant),
            deleted: false,
        };
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const CloneDialog = () => (
            <PermissionCheck permission={Permission.TenantCreate}>
                <AddTenantDialog title="Clone Tenant" cloneId={this.state.tenant.Id} cloneTenantName={this.state.tenant.Name} tenantCreated={this.onTenantCreated} />
            </PermissionCheck>
        );

        const overFlowActions = [
            OverflowMenuItems.dialogItem("Clone", <CloneDialog />, { permission: Permission.TenantCreate }),
            OverflowMenuItems.deleteItemDefault("tenant", this.handleDeleteConfirm, { permission: Permission.TenantDelete, tenant: this.state.tenant.Id }),
            OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForTenant(this.state.tenant.Id), { permission: Permission.EventView, wildcard: true }),
        ];

        if (this.state.deleted) {
            return <InternalRedirect to={routeLinks.tenants} />;
        }

        return (
            <FormPaperLayout
                title="Settings"
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: Permission.TenantEdit, tenant: "*" }}
                onSaveClick={this.handleSaveClick}
                overFlowActions={overFlowActions}
                saveText="Tenant details updated"
            >
                <TransitionAnimation>
                    <ExpandableFormSection errorKey="logo" title="Logo" summary={this.logoSummary()} help="Choose an image to use as a tenant logo.">
                        <LogoEditor value={this.state.model.logo} onChange={(logo) => this.setModelState({ logo })} />
                    </ExpandableFormSection>

                    <ExpandableFormSection
                        errorKey="name"
                        title="Name"
                        focusOnExpandAll
                        summary={this.state.model.name ? Summary.summary(this.state.model.name) : Summary.placeholder("Please enter a name for your tenant")}
                        help="Enter a name for your tenant."
                    >
                        <Text value={this.state.model.name} onChange={(name) => this.setModelState({ name })} label="Tenant name" validate={required("Please enter a tenant name")} autoFocus={true} />
                    </ExpandableFormSection>

                    <ExpandableFormSection
                        errorKey="description"
                        title="Description"
                        summary={this.state.model.description ? Summary.summary(this.state.model.description) : Summary.placeholder("No description provided")}
                        help="Enter a description for your tenant."
                    >
                        <MarkdownEditor value={this.state.model.description ?? undefined} label="Tenant description" onChange={(description) => this.setModelState({ description })} />
                    </ExpandableFormSection>

                    {this.renderCloningSection()}
                </TransitionAnimation>
            </FormPaperLayout>
        );
    }

    onTenantCreated = (tenant: TenantResource) => {
        this.setState({ redirectTo: routeLinks.tenant(tenant.Id).root });
    };

    renderCloneDetails(cloneDetails: CloneFromTenantDetails) {
        const clonedFromId = this.state.tenant.ClonedFromTenantId;

        if (cloneDetails === NotFound) {
            return <div>This tenant was originally cloned from a tenant ({clonedFromId}) that cannot be found.</div>;
        } else if (cloneDetails === AccessDenied) {
            return <div>This tenant was originally cloned from a tenant that you do not have {Permission.TenantView} for.</div>;
        } else {
            return (
                <div>
                    This tenant was originally cloned from <InternalLink to={routeLinks.tenant(cloneDetails.clonedFromTenant).root}>{cloneDetails.clonedFromTenant.Name}</InternalLink>.
                </div>
            );
        }
    }

    handleSaveClick = async () => {
        const model = this.state.model;

        const tenant: TenantResource = {
            ...this.state.tenant,
            Name: model.name,
            Description: model.description,
            TenantTags: model.tenantTags,
            ClonedFromTenantId: model.clonedFromTenantId,
        };

        await this.doBusyTask(async () => {
            await saveLogo(this.state.tenant, model.logo.file, model.logo.reset);

            const result = await repository.Tenants.save(tenant);
            this.props.onTenantSaved(result);
            this.setState(() => {
                return {
                    model: this.buildModel(result),
                    cleanModel: this.buildModel(result),
                    tenant: result,
                };
            });
        });
    };

    buildModel(tenant: TenantResource): TenantModel {
        const model: TenantModel = {
            name: tenant.Name,
            tenantTags: tenant.TenantTags,
            logo: { file: undefined, reset: false },
            clonedFromTenantId: tenant.ClonedFromTenantId,
            description: tenant.Description,
        };
        return model;
    }

    logoSummary(): SummaryNode {
        if (this.state.model.logo.reset) {
            return Summary.placeholder("Default logo");
        }

        if (this.state.model.logo.file) {
            return Summary.summary(this.state.model.logo.file.name);
        }

        return Summary.summary(<Logo url={this.state.tenant.Links.Logo} size="2.5em" />);
    }

    private renderClonedFrom() {
        const clonedDetails = this.props.initialData.clonedFromTenantDetails;
        if (clonedDetails === NotCloned) {
            return null;
        }
        const clonedFromElement = this.renderCloneDetails(clonedDetails);
        return <ExpandableFormSection errorKey="ClonedFrom" title="Cloned From" summary={Summary.summary(clonedFromElement)} help={clonedFromElement} />;
    }

    private renderClonedTenants() {
        if (this.props.initialData.clonedTenantsCollection.TotalResults <= 0) {
            return null;
        }
        return (
            <ExpandableFormSection errorKey="ClonedTenants" title="Cloned Tenants" summary={Summary.summary("This tenant was cloned to create other tenants.")} help="This tenant was cloned to create the following tenants.">
                <Section>
                    <ClonedTenantsList
                        initialData={this.props.initialData.clonedTenantsCollection}
                        onRow={(tenant: TenantResource) => {
                            return <ListTitle>{tenant.Name}</ListTitle>;
                        }}
                        onRowRedirectUrl={(tenant: TenantResource) => routeLinks.tenant(tenant).root}
                        filterSearchEnabled={false}
                        autoFocusOnFilterSearch={false}
                        apiSearchParams={["partialName"]}
                        match={this.props.match}
                        showPagingInNumberedStyle={true}
                    />
                </Section>
            </ExpandableFormSection>
        );
    }

    private renderCloningSection() {
        const clonedFrom = this.renderClonedFrom();
        const clonedTenants = this.renderClonedTenants();

        const shouldRender = clonedFrom !== null || clonedTenants !== null;
        return (
            shouldRender && (
                <React.Fragment>
                    <FormSectionHeading title="Cloning History" />
                    {clonedFrom}
                    {clonedTenants}
                </React.Fragment>
            )
        );
    }

    private handleDeleteConfirm = async () => {
        const result = await repository.Tenants.del(this.state.tenant);
        const status = await repository.Tenants.status();
        this.props.onSpaceMultiTenancyStatusFetched(status);
        this.setState(() => {
            return {
                model: undefined,
                cleanModel: undefined,
                deleted: true,
            };
        });
        return true;
    };
}

const mapGlobalActionDispatchersToProps = (dispatch: Dispatch): GlobalDispatchProps =>
    bindActionCreators(
        {
            onSpaceMultiTenancyStatusFetched: configurationActions.spaceMultiTenancyStatusFetched,
            onTenantSaved: tenantsActions.tenantSaved,
        },
        dispatch
    );

const TenantSettings = connect(null, mapGlobalActionDispatchersToProps)(TenantSettingsInternal);

export default TenantSettingsPage;
