import * as React from "react";
import type { ProjectUsage } from "~/client/resources/libraryVariableSetUsageResource";
import InternalLink from "~/components/Navigation/InternalLink";
import SimpleDataTable from "~/components/SimpleDataTable";
import routeLinks from "~/routeLinks";
import { pluraliseSummary, pluraliseHelp } from "./VariableSetUsage";

export const VariableSetProjectUsages: React.FC<{ usage: ProjectUsage[] }> = ({ usage }) => {
    return <SimpleDataTable<ProjectUsage> data={usage} headerColumns={["Project Name"]} onRow={(project) => [<InternalLink to={routeLinks.project(project.ProjectSlug).deployments.process.root}>{project.ProjectName}</InternalLink>]} />;
};

export function getProjectUsageSummary(type: string, count: number, countOfProjectsUserCannotSee: number) {
    return pluraliseSummary(count, `This ${type}`, "is being", "has not been", "project", countOfProjectsUserCannotSee);
}

export function getProjectUsageHelp(type: string, count: number, countOfProjectsUserCannotSee: number) {
    return pluraliseHelp(count, `This ${type}`, "is being", "has not been", "project", countOfProjectsUserCannotSee);
}

export function scriptModuleProjectUsageSummary(usagesInProjects: ProjectUsage[], countOfProjectsUserCannotSee: number) {
    return getProjectUsageSummary("script module", usagesInProjects.length, countOfProjectsUserCannotSee);
}

export function variableSetProjectUsageSummary(usagesInProjects: ProjectUsage[], countOfProjectsUserCannotSee: number) {
    return getProjectUsageSummary("variable set", usagesInProjects.length, countOfProjectsUserCannotSee);
}

export function scriptModuleProjectUsageHelp(usagesInProjects: ProjectUsage[], countOfProjectsUserCannotSee: number) {
    return getProjectUsageHelp("script module", usagesInProjects.length, countOfProjectsUserCannotSee);
}

export function variableSetProjectUsageHelp(usagesInProjects: ProjectUsage[], countOfProjectsUserCannotSee: number) {
    return getProjectUsageHelp("variable set", usagesInProjects.length, countOfProjectsUserCannotSee);
}
