import isEqual from "lodash/isEqual";
import React from "react";
import { noOp } from "~/utils/noOp";

export function useKeyCombo(konamiCode: string[]) {
    const [keys, setKeys] = React.useState<string[]>([]);
    const [isKonamiCodeTriggered, setKonamiCodeTriggered] = React.useState(false);

    const konamiCodeKeyHandler = React.useCallback((event: KeyboardEvent) => {
        let timeout: ReturnType<typeof setTimeout> | undefined = undefined;
        setKeys((prev) => [...prev, event.key]);
        clearTimeout(timeout);
        timeout = setTimeout(() => setKeys([]), 5000);
    }, []);

    React.useEffect(() => {
        window.document.addEventListener("keydown", konamiCodeKeyHandler);
        return () => window.document.removeEventListener("keydown", konamiCodeKeyHandler);
    });

    const isKonamiCode = isEqual(keys, konamiCode);

    React.useEffect(() => {
        isKonamiCode ? setKonamiCodeTriggered((prev) => true) : noOp();
    }, [isKonamiCode]);

    return { isKonamiCodeTriggered };
}
