/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type { Reducer, ReducerAction } from "react";

export type ReducersMapObject<S = any, A extends ReducerAction<any> = ReducerAction<any>> = {
    [K in keyof S]: Reducer<S[K], A>;
};

export function combineUseReducers<TState = any, TAction extends ReducerAction<any> = ReducerAction<any>>(reducers: ReducersMapObject<TState, TAction>) {
    const reducerKeys = Object.keys(reducers);

    function combinedReducer(state: TState, action: TAction): TState {
        const nextState: any = {};
        let hasChanged = false;

        reducerKeys.forEach((key) => {
            const reducer = (reducers as { [key: string]: Reducer<any, any> })[key];
            const previousStateForKey = (state as { [key: string]: any })[key];
            const nextStateForKey = reducer(previousStateForKey, action);

            if (typeof nextStateForKey === "undefined") {
                throw new Error(`Returned undefined for reducer key ${key}. Please return null if you don't want to set the value.`);
            }

            nextState[key] = nextStateForKey;
            if (nextStateForKey !== previousStateForKey) {
                hasChanged = true;
            }
        });
        return hasChanged ? nextState : (state as TState);
    }

    return combinedReducer;
}
