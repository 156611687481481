/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from "react";
import ActionButton from "~/components/Button/ActionButton";
import Dialog from "~/components/Dialog/Dialog";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import type FormFieldProps from "../../../components/form/FormFieldProps";
import Text from "../Text/Text";
import styles from "./style.module.less";

interface ColorPickerProps extends FormFieldProps<string> {
    defaultColor?: string;
    id?: string;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
}

interface ColorPickerState {
    error?: string;
    pickerOpen: boolean;
}

export default class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
    constructor(props: ColorPickerProps) {
        super(props);
        this.state = {
            pickerOpen: false,
        };
    }

    render() {
        const { id, label, validate, error, onChange, onValidate, defaultColor, value, warning } = this.props;

        const err = this.state.error || error;
        const errorText = err || warning;
        const val = value ? value : "";
        const colorSet1 = ["#983230", "#A77B22", "#227647", "#52818C", "#203A88", "#752BA5", "#6e6e6e"];
        const colorSet2 = ["#E8634F", "#ECAD3F", "#36A766", "#77B7C5", "#3156B3", "#9786A7", "#9d9d9d"];
        const cancel = <ActionButton key="CancelButton" label="Cancel" onClick={() => this.setState({ pickerOpen: false })} />;

        return (
            <div className={styles.container}>
                <Text id={id} placeholder={defaultColor} value={val} onChange={this.handleChange} error={errorText} label={label} />
                <a
                    className={styles.colorSwatch}
                    style={{ backgroundColor: val || defaultColor }}
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({ pickerOpen: true });
                    }}
                />
                <Dialog open={this.state.pickerOpen || false}>
                    <DialogLayout actions={[cancel]} title="Standard Palette" closeDialog={() => this.setState({ pickerOpen: false })}>
                        <div>
                            <div className={styles.colorAllOptions}>{colorSet1.map(this.renderPaletteSwatch)}</div>
                            <div className={styles.colorAllOptions}>{colorSet2.map(this.renderPaletteSwatch)}</div>
                        </div>
                    </DialogLayout>
                </Dialog>
            </div>
        );
    }

    private handleChange = (value: string) => {
        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.props.onChange!(value);
    };

    private renderPaletteSwatch = (color: string) => {
        return (
            <a
                key={color}
                className={styles.colorPickItem}
                style={{ backgroundColor: color }}
                title={color}
                onClick={(e) => {
                    e.preventDefault();
                    this.handleChange(color);
                    this.setState({ pickerOpen: false });
                }}
            />
        );
    };
}
