import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectChannelOverview, CommonProjectChannelTopics } from "../../CommonComponents/CommonProjectChannelViews";

export const ProjectChannelsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().channels,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectChannelOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectChannelTopics />;
    },
};
