import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationTestPermissionOverview, CommonConfigurationTestPermissionTopics } from "../../CommonComponents/CommonConfigurationTestPermissionViews";

export const ConfigurationTestPermissionRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.testPermission,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationTestPermissionOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationTestPermissionTopics />;
    },
};
