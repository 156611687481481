import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonInfrastructureProxyOverview, CommonInfrastructureProxyTopics } from "../../CommonComponents/CommonInfrastructureProxyViews";

export const InfrastructureProxiesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.proxies.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureProxyOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureProxyTopics />;
    },
};
