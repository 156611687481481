import type { AccountEditModel } from "~/areas/infrastructure/components/AccountEdit/AccountEditBase";
import type { CertificateResource, NewDeploymentTargetResource } from "~/client/resources/index";
import { TenantedDeploymentMode } from "~/client/resources/index";

export function TenantsOrTenantTagsSelectedOnUntenantedDeploymentMode(model: NewDeploymentTargetResource | CertificateResource | AccountEditModel) {
    if ("tenantMode" in model) {
        return model.tenantMode === TenantedDeploymentMode.Untenanted && (model.tenantTags.length > 0 || model.tenantIds.length > 0);
    }
    return model.TenantedDeploymentParticipation === TenantedDeploymentMode.Untenanted && (model.TenantTags.length > 0 || model.TenantIds.length > 0);
}
