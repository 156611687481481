import * as React from "react";
import CodeEditor, { TextFormat } from "~/components/CodeEditor/CodeEditor";
import ExternalLink from "~/components/Navigation/ExternalLink";
import styles from "./Registrations.module.less";

export const CommonBuildServerPackagingTextForIntegration = (
    <React.Fragment>
        <h4>Build Server Integration</h4>
        <p>
            Please see the <ExternalLink href="PackagingApiAndIntegration">API and Integrations</ExternalLink> documentation to learn more about integration with your build server.
        </p>
    </React.Fragment>
);
export const CommonBuildServerPackagingTextForOctoPack = (
    <React.Fragment>
        <h4>OctoPack</h4>
        <p>
            <ExternalLink href="PackagingCreatingNuGetPackagesOctopack">OctoPack</ExternalLink> is the easiest way to package full framework .NET applications from your continuous integration/automated build process.
        </p>
        <p>Octo.exe can be used with full .NET framework applications if you require more flexibility than what OctoPack provides.</p>
    </React.Fragment>
);
export const CommonBuildServerPackagingTextForOctoExe = (
    <React.Fragment>
        <h4>Octo.exe (Command Line Tools)</h4>
        <p>
            <ExternalLink href="PackagingCreatingNuGetPackagesOctoExe">Octo.exe</ExternalLink> is our API command line tool that allows you to interact with your Octopus Deploy server using different commands, one of which is the pack command which
            can turn any ordinary folder into a NuGet or Zip package.
        </p>
    </React.Fragment>
);
export const CommonBuildServerPackagingTextForOctopackJs = (
    <React.Fragment>
        <h4>Octopack.js</h4>
        <p>
            <ExternalLink href="ExternalToolOctopackJs">Octopack.js</ExternalLink> is our Node.js tool for packaging and pushing projects to an Octopus Deploy instance. See our{" "}
            <ExternalLink href="PackagingDeploymentExamplesNodeJs">sample project walkthrough</ExternalLink> for more details.
        </p>
    </React.Fragment>
);

export const CommonCommandLineTextForOctoPack = (
    <React.Fragment>
        <h4>OctoPack</h4>
        <p>
            Once installed, <ExternalLink href="PackagingCreatingNuGetPackagesOctopack">OctoPack</ExternalLink> can easily create a NuGet package from your build, by setting the <i>RunOctoPack</i> MSBuild property to <i>true</i>.
        </p>
        <div className={styles.codeContainer}>
            For example:
            <br />
            <code>msbuild MySolution.sln /t:Build /p:RunOctoPack=true</code>
        </div>
        <p>
            <ExternalLink href="PackagingCreatingNuGetPackagesOctoExe">Octo.exe</ExternalLink> can also be used with full .NET framework applications if you require more flexibility than what OctoPack provides.
        </p>
    </React.Fragment>
);
export const CommonCommandLineTextForOctoExe = (
    <React.Fragment>
        <h4>Octo.exe (Command Line Tools)</h4>
        <p>
            <ExternalLink href="PackagingCreatingNuGetPackagesOctoExe">Octo.exe</ExternalLink> is our API command line tool that allows you to interact with your Octopus Deploy server using different commands, one of which is the pack command which
            can turn any ordinary folder into a NuGet or Zip package.
        </p>
    </React.Fragment>
);
export const CommonCommandLineTextForOctoExeCodeSample = (
    <React.Fragment>
        <div className={styles.codeContainer}>
            For example:
            <br />
            <code>octo.exe pack --id="MyPackage" --version="1.0.0" --basePath="folder/to/pack" --outFolder="destination/folder/path"</code>
        </div>
    </React.Fragment>
);
export const CommonCommandLineTextForOctopackJs = (
    <React.Fragment>
        <h4>Octopack.js</h4>
        <p>
            <ExternalLink href="ExternalToolOctopackJs">Octopack.js</ExternalLink> is our Node.js tool for packaging and pushing projects to an Octopus Deploy instance. See our{" "}
            <ExternalLink href="PackagingDeploymentExamplesNodeJs">sample project walkthrough</ExternalLink> for more details.
        </p>
    </React.Fragment>
);
export const CommonCommandLineTextForOctopackJsCodeSample = (
    <React.Fragment>
        <div className={styles.codeContainer}>
            For example:
            <br />
            <CodeEditor
                value={`npm install @octopusdeploy/octopackjs --save-dev

var octo = require('@octopusdeploy/octopackjs');
octo.pack()
  .append('buffer files/hello.txt', new Buffer('hello world'), {date: new Date(1982, 05, 21)})
  .append('stream.txt', fs.createReadStream('./package.json'))
  .append('lib/myfile.js')
  .toFile('./bin', function (err, data) {
    console.log("Package Saved: "+ data.name);
  });`}
                allowFullScreen={false}
                readOnly={true}
                language={TextFormat.PlainText}
            />
        </div>
    </React.Fragment>
);

export const CommonManualPackagingTextForNuGet = (
    <React.Fragment>
        <h4>NuGet Packages</h4>
        <p>
            The simplest way to create a NuGet package is using the <ExternalLink href="PackagingCreatingNuGetPackagesManually">NuGet Package Explorer</ExternalLink>.
        </p>
        <p>
            Alternatively, NuGet packages can easily be created using <ExternalLink href="PackagingCreatingNuGetPackagesOctoExe">Octo.exe</ExternalLink>.
        </p>
    </React.Fragment>
);
export const CommonManualPackagingTextForZip = (
    <React.Fragment>
        <h4>Zip Packages</h4>
        <p>
            Octopus supports zip files as packages, as created through common zip programs or with <ExternalLink href="OnboardingPackagingZipItUpLearnMore">Octo.exe</ExternalLink>.
        </p>
        <ul>
            <li>
                Learn more about <ExternalLink href="PackagingCreatingPackages">Creating Packages</ExternalLink>
            </li>
            <li>
                Learn more about <ExternalLink href="PackagingCreatingSupportedPackages">Supported Formats</ExternalLink>
            </li>
            <li>
                Learn more about <ExternalLink href="PackagingCreatingPackageIds">Package IDs</ExternalLink>
            </li>
            <li>
                Learn more about <ExternalLink href="PackagingCreatingVersioning">Versioning</ExternalLink>
            </li>
        </ul>
    </React.Fragment>
);

export const CommonTextForJavaSample = (
    <React.Fragment>
        <p>
            The Octopus library accepts files with the <code>jar</code>, <code>war</code>, <code>ear</code> and <code>rar</code> file extensions. The package filenames have to adhere to the standard Octopus versioning rules, which are based on the
            SemVer format.
        </p>
        <p>
            We also have a <ExternalLink href="PackagingDeploymentExamplesJava">sample project walkthrough</ExternalLink> where you can see examples of Java packaging.
        </p>
    </React.Fragment>
);
