import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";

const VersionNumberInfo: React.FC = () => {
    return (
        <div>
            <p>You can use standard version numbers with two, three or four components:</p>
            <ul>
                <li>
                    <code>2.3</code>
                </li>
                <li>
                    <code>2.3.16</code>
                </li>
                <li>
                    <code>2.3.16.384</code>
                </li>
            </ul>
            <p>
                You can also include <ExternalLink href="SemVer">semantic version</ExternalLink> tags:
            </p>
            <ul>
                <li>
                    <code>2.3.16-beta</code>
                </li>
                <li>
                    <code>2.3.16.384-pre-release</code>
                </li>
            </ul>
            <p>
                You can also use the letter <code>i</code> to increment part of the last release, for example:
            </p>
            <ul>
                <li>
                    <code>1.i</code>
                </li>
                <li>
                    <code>1.15.i</code>
                </li>
                <li>
                    <code>2.i.i</code>
                </li>
            </ul>
        </div>
    );
};

export default VersionNumberInfo;
