import * as React from "react";
import type { SelectItem } from "../VirtualListWithKeyboard/SelectItem";
import { TypeaheadMultiSelector } from "./Typeahead";

interface TypeaheadStepMultiSelectorProps {
    onStepChange: (value: string[]) => void;
    onNewStep: (value: string) => void;
    stepReferences: SelectItem[];
    selectedStepIds: string[];
    accessibleName?: string | undefined;
}

export const TypeaheadMultiStepSelector: React.FC<TypeaheadStepMultiSelectorProps> = (props) => {
    return (
        <TypeaheadMultiSelector
            fieldName={"steps"}
            value={props.selectedStepIds}
            items={props.stepReferences}
            onChange={props.onStepChange}
            onNew={props.onNewStep}
            addNewTemplate={(text) => `"${text}" (add new step)`}
            accessibleName={props.accessibleName}
        />
    );
};
