import type RetentionDefaultConfigurationResource from "~/client/resources/retentionDefaultConfigurationResource";
import type { Client } from "../client";
import ConfigurationRepository from "./configurationRepository";

class RetentionDefaultConfigurationRepository extends ConfigurationRepository<RetentionDefaultConfigurationResource> {
    constructor(client: Client) {
        super("RetentionDefaultConfiguration", client);
    }
}

export default RetentionDefaultConfigurationRepository;
