import type { ProjectResource, GitRefResource } from "~/client/resources";
import { HasVersionControlledPersistenceSettings } from "~/client/resources";
import { repository } from "~/clientInstance";
import type { SpecifiedGitRef, BranchSpecifier } from "../components/ProjectsRoutes/BranchSpecifier";
import { UseDefaultBranch, ShouldUseDefaultBranch } from "../components/ProjectsRoutes/BranchSpecifier";

function lastAccessedGitRefStore() {
    const LocalStorageKey = "octoLastAccessedGitRef";

    const buildKey = (projectIdOrSlug: string) => `${LocalStorageKey}-${projectIdOrSlug}`;

    const validateIsVersionControlled = (project: ProjectResource): void => {
        if (!project.IsVersionControlled) {
            throw new Error("Can only retrieve stored GitRef for Git Projects");
        }
    };

    const save = async (project: ProjectResource, gitRef: SpecifiedGitRef): Promise<void> => {
        validateIsVersionControlled(project);
        localStorage.setItem(buildKey(project.Id), gitRef);
    };

    const get = async (project: ProjectResource): Promise<GitRefResource> => {
        validateIsVersionControlled(project);

        const key = buildKey(project.Id);
        const storedGitRef = localStorage.getItem(key) ?? UseDefaultBranch;

        if (!ShouldUseDefaultBranch(storedGitRef)) {
            try {
                const validationResult = await repository.Projects.validateGitRef(project, storedGitRef);
                if (!!validationResult.ValidatedGitRef) {
                    return validationResult.ValidatedGitRef;
                }
            } catch (e) {
                localStorage.removeItem(key);
            }
        }
        if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
        return repository.Projects.getBranch(project, project.PersistenceSettings.DefaultBranch);
    };

    const getBranchName = (project: ProjectResource): BranchSpecifier => {
        validateIsVersionControlled(project);
        const key = buildKey(project.Id);
        return localStorage.getItem(key) ?? UseDefaultBranch;
    };

    return {
        save,
        get,
        getBranchName,
    };
}

const lastAccessedGitRef = lastAccessedGitRefStore();

export { lastAccessedGitRef };
