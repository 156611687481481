import * as React from "react";
import type { ToolTipContent } from "../../primitiveComponents/dataDisplay/ToolTip";
import ToolTip from "../../primitiveComponents/dataDisplay/ToolTip";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import navigationChipStyles from "./NavigationChip.module.less";
import { StyledMaterialChip } from "./StyledMaterialChip";

export interface NavigationChipProps {
    to: string;
    accessibleName: string;
    description?: string | ToolTipContent;
}

export const NavigationChip: React.FC<NavigationChipProps> = ({ to, accessibleName, children, description }) => {
    return (
        <InternalLink to={to} accessibleName={accessibleName}>
            <StyledMaterialChip className={navigationChipStyles.clickable} label={<ToolTip content={<span>{description || children}</span>}>{children}</ToolTip>} />
        </InternalLink>
    );
};

export default NavigationChip;
